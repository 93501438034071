import { child, get, onValue, ref, remove, set, update } from "firebase/database";
import { rtdb } from "../firebase";

const PIXI = window.pixi;
const kjua = window.kjua;

(function( Meta, undefined ) {
	var CurWindow = null;
	var Stage = null;
	var SoundStage = null;
	var LegacyMeta = null;
	
	//for now
	Meta.BlankFunction = function(){};
	
	Meta.Window = function(div){
		CurWindow = div;
		CurWindow.style.overflow = "hidden";
		CurWindow.style.position = "relative";
		
		CurWindow.style.pointerEvents = "none";
		CurWindow.style.userSelect = "none";
		//~ -webkit-touch-callout: none; /* iOS Safari */
		//~ -webkit-user-select: none; /* Safari */
		//~ -khtml-user-select: none; /* Konqueror HTML */
		//~ -moz-user-select: none; /* Old versions of Firefox */
		//~ -ms-user-select: none; /* Internet Explorer/Edge */
		//~ user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
		
		if (Stage == null){
			Stage = document.createElement("DIV");
			Stage.style.pointerEvents = "auto";
			CurWindow.appendChild(Stage);
		}
		
		if (SoundStage == null){
			SoundStage = document.createElement("SPAN");
			CurWindow.appendChild(SoundStage);
		}
		
		if (LegacyMeta == null){
			LegacyMeta = new MetaEngine(CurWindow);
		}

		if (rtdb == null){
			//~ firebase.initializeApp({
				//~ apiKey: "AIzaSyAL67Sw0kHL1OaKviF_jrwC9e0DwijYZzY",
				  //~ authDomain: "vision-science-labs.firebaseapp.com",
				  //~ databaseURL: "https://vision-science-labs-default-rtdb.firebaseio.com",
				  //~ projectId: "vision-science-labs",
				  //~ storageBucket: "vision-science-labs.appspot.com",
				  //~ messagingSenderId: "849113159696",
				  //~ appId: "1:849113159696:web:410152347bef82c9f7d295",
				  //~ measurementId: "G-1QK5EY9HYG"
			//~ });
			// rtdb = firebase.database();
			//~ if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
				//~ rtdb.useEmulator('localhost', '9000');
			//~ }
		}
		
		Void();
	}
	
	var MetaTree = null;
	var CurNode = null;
	
	function ClearStage(){

		while(Stage.firstChild){
			Stage.removeChild(Stage.firstChild);
		}
		
		LegacyMeta.Void();
	}
	
	function Void(){
		CurWindow.style.backgroundColor = '#000000';
		LegacyMeta.backgroundColor = 0x000000;
		
		ClearStage();

		while(SoundStage.firstChild){
			SoundStage.removeChild(SoundStage.firstChild);
		}
		//for now
		rainchecks = [];
		Meta.Cursor(1);
		
		if(CurNode != null){
			CurNode.Del();
		}
		
		gameStateIds.forEach(gs=>{
			remove(ref(rtdb, 'codes/' + gs))
			// rtdb.ref("/rt_states/" + gs).remove();
		})
		gameStateIds = [];
		
		if (MetaTree && MetaTree.cb) MetaTree.cb(null); //releases outside promises
		MetaTree = null;
		
		Meta.Fullscreen(0);
		Meta.Visibility(0); //for now
		LegacyMeta.Visibility(0);
	}
	
	Meta.Void = function(){Void()};
	
	Meta.Stage = {
		Clear : ()=>{
			ClearStage();
		},
		Background : (c)=>{
			if(CurWindow){
				CurWindow.style.backgroundColor = c;
				LegacyMeta.backgroundColor = window.PIXI.utils.string2hex(c); //have to do this for now
			}
		},
	};
	
	window.Meta.Cursor = function(b){
		if(b){
			CurWindow.style.removeProperty("cursor");
		}else{
			CurWindow.style.cursor = "none";
		}
	}
	
	function BrowserZoom(){
		//~ return 1;
		return window.screen.height / CurWindow.clientHeight;
	}
	
	Object.defineProperty(Meta.Stage, "Top", {
		get : function () {
			return (-window.screen.height/BrowserZoom())/2 * TrueZoom;
		}
	});
	
	Object.defineProperty(Meta.Stage, "Bot", {
		get : function () {
			return (window.screen.height/BrowserZoom())/2 * TrueZoom;
		}
	});
	
	Object.defineProperty(Meta.Stage, "Left", {
		get : function () {
			return (-window.screen.width/BrowserZoom())/2 * TrueZoom;
		}
	});
	
	Object.defineProperty(Meta.Stage, "Right", {
		get : function () {
			return (window.screen.width/BrowserZoom())/2 * TrueZoom;
		}
	});
	
	Object.defineProperty(Meta.Stage, "Width", {
		get : function () {
			return window.screen.width / BrowserZoom() * TrueZoom;
		}
	});
	
	Object.defineProperty(Meta.Stage, "Height", {
		get : function () {
			return window.screen.height / BrowserZoom() * TrueZoom;
		}
	});
	
	Meta.Visibility = function(b){
		if (CurWindow == null) return;
		if(b){
			CurWindow.style.display = "block";
		}else{
			CurWindow.style.display = "none";
		}
	}

	function NewMetatreeNode(){
		const N = {
			Do : function(engine){
				CurNode = this;
				Meta.Do(engine);
			},
			Del : function(){
				if (this.keyboard) this.keyboard.Relinquish();
				if (this.mouse) this.mouse.Relinquish();
				if (this.child){
					this.child.Del();
					this.child = null;
				}
			},
			Load : function(engine){
				const starts = [[], [], [], [], []];
				const engines = Array.isArray(engine) ? engine : [engine];
				

				engines.forEach(E=>{
					if(E) {
						Object.entries(E).forEach(e=>{
							if (e[0] == "Start"){
								starts[0].push(e[1]);
							}else if (e[0] == "Start5"){
								starts[4].push(e[1]);
							}else if (e[0] == "Tick"){
								CurNode.Tick ? CurNode.Tick.push(e[1]) : CurNode.Tick = [e[1]];
							}else if (e[0] == "Resize"){
								CurNode.Resize ? CurNode.Resize.push(e[1]) : CurNode.Resize = [e[1]];
							}else{
								CurNode.data[e[0]] = e[1];
							}
						});
					}
				})
				
				starts[0].forEach(s=>s());
				starts[4].forEach(s=>s());
			},
			data : {},
		};
		
		return N;
	}
	
	Meta.get = function(name){
		if(CurNode == null) return null;
		
		var node = CurNode;
		while (!node.data.hasOwnProperty(name)){
			if (node.parent){
				node = node.parent;
			}else{
				return null;
			}
		}
		return node.data[name];
	}
	
	Meta.set = function(name, val){
		if(CurNode == null) return;
		
		CurNode.data[name] = val;
	}
	
	Meta.Do = function(engine, cb){
		Void();
		
		Meta.Fullscreen(1).then(()=>{ //for now
			requestAnimationFrame(()=>{ //fuck this shit
				MetaTree = NewMetatreeNode();
				CurNode = MetaTree;
				CurNode.cb = cb;
				CheckSizing();
				
				CurNode.Load(engine);
				
				LastFrameTime = Date.now();
				Tick();
			});
		});
	}
	
	Meta.SubDo = function(engine, cb){
		if(CurNode == null) return;
		
		CurNode.child = NewMetatreeNode();
		CurNode.child.parent = CurNode;
		CurNode = CurNode.child;
		CurNode.cb = cb;
		
		CurNode.Load(engine);
		
		DoResize();
	}
	
	Meta.LegacyDo = function(engine){
		LegacyMeta.Visibility(1);
		LegacyMeta.Do(engine);
		Meta.Redraw();
	}
	
	Meta.LegacyEnd = function(){
		LegacyMeta.End();
	}
	
	Meta.Return = function(ret){
		const me = CurNode;
		
		const cb = CurNode.cb;
		const parent = CurNode.parent;
		if (parent){
			CurNode = parent;
		}
		if(cb) cb(ret);
		me.Del();
		if (!parent){
			CurNode.cb = null;
			Void();
		}
	}
	
	Meta.Engine = function(){
		const combo = [];
		
		function Iterate(ele){
			if (Array.isArray(ele)){
				for(var e = 0; e < ele.length; e++){
					Iterate(ele[e]);
				}
			}else{
				combo.push(ele);
			}
		}
		
		if(arguments.length > 0){
			for(var e = 0; e < arguments.length; e++){
				Iterate(arguments[e]);
			}
		}
		
		return combo;
	}
	
	//Legacy
	Meta.Combine = function(){
		const combo = {};
		
		if(arguments.length >0){
			for(var e = 0; e < arguments.length; e++){
				const engine = arguments[e];
				for (const key in engine){
					if (combo[key]){
						if(Array.isArray(combo[key]) == false){
							combo[key] = [combo[key]];
						}
						combo[key] = combo[key].concat(engine[key]);
						//I don't know why, but this made things compound
						//~ if (Array.isArray(engine[key])){
							//~ combo[key] = combo[key].concat(engine[key]);
						//~ }else{
							//~ combo[key].push(engine[key]);
						//~ }
					}else{
						combo[key] = engine[key];
					}
				}
			}
		}
		return combo;
	}
	
	var LastFrameTime = 0;
	
	function CheckSizing(){
		if(AntiZoomX > 0 && AntiZoomY > 0){
			if (TrueZoom != window.devicePixelRatio || CurWindow.offsetWidth != AntiZoomX || CurWindow.offsetHeight != AntiZoomY){
				TrueZoom = window.devicePixelRatio;
				Meta.Resize(CurWindow.offsetWidth, CurWindow.offsetHeight);
			}
		}
	}
	
	var rainchecks = [];
	Meta.DoAfter = function(dur, foo){
		rainchecks.push({dur,foo});
	}
	
	function Tick() {
		if (MetaTree){
			CheckSizing();
			
			//this works until you can have multiple children with same parent
			var QuickHack = MetaTree;
			
			const NOW = Date.now();
			const dT = NOW - LastFrameTime;
			LastFrameTime = NOW;
			
			//run it backwards so anything added with time 0 at least waits until next frame
			for (var dex = rainchecks.length-1; dex >= 0; dex--){
				rainchecks[dex].dur -= dT;
				if (rainchecks[dex].dur <= 0){
					rainchecks[dex].foo();
					rainchecks.splice(dex, 1);
				}
			}
			
			while(QuickHack){
				if (Array.isArray(QuickHack.Tick)) QuickHack.Tick.forEach(t=>{t(dT/1000)});
				QuickHack = QuickHack.child;
			}
			
			requestAnimationFrame(Tick);
		}
	}
	
	var AntiZoomX = -1;
	var AntiZoomY = -1;
	var ZoomX = 1;
	var ZoomY = 1;
	var TrueZoom = window.devicePixelRatio;
	
	Meta.Resize = function(w, h){
		if (CurWindow == null) return;
		if (w <= 0 || h <= 0) return;
		
		AntiZoomX = w;
		AntiZoomY = h;
		ZoomX = window.screen.width/AntiZoomX;
		ZoomY = window.screen.height/AntiZoomY;
		DoResize();
	}
	
	function DoResize(){
		//this works until you can have multiple children with same parent
		var QuickHack = MetaTree;
		
		while(QuickHack){
			if (Array.isArray(QuickHack.Resize)) QuickHack.Resize.forEach(r=>{r()});
			QuickHack = QuickHack.child;
		}
		
		LegacyMeta.Resize(AntiZoomX, AntiZoomY);
		
		function IterateTransform(C){
			if (C._trueNature == "Anchor") return;
			
			var pLeft = 0;
			var pRight = 0;
			var pTop = 0;
			var pBot = 0
			
			var _tP = C._trueParent;
			while(_tP){
				pLeft += _tP._trueLeft ? _tP._trueLeft : 0;
				pRight += _tP._trueRight ? _tP._trueRight : 0;
				pTop += _tP._trueTop ? _tP._trueTop : 0;
				pBot += _tP._trueBot ? _tP._trueBot : 0;
				_tP = _tP._trueParent;
			}
			
			if(C._trueWidth){
				// C.width = C._trueWidth / TrueZoom;
				C.style.width = C._trueWidth / TrueZoom + "px";
			}
			if(C._trueHeight){
				// C.height = C._trueHeight / TrueZoom;
				C.style.height = C._trueHeight / TrueZoom + "px";
				C.style.lineHeight = C._trueHeight / TrueZoom + "px"; //works while there is one line of text
			}
			if(C._trueRad){
				C.width = C._trueRad*2 / TrueZoom;
				C.style.width = C._trueRad*2 / TrueZoom;
				C.height = C._trueRad*2 / TrueZoom;
				C.style.height = C._trueRad*2 / TrueZoom;
			}
			
			if(C._trueFont){
				C.style.fontSize = C._trueFont / TrueZoom + "px";
				if (!C._trueHeight){
					C.style.lineHeight = (C._trueFont*C._trueSpacing) / TrueZoom + "px";
				}
			}
			
			if (C.nodeName == "svg"){
				const rects = C.getElementsByTagName('rect');
				for(var x = 0; x < rects.length; x++){
					if(C._trueWidth){
						rects[x].setAttributeNS(null,"width",(C._trueWidth) / TrueZoom);
					}
					if(C._trueHeight){
						rects[x].setAttributeNS(null,"height",(C._trueHeight) / TrueZoom);
					}
					if(C._trueStroke){
						rects[x].setAttributeNS(null,"stroke-width",C._trueStroke*2 / TrueZoom);
					}
				}
				
				const circs = C.getElementsByTagName('circle');
				for(var x = 0; x < circs.length; x++){
					if(C._trueRad){
						circs[x].setAttributeNS(null,"cx",C._trueRad / TrueZoom);
						circs[x].setAttributeNS(null,"cy",C._trueRad / TrueZoom);
						circs[x].setAttributeNS(null,"r",(C._trueRad-C._trueStroke/2) / TrueZoom);
					}
					if(C._trueStroke){
						circs[x].setAttributeNS(null,"stroke-width",C._trueStroke / TrueZoom);
					}
				}
				
				const paths = C.getElementsByTagName('path');
				for(var x = 0; x < paths.length; x++){
					paths[x].setAttributeNS(null,"transform", "scale(" + 1/TrueZoom + ")");
					//~ not needed because of scaling
					//~ if(C._trueStroke){
						//~ paths[x].setAttributeNS(null,"stroke-width", C._trueStroke / TrueZoom);
					//~ }
				}
			}else{
				if(C._trueStroke){
					C.style.borderWidth = C._trueStroke / TrueZoom + "px";
				}
			}
			
			if (C.nodeName == "CANVAS"){
				C.width = C._trueWidth;
				C.height = C._trueHeight;
				if (C.BUFF.width != C._trueWidth || C.BUFF.height != C._trueHeight){
					C.BackBuffer();
					C.Redraw();
				}else{
					C.Draw();
				}
			}
			
			var OX;
			var OY;
			
			switch(C.Origin){
			case "Left":
				OX = 0;
				OY = window.screen.height/2;
				break;
			case "Right":
				OX = window.screen.width;
				OY = window.screen.height/2;
				break;
			case "Top":
				OX = window.screen.width/2;
				OY = 0;
				break;
			case "Bot":
				OX = window.screen.width/2;
				OY = window.screen.height;
				break;
			default:
				OX = window.screen.width/2;
				OY = window.screen.height/2;
				break;
			}
			
			if(C._trueLeft != null && C._trueRight != null){
				C.style.left = (C._trueLeft + pLeft)/TrueZoom + "px";
				C.style.right = (C._trueRight + pRight)/TrueZoom + "px";
			}else if(C._trueLeft != null) {
				C.style.left = ((C._trueLeft + pLeft)/TrueZoom  + OX/ZoomX) + "px";
				C.style.removeProperty("right");
			}else if(C._trueRight != null) {
				C.style.right = ((C._trueRight + pRight)/TrueZoom + OX/ZoomX) + "px";
				C.style.removeProperty("left");
			}
			if(C.hasOwnProperty('_trueTop')) {
				C.style.top = ((C._trueTop + pTop)/TrueZoom + OY/ZoomY) + "px";
			}else if(C.hasOwnProperty('_trueBot')) {
				C.style.bottom = ((C._trueBot + pBot)/TrueZoom + OY/ZoomY) + "px";
			}
			
			for(var c = 0; c < C.children.length; c++){
				IterateTransform(C.children[c]);
			}
		}
		
		for(var c = 0; c < Stage.children.length; c++){
			IterateTransform(Stage.children[c]);
		}
	}
	//for now
	Meta.Redraw = function(){
		DoResize();
	}
	
	var IsFullscreen = false;
	
	Meta.Fullscreen = function(b){
		CurWindow = document.getElementById("MetaEngine");
		if (CurWindow == null) return Promise.reject();
		
		if(b){
			if (CurWindow.requestFullscreen){
				return CurWindow.requestFullscreen();
			}else{
				CurWindow.style.position = "absolute";
				CurWindow.style.width = document.body.offsetWidth + "px";
				CurWindow.style.height = document.body.offsetHeight + "px";
				CurWindow.style.display = "block";
				CurWindow.style.zIndex  = "999999999";
				return Promise.resolve(1);
			}
		}else if (IsFullscreen){
			if (document.exitFullscreen){
				return document.exitFullscreen();
			}else{
				//~ return document.webkitExitFullscreen();
				CurWindow.style.position = "relative";
				CurWindow.style.width = "0px";
				CurWindow.style.height = "0px";
				CurWindow.style.display = "none";
				CurWindow.style.zIndex  = "-1";
				return Promise.resolve(1);
			}
		}
	}
	
	document.addEventListener("fullscreenchange", () =>{
		if (document.fullscreenElement == null){
			IsFullscreen = false;
			window.Cali.PatientDistance = window.Cali.PatientDistance; //reset any overrides
			Void();
			//~ this.Visibility(!this._FullscreenOnly);
			//~ this.DoResize();
		}else if (document.fullscreenElement == CurWindow){
			IsFullscreen = true;
			window.Meta.Visibility(1);
			window.Meta.Resize(CurWindow.offsetWidth, CurWindow.offsetHeight);
		}
	});
	
	function Standard(obj){
		obj.style.position = "absolute";
		obj.style.fontFamily = "Arial";
		
		obj.Show = function(){
			this.style.removeProperty("display");
		}
		
		obj.Hide = function(){
			this.style.display = "none";
		}
		
		Object.defineProperty(obj, "Top", {
			get : function () {
				return this._trueTop;
			},
			set : function (val) {
				this._trueTop = val;
			},
		});
		
		Object.defineProperty(obj, "Bot", {
			get : function () {
				if (this._trueBot){
					return -this._trueBot;
				}else if (this._trueTop){
					return this._trueTop + this.Height;
				}
				return null;
			},
			set : function (val) {
				this._trueBot = -val;
			},
		});
		
		Object.defineProperty(obj, "Left", {
			get : function () {
				return this._trueLeft;
			},
			set : function (val) {
				if (val != null){
					this._trueLeft = val;
				}else{
					this._trueLeft = null;
				}
			},
		});
		
		Object.defineProperty(obj, "Right", {
			get : function () {
				if (this._trueRight){
					return -this._trueRight;
				}else if (this._trueLeft){
					return this._trueLeft + this.Width;
				}
				return null;
			},
			set : function (val) {
				if (val != null){
					this._trueRight = -val;
				}else{
					this._trueRight = null;
				}
			},
		});
		
		Object.defineProperty(obj, "Width", {
			get : function () {
				if (this._trueWidth){
					return this._trueWidth;
				}else{
					return this.offsetWidth * TrueZoom;
				}
			},
			set : function (val) {
				this._trueWidth = val;
			},
		});
		
		Object.defineProperty(obj, "Height", {
			get : function () {
				if (this._trueHeight){
					return this._trueHeight;
				}else{
					return this.offsetHeight * TrueZoom;
				}
			},
			set : function (val) {
				this._trueHeight = val;
			},
		});
		
		Object.defineProperty(obj, "Parent", {
			get : function () {
				return this._trueParent;
			},
			set : function (ele) {
				this._trueParent = ele;
			},
		});
		
		function RenderTransform(obj){
			var str = "";
			
			if (obj._scaleX) str += "scaleX(" + obj._scaleX + ")";
			if (obj._scaleY) str += "scaleY(" + obj._scaleY + ")";
			if (obj._rotation) str += "rotate(" + obj._rotation + "deg)";
			
			obj.style.transform = str;
		}
		
		Object.defineProperty(obj, "ScaleX", {
			get : function () {
				return this._scaleX;
			},
			set : function (val) {
				this._scaleX = val;
				RenderTransform(this);
			},
		});
		
		Object.defineProperty(obj, "ScaleY", {
			get : function () {
				return this._scaleY;
			},
			set : function (val) {
				this._scaleY = val;
				RenderTransform(this);
			},
		});
		
		Object.defineProperty(obj, "Rotation", {
			get : function () {
				return this._rotation ? this._rotation : 0;
			},
			set : function (val) {
				this._rotation = val;
				RenderTransform(this);
			},
		});
		
		Object.defineProperty(obj, "Blend", {
			//~ get : function () {
				//~ return this._trueRight;
			//~ },
			set : function (val) {
				switch(val){
				case "Add":
					this.style.mixBlendMode = "screen";
					break;
				}
			},
		});
		
		Object.defineProperty(obj, "ClickThrough", {
			//~ get : function () {
				//~ return this._trueRight;
			//~ },
			set : function (val) {
				if (val){
					this.style.pointerEvents = "none";
				}else{
					this.style.removeProperty("pointerEvents");
				}
			},
		});
		
		obj.Delete = function(){
			this.parentNode.removeChild(this);
		}
	};
	
	const W3SVG = "http://www.w3.org/2000/svg";
	
	function StandardSVG(svg){
		Object.defineProperty(svg, "Fill", {
			//~ get : function () {
				//~ return this._trueTop;
			//~ },
			set : function (val) {
				this.children[0].setAttributeNS(null,"fill",(typeof val === "string") ? val : window.PIXI.utils.hex2string(val));
			},
		});
		
		Object.defineProperty(svg, "Line", {
			//~ get : function () {
				//~ return this._trueTop;
			//~ },
			set : function (val) {
				this.children[0].setAttributeNS(null,"stroke",(typeof val === "string") ? val : window.PIXI.utils.hex2string(val));
			},
		});
	};
	
	window.Meta.Anchor = function(){
		const obj = {_trueNature: "Anchor"};
		
		Object.defineProperty(obj, "Top", {
			get : function () {
				return this._trueTop;
			},
			set : function (val) {
				this._trueTop = val;
			},
		});
		
		Object.defineProperty(obj, "Bot", {
			get : function () {
				if (this._trueBot){
					return -this._trueBot;
				}else if (this._trueTop){
					return this._trueTop;
				}
				return null;
			},
			set : function (val) {
				this._trueBot = -val;
			},
		});
		
		Object.defineProperty(obj, "Left", {
			get : function () {
				return this._trueLeft;
			},
			set : function (val) {
				if (val != null){
					this._trueLeft = val;
				}else{
					this._trueLeft = null;
				}
			},
		});
		
		Object.defineProperty(obj, "Right", {
			get : function () {
				if (this._trueRight){
					return -this._trueRight;
				}else if (this._trueLeft){
					return this._trueLeft;
				}
				return null;
			},
			set : function (val) {
				if (val != null){
					this._trueRight = -val;
				}else{
					this._trueRight = null;
				}
			},
		});
		
		return obj;
	}
	
	Meta.Rect = function(w, h, config={}){
		const SVG = document.createElementNS(W3SVG, "svg");
		Standard(SVG);
		StandardSVG(SVG);
		
		const RECT = document.createElementNS(W3SVG, "rect");
		RECT.setAttributeNS(null,"x",0);
		RECT.setAttributeNS(null,"y",0);
		RECT.setAttributeNS(null,"width",w);
		RECT.setAttributeNS(null,"height",h);
		if(config.fill) {
			RECT.setAttributeNS(null,"fill",config.fill);
		}else{
			RECT.setAttributeNS(null,"fill-opacity","0.0");
		};
		if(config.line) RECT.setAttributeNS(null,"stroke",config.line);
		if(config.stroke){
			SVG._trueStroke = config.stroke;
			RECT.setAttributeNS(null,"stroke-width",config.stroke);
		}else{
			SVG._trueStroke = 0;
		}
		
		SVG._trueWidth = w;
		SVG._trueHeight = h;
		SVG.appendChild(RECT);
		Stage.appendChild(SVG);
		return SVG;
	}
	
	Meta.Circle = function(r, config={}){
		const SVG = document.createElementNS(W3SVG, "svg");
		Standard(SVG);
		StandardSVG(SVG);
		
		const CIRC = document.createElementNS(W3SVG, "circle");
		CIRC.setAttributeNS(null,"cx",r);
		CIRC.setAttributeNS(null,"cy",r);
		CIRC.setAttributeNS(null,"r",r);
		if(config.fill) {
			CIRC.setAttributeNS(null,"fill",config.fill);
		}else{
			CIRC.setAttributeNS(null,"fill-opacity","0.0");
		};
		if(config.line) CIRC.setAttributeNS(null,"stroke",config.line);
		if(config.stroke){
			SVG._trueStroke = config.stroke;
			CIRC.setAttributeNS(null,"stroke-width",config.stroke);
		}else{
			SVG._trueStroke = 0;
		}
		
		Object.defineProperty(SVG, "Rad", {
			get : function () {
				return this._trueRad;
			},
			set : function (val) {
				this._trueRad = val;
				this._trueWidth = val*2;
				this._trueHeight = val*2;
			},
		});
		
		SVG._trueRad = r;
		SVG.appendChild(CIRC);
		Stage.appendChild(SVG);
		return SVG;
	}
	
	Meta.Image = function(w, h, src, a=true){
		if (CurWindow == null) return;
		
		const IMG = document.createElement("IMG");
		IMG._trueWidth = w;
		IMG._trueHeight = h;
		
		Standard(IMG);
		
		Object.defineProperty(IMG, "Url", {
			get : function () {
				return this._trueSrc;
			},
			set : function (val) {
				this._trueSrc = val;
				this.src = ""; //make it empty if a file takes time loading
				this.src = val;
			},
		});
		
		IMG.Url = src;
		
		//probably never going to tint images
		//if you ever pick this up again this filter tints white to red
		//brightness(50%) sepia(100%) saturate(10000%)
		//can rotate hue to get green and blue
		//could create three version of image, all with additive color
		//then filter each one for the rgb value?
		//probably wont work, but that's the level of bullshit I stopped on
		
		//for now super temporary tint
		//only white or black
		Object.defineProperty(IMG, "Fill", {
			set : function (val) {
				if (val == "white"){
					this.style.filter = "brightness(1)";
				}else if (val == "black"){
					this.style.filter = "brightness(0)";
				}
			},
		});
		
		IMG.draggable = false;
		Stage.appendChild(IMG);
		//temp
		if (!a) IMG.style.backgroundColor = "white";
		return IMG;
	}
	
	Meta.Text = function(txt, config={}){
		if (CurWindow == null) return;
		
		const P = document.createElement("DIV");
		
		if(config.fill){
			P.style.color = (typeof config.fill === "string") ? config.fill : PIXI.utils.hex2string(config.fill);
		}
		
		if(config.size){
			P._trueFont = config.size;
		}
		
		if(config.spacing){
			P._trueSpacing = config.spacing;
		}else{
			P._trueSpacing = 1.34;
		}
		
		P.style.textAlign = "center";
		P.innerHTML = txt;
		
		Object.defineProperty(P, "Text", {
			get : function () {
				return this.innerHTML;
			},
			set : function (val) {
				this.innerHTML = val;
			},
		});
		
		Object.defineProperty(P, "Fill", {
			//~ get : function () {
				//~ return this.innerHTML;
			//~ },
			set : function (val) {
				this.style.color = (typeof val === "string") ? val : window.PIXI.utils.hex2string(val);
			},
		});
		
		Standard(P);
		
		Stage.appendChild(P);
		return P;
	}
	
	window.Meta.Button = function(txt, foo, config={}){
		if (CurWindow == null) return;
		
		const BUTTON = document.createElement("BUTTON");
		BUTTON.style.outline = "none";
		
		if(config.fill){
			BUTTON.style.backgroundColor = config.fill;
		}
		
		Object.defineProperty(BUTTON, "Fill", {
			//~ get : function () {
				//~ return this.innerHTML;
			//~ },
			set : function (val) {
				this.style.backgroundColor = (typeof val === "string") ? val : PIXI.utils.hex2string(val);
			},
		});
		
		if(config.line){
			BUTTON.style.borderColor = config.line;
			BUTTON.style.color = config.line;
		}
		
		Object.defineProperty(BUTTON, "Line", {
			//~ get : function () {
				//~ return this.innerHTML;
			//~ },
			set : function (val) {
				const c = (typeof val === "string") ? val : PIXI.utils.hex2string(val);
				this.style.borderColor = c;
			},
		});
		
		if(config.stroke){
			BUTTON._trueStroke = config.stroke;
		}
		
		if(config.size){
			BUTTON._trueFont = config.size;
			BUTTON._trueSpacing = 1;
		}
		
		BUTTON.innerHTML = txt;
		BUTTON.onclick = foo;
		
		Standard(BUTTON);
		
		Stage.appendChild(BUTTON);
		return BUTTON;
	}
	
	function MakeKeyboard(){
		function keyboard(value) {
			const key = {
				value,
				isDown: false,
				isUp: true,
				press: undefined,
				release: undefined,
			};
			
			key.downHandler = event => {
				if (event.key === key.value) {
					if (key.isUp && key.press) key.press();
					key.isDown = true;
					key.isUp = false;
					event.preventDefault();
				}
			};
			
			key.upHandler = event => {
				if (event.key === key.value) {
					if (key.isDown && key.release) key.release();
					key.isDown = false;
					key.isUp = true;
					event.preventDefault();
				}
			};

			const downListener = key.downHandler.bind(key);
			const upListener = key.upHandler.bind(key);

			window.addEventListener("keydown", downListener, false);
			window.addEventListener("keyup", upListener, false);
			
			key.unsubscribe = () => {
				window.removeEventListener("keydown", downListener);
				window.removeEventListener("keyup", upListener);
			};

			return key;
		};
		
		function touchboard(action){
			const mvnt = {
				done: undefined,
			};
			
			if (action == "swipeL" || action == "swipeR" || action == "swipeU" || action == "swipeD"){
				mvnt.start = (event)=>{
					mvnt.startX = event.touches[0].pageX;
					mvnt.startY = event.touches[0].pageY;
				}
				mvnt.move = (event)=>{
					mvnt.endX = event.touches[0].pageX;
					mvnt.endY = event.touches[0].pageY;
				}
				switch(action){
				case "swipeL":
					mvnt.end = (event)=>{
						const dX = mvnt.endX - mvnt.startX;
						const dY = mvnt.endY - mvnt.startY;
						if(Math.abs(dX) > Math.abs(dY) && dX < 0){
							mvnt.done();
						}
						mvnt.startX = null;
						mvnt.startY = null;
						mvnt.endX = null;
						mvnt.endY = null;
					}
					break;
				case "swipeR":
					mvnt.end = (event)=>{
						const dX = mvnt.endX - mvnt.startX;
						const dY = mvnt.endY - mvnt.startY;
						if(Math.abs(dX) > Math.abs(dY) && dX >= 0){
							mvnt.done();
						}
						mvnt.startX = null;
						mvnt.startY = null;
						mvnt.endX = null;
						mvnt.endY = null;
					}
					break;
				case "swipeU":
					mvnt.end = (event)=>{
						const dX = mvnt.endX - mvnt.startX;
						const dY = mvnt.endY - mvnt.startY;
						if(Math.abs(dY) > Math.abs(dX) && dY < 0){
							mvnt.done();
						}
						mvnt.startX = null;
						mvnt.startY = null;
						mvnt.endX = null;
						mvnt.endY = null;
					}
					break;
				case "swipeD":
					mvnt.end = (event)=>{
						const dX = mvnt.endX - mvnt.startX;
						const dY = mvnt.endY - mvnt.startY;
						if(Math.abs(dY) > Math.abs(dX) && dY >= 0){
							mvnt.done();
						}
						mvnt.startX = null;
						mvnt.startY = null;
						mvnt.endX = null;
						mvnt.endY = null;
					}
					break;
				}
			}
			
			
			const starter = mvnt.start.bind(mvnt);
			const mover = mvnt.move.bind(mvnt);
			const ender = mvnt.end.bind(mvnt);
			
			CurWindow.addEventListener("touchstart", starter, false);
			CurWindow.addEventListener("touchmove", mover, false);
			CurWindow.addEventListener("touchend", ender, false);
			
			mvnt.unsubscribe = () => {
				CurWindow.removeEventListener("touchstart", starter);
				CurWindow.removeEventListener("touchmove", mover);
				CurWindow.removeEventListener("touchend", ender);
			};
			
			return mvnt;
		}
		
		const KB = {
			_direction : [],
			_accept : [],
			_fullSet : [],
			_numSet : [],
			_quickKeys : {},
			Relinquish : function(){
				Object.values(this._quickKeys).forEach(val => val.unsubscribe());
				this._quickKeys = {};
				
				this._direction.forEach((e)=>{
					e.unsubscribe();
				});
				this._direction.length = 0;
				
				this._accept.forEach((e)=>{
					e.unsubscribe();
				});
				this._accept.length = 0;
				
				this._fullSet.forEach((e)=>{
					e.unsubscribe();
				});
				this._fullSet.length = 0;
				
				this._numSet.forEach((e)=>{
					e.unsubscribe();
				});
				this._numSet.length = 0;
				
				for (const [key, value] of Object.entries(this._quickKeys)) {
					value.unsubscribe();
				}
				this._quickKeys = {};
			},
			QuickKey : function(key){
				this._quickKeys[key] = keyboard(key);
			},
			OnQuickKey : function(key, foo){
				this._quickKeys[key].press = foo;
			},
			RequestDirection : function(){
				//0 = Left
				//1 = Right
				//2 = Up
				// 3 = Down
				//4 = W
				//5 = A
				//6 = S
				//7 = D
				if (this._direction.length == 0){
					this._direction.push(keyboard("ArrowLeft"));
					this._direction.push(keyboard("ArrowRight"));
					this._direction.push(keyboard("ArrowUp"));
					this._direction.push(keyboard("ArrowDown"));
					this._direction.push(keyboard("w"));
					this._direction.push(keyboard("a"));
					this._direction.push(keyboard("s"));
					this._direction.push(keyboard("d"));
					this._direction.push(keyboard("W"));
					this._direction.push(keyboard("A"));
					this._direction.push(keyboard("S"));
					this._direction.push(keyboard("D"));
					this._direction.push(touchboard("swipeL"));
					this._direction.push(touchboard("swipeR"));
					this._direction.push(touchboard("swipeU"));
					this._direction.push(touchboard("swipeD"));
				}
			},
			OnLeft : function(foo){
				this._direction[0].press = foo;
				this._direction[5].press = foo;
				this._direction[9].press = foo;
				this._direction[12].done = foo;
			},
			OnRight : function(foo){
				this._direction[1].press = foo;
				this._direction[7].press = foo;
				this._direction[11].press = foo;
				this._direction[13].done = foo;
			},
			OnUp : function(foo){
				this._direction[2].press = foo;
				this._direction[4].press = foo;
				this._direction[8].press = foo;
				this._direction[14].done = foo;
			},
			OnDown : function(foo){
				this._direction[3].press = foo;
				this._direction[6].press = foo;
				this._direction[10].press = foo;
				this._direction[15].done = foo;
			},
			Left : function(){
				return this._direction[0].isDown || this._direction[5].isDown;
			},
			Right : function(){
				return this._direction[1].isDown || this._direction[7].isDown;
			},
			Up : function(){
				return this._direction[2].isDown || this._direction[4].isDown;
			},
			Down : function(){
				return this._direction[3].isDown || this._direction[6].isDown;
			},
			RequestAccept : function(){
				if (this._accept.length == 0){
					this._accept.push(keyboard(" "));
					this._accept[0].press = this.DoAccept;
					this._accept[0].This = this;
					this._accept[0].foos = [];
				}
			},
			OnAccept : function(foo){
				this._accept[0].foos.push(foo);
			},
			DoAccept : function(){
				this.This._accept[0].foos.forEach(foo=>foo());
			},
			RequestFullSet : function(){
				if (this._fullSet.length > 0) return;
				
				Array.from("qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890").forEach((e) => {
					const key = keyboard(e);
					key.press = this.DoFullSet;
					key.board = this;
					this._fullSet.push(key);
				});
				const key = keyboard("Backspace");
				key.press = this.DoFullSet;
				key.board = this;
				this._fullSet.push(key);
				//~ console.log(this._fullSet);
			},
			DoFullSet : function(){
				this.board.OnFullSet(this.value.toUpperCase());
			},
			OnFullSet : function(){},
			RequestNumSet : function(){
				if (this._numSet.length > 0) return;
				
				let key;
				Array.from("1234567890").forEach((e) => {
					key = keyboard(e);
					key.press = this.DoNumSet;
					key.board = this;
					this._numSet.push(key);
				});
				this._numSet.push(key);
			},
			DoNumSet : function(){
				this.board.OnNumSet(this.value);
			},
			OnNumSet : function(){},
		};
		
		return KB;
	}
	
	function KeyboardCheck(){
		if(!CurNode.keyboard){
			CurNode.keyboard = MakeKeyboard();
			// for now
			CurNode.keyboard.RequestAccept();
			CurNode.keyboard.RequestDirection();
		}
	}
	
	Meta.KeyDown = function(code, foo){
		if (CurWindow == null) return;
		
		KeyboardCheck();
		
		switch(code){
		case "Accept":
			CurNode.keyboard.OnAccept(foo);
			break;
		case "Left":
			CurNode.keyboard.OnLeft(foo);
			break;
		case "Right":
			CurNode.keyboard.OnRight(foo);
			break;
		case "Up":
			CurNode.keyboard.OnUp(foo);
			break;
		case "Down":
			CurNode.keyboard.OnDown(foo);
			break;
		case "Numbers":
			CurNode.keyboard.RequestFullSet();
			CurNode.keyboard.OnFullSet = foo;
			break;
		case "Full":
			CurNode.keyboard.RequestFullSet();
			CurNode.keyboard.OnFullSet = foo;
			break;
		default:
			//~ console.log("No quick key yet");
			CurNode.keyboard.QuickKey(code.toUpperCase());
			CurNode.keyboard.QuickKey(code.toLowerCase());
			CurNode.keyboard.OnQuickKey(code.toUpperCase(), foo);
			CurNode.keyboard.OnQuickKey(code.toLowerCase(), foo);
			break;
		}
	}
	
	Meta.Key = function(code){
		if (CurWindow == null) return false;
		
		KeyboardCheck();
		
		switch(code){
		case "Accept":
			//~ CurNode.keyboard.OnAccept(foo);
			return false;
		case "Left":
			return CurNode.keyboard.Left();
		case "Right":
			return CurNode.keyboard.Right();
		case "Up":
			return CurNode.keyboard.Up();
		case "Down":
			return CurNode.keyboard.Down();
		default:
			console.log("No full set yet");
			return false;
		}
	}
	
	function MakeMouse(){
		const mouse = {
			leftclick : [],
			rightclick : [],
			move : [],
		}
		
		const downRouter = E => {
			if (E.which == 1){
				for (var e = mouse.leftclick.length-1; e >= 0; e--){
					mouse.leftclick[e](E);
				}
			}else if (E.which == 3){
				for (var e = mouse.rightclick.length-1; e >= 0; e--){
					mouse.rightclick[e](E);
				}
			}
		};
		
		const moveRouter = E => {
			mouse.move.forEach(foo=>foo());
		};
		
		document.addEventListener("mousedown", downRouter, false);
		document.addEventListener("mousemove", moveRouter, false);

		// Detach event listeners
		mouse.Relinquish = () => {
			document.removeEventListener("mousedown", downRouter);
			document.removeEventListener("mousemove", moveRouter);
		};

		return mouse;
	}
	
	function MouseCheck(){
		if(!CurNode.mouse){
			CurNode.mouse = MakeMouse();
		}
	}
	
	Meta.MouseClick = function(side, foo){
		if (CurWindow == null) return false;
		
		MouseCheck();
		
		if (side == "Left"){
			CurNode.mouse.leftclick.push(foo);
		}else if (side == "Right"){
			CurNode.mouse.rightclick.push(foo);
		}
	}
	
	Meta.MouseMove = function(foo){
		if (CurWindow == null) return false;
		
		MouseCheck();
		
		CurNode.mouse.move.push(foo);
	}
	
	Meta.Slider = function(min, max, val, foo){
		if (CurWindow == null) return;
		
		const SLIDER = document.createElement("INPUT");
		SLIDER.type = "range";
		SLIDER.min = min;
		SLIDER.max = max;
		SLIDER.value = val;
		SLIDER.classList.add("slider-purple");
		
		SLIDER.oninput = foo;
		
		Standard(SLIDER);
		
		Stage.appendChild(SLIDER);
		return SLIDER;
	};
	
	Meta.Comment = function(ph, config={}){
		if (CurWindow == null) return;
		
		const TEXTAREA = document.createElement("input"); //keep everything to one line for now
		
		if(config.size){
			TEXTAREA._trueFont = config.size;
			TEXTAREA._trueSpacing = 1;
		}
		
		TEXTAREA.placeholder = ph;
		TEXTAREA.style.overflow = "hidden";
		TEXTAREA.style.textAlign = "center";
		TEXTAREA.style.background = "black";
		TEXTAREA.style.color = "white";
		
		Object.defineProperty(TEXTAREA, "Text", {
			get : function () {
				return this.value;
			},
			set : function (val) {
				this.value = val;
			},
		});
		
		Standard(TEXTAREA);
		
		Stage.appendChild(TEXTAREA);
		return TEXTAREA;
	};
	
	Meta.QuickYN = function(name, config={}){
		if (CurWindow == null) return;
		
		const DIV = document.createElement("DIV");
		
		const Yes = document.createElement("SPAN");
		Yes.innerHTML = "Yes";
		Yes.style.marginLeft = "10px";
		Yes.style.marginRight = "10px";

		const Y = document.createElement("INPUT");
		Y.type = "radio";
		Y.name = name;
		Y.style.transform = "scale(1.5)";
		
		const No = document.createElement("SPAN");
		No.innerHTML = "No";
		No.style.marginLeft = "10px";
		No.style.marginRight = "10px";
		
		const N = document.createElement("INPUT");
		N.type = "radio";
		N.name = name;
		N.style.transform = "scale(1.5)";
		
		DIV.appendChild(Yes);
		DIV.appendChild(Y);
		DIV.appendChild(No);
		DIV.appendChild(N);
		
		if(config.size){
			DIV._trueFont = config.size;
			DIV._trueSpacing = 1;
		}
		
		DIV.style.textAlign = "center";
		DIV.style.color = "white";
		
		DIV.Y = Y;
		DIV.N = N;
		
		Object.defineProperty(DIV, "Answer", {
			get : function () {
				return this.Y.checked ? "Yes" : (this.N.checked ? "No" : null);
			},
		});
		
		Standard(DIV);
		
		Stage.appendChild(DIV);
		return DIV;
	};
	
	Meta.Path = function(w, h, config={}){
		const SVG = document.createElementNS(W3SVG, "svg");
		Standard(SVG);
		StandardSVG(SVG);
		
		const PATH = document.createElementNS(W3SVG, "path");
		if(config.fill) {
			PATH.setAttributeNS(null,"fill",config.fill);
		}else{
			PATH.setAttributeNS(null,"fill-opacity","0.0");
		};
		if(config.line) PATH.setAttributeNS(null,"stroke",config.line);
		if(config.stroke){
			SVG._trueStroke = config.stroke;
			PATH.setAttributeNS(null,"stroke-width",config.stroke);
		}
		
		SVG._truePath = "";
		PATH.setAttributeNS(null,"d","");
		
		SVG.MOVE = function(x, y){
			this._truePath += " M" + x + " " + y;
		}
		
		SVG.LINE = function(x, y){
			this._truePath += " L" + x + " " + y;
		}
		
		SVG.DRAW = function(x, y){
			this.children[0].setAttributeNS(null, "d", this._truePath);
		}
		
		SVG.CLEAR = function(){
			this._truePath = "";
		}
		
		Object.defineProperty(SVG, "Path", {
			get : function () {
				return this._truePath;
			},
			set : function (val) {
				this._truePath = val;
				this.DRAW();
			},
		});
		
		SVG._trueWidth = w;
		SVG._trueHeight = h;
		SVG.appendChild(PATH);
		Stage.appendChild(SVG);
		return SVG;
	};
	
	Meta.Svg = function(w, h, src, config={}){
		const SVG = document.createElementNS(W3SVG, "svg");
		Standard(SVG);
		StandardSVG(SVG);
		
		//~ const PATH = document.createElementNS(W3SVG, "path");
		//~ if(config.fill) {
			//~ PATH.setAttributeNS(null,"fill",config.fill);
		//~ }else{
			//~ PATH.setAttributeNS(null,"fill-opacity","0.0");
		//~ };
		//~ if(config.line) PATH.setAttributeNS(null,"stroke",config.line);
		//~ if(config.stroke){
			//~ SVG._trueStroke = config.stroke;
			//~ PATH.setAttributeNS(null,"stroke-width",config.stroke);
		//~ }
		
		SVG._trueWidth = w;
		SVG._trueHeight = h;
		SVG.innerHTML = src;
		Stage.appendChild(SVG);
		return SVG;
	};
	
	Meta.Sound = function(src, vol=1){
		const Sound = document.createElement("AUDIO");
		Sound.src = src;
		SoundStage.appendChild(Sound);
		Sound.load();
		Sound.volume = vol;
		
		Sound.Play = function(){
			this.currentTime = 0;
			this.play();
		}
		
		return Sound;
	};
	
	Meta.Raincheck = function(dur, foo){
		const prom = new Promise(res => setTimeout(res, dur));
		prom.then(foo);
	};
	
	Meta.Canvas = function(w, h, draw){
		const CANVAS = document.createElement("CANVAS");
		Standard(CANVAS);
		CANVAS._trueWidth = w;
		CANVAS._trueHeight = h;
		CANVAS.CTX = CANVAS.getContext('2d');
		CANVAS.BUFF = {Width:0, Height:0};
		CANVAS._DrawFoo = draw;
		CANVAS._FirstDraw = false;
		
		CANVAS.GetPixel = function(x, y){
			const dex = y * this.BUFF.width + x;
			const data = this.BUFF.data;
			
			return [data[dex], data[dex+1], data[dex+2], data[dex+3]]
		}
		
		CANVAS.SetPixel = function(x, y, r, g, b, a=255){
			const dex = (y * this.BUFF.width + x) * 4;
			const data = this.BUFF.data;
			
			data[dex] = r;
			data[dex+1] = g;
			data[dex+2] = b;
			data[dex+3] = a;
		}
		
		CANVAS.Redraw = function(){
			if (this._CanvasLock && this._FirstDraw) return;
			if (this.BUFF.Width == 0) return;
			this._DrawFoo();
			this.Draw();
			this._FirstDraw = true;
		}
		
		CANVAS.Draw = function(){
			this.CTX.putImageData(this.BUFF, 0, 0);
		}
		
		CANVAS.BackBuffer = function(){
			if (this._CanvasLock && this._FirstDraw) return;
			this.BUFF = this.CTX.createImageData(this.Width, this.Height);
			this._DrawFoo();
			this._FirstDraw = true;
		}
		
		CANVAS.Lock = function(){
			this._CanvasLock = true;
		}

		Stage.appendChild(CANVAS);
		return CANVAS;
	}
	
	function WrapState(code){
		const wrap = {
			code,
			On : function(key, foo){
				onValue(ref(rtdb, 'codes/' + this.code + "/" + key), (snapshot) => {foo(snapshot.val())})
				// rtdb.ref("/rt_states/" + this.code + "/" + key).on("value", function(snap){foo(snap.val())});
			},
			Read : function(key){
				return new Promise((resolve, reject) => {
					get(child(ref(rtdb), 'codes/' + this.code + "/" + key)).then((snapshot => {
						if (snapshot.exists()) {
							resolve(snapshot.val());
						} else {
							resolve(null);
						}
					}))
				})
			},
			Write : function(key, val){
				let obj = {};
				obj[key] = val;
				update(ref(rtdb, 'codes/' + this.code), obj);
				// rtdb.ref("/rt_states/" + this.code).update(obj);
			},
		}
		return wrap;
	}
	
	var gameStateIds = [];
	Meta.GameState = function(){
		return new Promise((resolve, reject) => {
			function GetID(){
				var code = '';
				//~ const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const chars = '0123456789';
				const L = chars.length;
				for ( var i = 0; i < 6; i++ ) {
					code += chars.charAt(Math.floor(Math.random() * L));
				}
				get(child(ref(rtdb), 'codes/' + code)).then((snapshot) => {
					if (snapshot.exists()) {
						GetID();
					} else {
						set(ref(rtdb, 'codes/' + code), {
							timestamp: Date.now()
						})
						gameStateIds.push(code);
						
						resolve(WrapState(code));
					}
				}).catch((error) => {
					reject(error);
				});
			}
			
			GetID();
		})
	}
	
	Meta.JoinGame = function(id){
		return WrapState(id);
	}
	
	Meta.QRC = function(w, h, link){
		if (CurWindow == null) return;
		
		const DIV = document.createElement("DIV");
		DIV._trueWidth = w;
		DIV._trueHeight = h;
		DIV.style.textAlign = "center";
		
		var el = kjua({text: link});
		el.style.width = "100%";
		el.style.height = "100%";
		DIV.appendChild(el);
		
		Standard(DIV);
		Stage.appendChild(DIV);
		return DIV;
	}
}( window.Meta = window.Meta || {}));

//Legacy MetaEngine

class MetaEngine{
	constructor(div){
		this.SELF = this;
		this.documentEle = div;
		this.PixiApp = new window.PIXI.Application({width: parseInt(div.style.width), height: parseInt(div.style.height), backgroundColor: 0x00ff00, antialias: true});
		this.PixiApp.view.style.pointerEvents = "auto";
		div.appendChild(this.PixiApp.view);
		
		this.PixiApp.ticker.add((delta)=>{this.TickWrapper(delta);});
		
		//~ document.addEventListener("fullscreenchange", () =>{
			//~ if (document.fullscreenElement == null){
				//~ this.PixiApp.renderer.resize(parseInt(div.style.width), parseInt(div.style.height));
				//~ this.Visibility(!this._FullscreenOnly);
				//~ this.DoResize();
			//~ }
		//~ });
		
		this._Data = {};
		this._Loader = new window.PIXI.Loader();
		
		this.PixiApp.renderer.plugins.interaction.on('pointerdown', ()=>{
			if (this._Data.PointDown != null){
				this._Data.PointDown();
			}
		});
		this.PixiApp.renderer.plugins.interaction.on('pointerup', ()=>{
			if (this._Data.PointUp != null){
				this._Data.PointUp();
			}
		});
		
		div.style.position = "relative";
		this.FrontLayer = document.createElement("DIV");
		div.appendChild(this.FrontLayer);
		this.FrontLayer.style.width = "100%";
		this.FrontLayer.style.height = "100%";
		this.FrontLayer.style.backgroundColor = "rgba(0, 0, 0, 0)";
		this.FrontLayer.style.position = "absolute";
		this.FrontLayer.style.top = "0";
		
		this.FrontLayer.unblockClicks = function(){
			this.style.pointerEvents = "none";
		};
		
		this.FrontLayer.blockClicks = function(){
			this.style.removeProperty("pointer-events");
		};
	}
	
	Object(){
		const obj = {};

		obj.OnDeath = [];
		
		obj.Die = function(){
			this.OnDeath.forEach((e)=>{e(this)});
		}
		
		return obj;
	}
	
	Resize(w, h){
		//https://www.html5rocks.com/en/tutorials/canvas/hidpi/#disqus_thread
		this.PixiApp.renderer.resize(w * window.devicePixelRatio, h * window.devicePixelRatio);
		this.PixiApp.view.style.width = w + "px";
		this.PixiApp.view.style.height = h + "px";
		this.DoResize();
	}
	
	Visibility(bool){
		if (bool){
			this.PixiApp.view.style.removeProperty("display");
		}else{
			this.PixiApp.view.style.display = "none";
		}
	}
	
	End(){
		if (this._Data && this._Data.KountdownEnd){
			this._Data.KountdownEnd.call(this._Data, this);
		}
	}
	
	DoResize(){
		//~ this.AntiZoomX = this.PixiApp.renderer.width;
		//~ this.AntiZoomY = this.PixiApp.renderer.height;
		//~ this.ZoomX = window.screen.width/this.AntiZoomX;
		//~ this.ZoomY = window.screen.height/this.AntiZoomY;
		if (this._Data.Resize != null){
			if (Array.isArray(this._Data.Resize)){
				this._Data.Resize.forEach((e)=>{e.call(this._Data, this)});
			}else{
				this._Data.Resize.call(this._Data, this);
			}
		}
	}
	
	get FullscreenOnly(){
		return this._FullscreenOnly;
	}
	
	set FullscreenOnly(bool){
		this._FullscreenOnly = bool;
		//~ this.Visibility(!bool);
	}
	
	set backgroundColor(color){
		this.PixiApp.renderer.backgroundColor = color;
	}
	
	Void(){
		//~ this.backgroundColor = 0x000000;
		while(this.PixiApp.stage.children[0]) {
			this.PixiApp.stage.removeChild(this.PixiApp.stage.children[0]);
		}
		if (this._Data){
			if (Array.isArray(this._Data.Cleanup)){
				this._Data.Setup.forEach((e)=>{if(this._Data.Cleanup == null)return;e.call(this._Data, this)});
			}else if (this._Data.Cleanup){
				this._Data.Cleanup.call(this._Data, this);
			}
		}
		
		while(this.FrontLayer.firstChild){
			this.FrontLayer.removeChild(this.FrontLayer.firstChild);
		}
		this.FrontLayer.unblockClicks();
		
		this._Data = {};
		//~ this.AntiZoomX = -1;
		//~ this.AntiZoomY = -1;
		this.Cursor(1);
	}
	
	TickWrapper(delta){
		//~ if(this.AntiZoomX > 0 && this.AntiZoomY > 0){
			//~ if (window.innerWidth != this.AntiZoomX || window.innerHeight != this.AntiZoomY){
				//~ this.Resize(window.innerWidth, window.innerHeight);
			//~ }
		//~ }
		
		if (this._Data.Tick != null){
			if (Array.isArray(this._Data.Tick)){
				this._Data.Tick.forEach((e)=>{if(this._Data.Tick == null)return;e.call(this._Data, this, delta)});
			}else{
				this._Data.Tick.call(this._Data, this, delta);
			}
		}
		
		if (this._Data.TempTick != null){
			this._Data.TempTick(delta);
		}
	}
	
	TempTicker(foo){
		this._Data.TempTick = foo;
	}
	
	//Note that using arrow notation for anon foos breaks this
	Do(data){
		this.Void();
		
		if (Array.isArray(data.Load)){
			data.Load.forEach((fn)=>{
				if (this._Loader.resources[fn[0]] == null){
					this._Loader.add(fn[0], fn[1]);
				}
			});
		}
		
		this._Loader.load((loader, resources) =>{
			this._Data = data;
			
			if (Array.isArray(data.Load)){
				data.Load.forEach((fn)=>{
					switch(fn[1].split(".").pop()){
					case "jpg":
					case "png":
						this._Data[fn[0]] = resources[fn[0]].texture;
						break;
					case "frag":
						this._Data[fn[0]] = resources[fn[0]].data;
						break;
					default:
						this._Data[fn[0]] = "unknown";
						break;
					}
				});
			}
			if (Array.isArray(data.Setup)){
				data.Setup.forEach((e)=>{if(this._Data.Setup == null)return;e.call(this._Data, this)});
			}else if (data.Setup){
				data.Setup.call(this._Data, this);
			}
			
			if (Array.isArray(data.Start)){
				data.Start.forEach((e)=>{if(this._Data.Start == null)return;e.call(this._Data, this)});
			}else if (data.Start){
				data.Start.call(this._Data, this);
			}
			
			window.Meta.Redraw();
		})
	}
	
	pDown(foo){
		this._Data.PointDown = foo;
	}
	
	pUp(foo){
		this._Data.PointUp = foo;
	}
	
	Cursor(b){
		//~ if(b){
			//~ this.documentEle.style.removeProperty("cursor");
		//~ }else{
			//~ this.documentEle.style.cursor = "none";
		//~ }
	}
	
	//Quick Modules
	get Screen(){return this.PixiApp.screen};
	get Bounds(){return this._Data.__rootBox__};
	
	BlankFunction(){}
}

(function( Random, undefined ) {
	Random.Dex = function(len){
		return Math.floor(Math.random() * len);
	}
	
	Random.Round = function(val, d){
		const mag = Math.pow(10, d);
		return Math.round(val*mag)/mag;
	}
	
	Random.Range = function(min, max){
		return Math.random() * (max - min) + min;
	}
	
	Random.fmod = function(a, b){
		return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
	}
	
	var LastCompass = -1;
	Random.Compass = function(){
		var ret = Random.Dex(4);
		while (ret == LastCompass){
			ret = Random.Dex(4);
		}
		LastCompass = ret;
		return ret;
	};
	
	Random.Vector = function(spd, flag){
		var ret;
		switch(flag){
		case "H":
			ret = [Random.Dex(2)*2 - 1, 0];
			break;
		case "V":
			ret = [0, Random.Dex(2)*2 - 1];
			break;
		case "D":
			ret = [Random.Dex(2)*2 - 1, Random.Dex(2)*2 - 1];
			break;
		case "4":
			if (Random.Dex(2) == 0){
				ret = [Random.Dex(2)*2 - 1, 0];
			}else{
				ret = [0, Random.Dex(2)*2 - 1];
			}
			break;
		case "O":
			ret = [Math.random()*2-1, Math.random()*2-1];
			const mag = Math.hypot(ret[0], ret[1]);
			ret[0] /= mag;
			ret[1] /= mag;
			break;
		case "8":
		default:
			ret = [Random.Dex(3) - 1, (Random.Dex(3) - 1)];
			while (ret[0] == 0 && ret[1] == 0){
				ret = [Random.Dex(3) - 1, (Random.Dex(3) - 1)];
			}
			break;
		}
		ret[0] *= spd;
		ret[1] *= spd;
		return ret;
	};
	
	Random.Color = function(legacy=null){
		const hexchars = '0123456789ABCDEF';
		const L = hexchars.length;
		var str = "#";
		for ( var i = 0; i < 6; i++) {
			str += hexchars.charAt(Math.floor(Math.random() * L));
		}
		
		if(legacy){
			return PIXI.utils.string2hex(str);
		}
		return str;
	};
	
	Random.Char = function(mask, filter=''){
		//TFT is a mask example {upper|lower|numbers}
		var sampleSpace = "";
		if(mask[0] == "T"){
			sampleSpace += 'QWERTYUIOPASDFGHJKLZXCVBNM';
		}
		if(mask[1] == "T"){
			sampleSpace += 'qwertyuiopasdfghjklzxcvbnm';
		}
		if(mask[2] == "T"){
			sampleSpace += '1234567890';
		}
		
		const L = sampleSpace.length;
		var ret = '';
		do{
			ret = sampleSpace.charAt(Math.floor(Math.random() * L));
		}while (filter.indexOf(ret) > -1);
		return ret;
	};
}( window.MetaRandom = window.Random || {}));

(function( Vector, undefined ) {
	Vector.Distance = function(v1, v2){
		if (v1.length == 2 && v2.length == 2){
			return Math.hypot(v1[0]-v2[0], v1[1]-v2[1]);
		}else{
			return 0/0;
		}
	};
	Vector.Direction = function(v1, v2){
		if (v1.length == 2 && v2.length == 2){
			const RoR = [v1[0]-v2[0], v1[1]-v2[1]];
			const mag = Math.hypot(RoR[0], RoR[1]);
			return [RoR[0]/mag, RoR[1]/mag];
		}else{
			return [0, 0];
		}
	};
}( window.MetaVector = window.MetaVector || {}));

//Replace these with meta version eventually
window.Round = function (val, d){
	const mag = Math.pow(10, d);
	return Math.round(val*mag)/mag;
};
window.fmod = function(a, b){
	return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
};
window.Random = function(min, max){
	return Math.random() * (max - min) + min;
};
window.Randex = function(len){
    return Math.floor(Math.random() * len);
};
window.Clamp = function(val, min, max){
	return Math.min(Math.max(val, min), max);
};

(function( Vision, undefined ) {
	Vision.ScreenSize = 42;
	Vision.PatientDistance = 42;
	Vision.RightColor = "#ff0000";
	Vision.LeftColor = "#0000ff";
	
	Vision.VaToArc = function(va, gapCount = 5){
		const Arcmin = 1/va * gapCount;
		return Arcmin * 60;
	};
	Vision.PdToArc = function(pd){
		const DisplacementInRadians = Math.atan(pd/100);
		return (180 / Math.PI) * DisplacementInRadians * 3600;
	};
	Vision.ArcToCm = function(arc){
		const HalfArcInRadian = ((arc/2) / 3600) * (Math.PI / 180);
		return Math.tan(HalfArcInRadian) * this.PatientDistance * 2;
	};
	Vision.CmToPix = function(cm){
		//Random note, this is wrong when first going fullscreen if the console is open
		const w = window.Meta.Stage.Width;
		const h = window.Meta.Stage.Height;
		const DiagonalInPixels = Math.sqrt((w * w) + (h * h));
		return DiagonalInPixels / (this.ScreenSize*(w/window.Meta.Stage.Width)*2.54) * cm;
	};
	Vision.PixToCm = function(pix){
		const w = window.Meta.Stage.Width;
		const h = window.Meta.Stage.Height;
		const DiagonalInPixels = Math.sqrt((w * w) + (h * h));
		return pix / (DiagonalInPixels / (this.ScreenSize*(w/window.Meta.Stage.Width)*2.54));
	};
	Vision.CmToArc = function(cm){
		const HalfArcInRadian = Math.atan((cm / 2) / this.PatientDistance);
		return (HalfArcInRadian * 2) * (180 / Math.PI) * 3600;
	};
	Vision.ArcToPd = function(arc){
		const DisplacementInRadians = (arc / 3600) * (Math.PI / 180);
		return 100 * Math.tan(DisplacementInRadians);
	};
	Vision.BlindRad = function(){
		return this.CmToPix(this.ArcToCm(2.2*3600));
	};
}( window.MetaVision = window.MetaVision || {}));

//Calibration Stuff

(function( Cali, undefined ) {
	var ScreenCert = true;

	var ScreenSize = localStorage.getItem("PREF_SS");
	if (ScreenSize == undefined){
		ScreenSize = 0;
		ScreenCert = false;
	}
	
	const ScreenSizeInput = document.getElementById("ScreenCali");
	if (ScreenSizeInput && ScreenCert) ScreenSizeInput.value = ScreenSize;
	
	Object.defineProperty(Cali, "ScreenSize", {
		get : function () {
			return ScreenSize;
		},
		set : function (val) {
			ScreenCert = true;
			
			ScreenSize = val;
			window.MetaVision.ScreenSize = val;
			if (ScreenSizeInput) ScreenSizeInput.value = val;
			localStorage.setItem("PREF_SS", val);
		},
	});

	window.MetaVision.ScreenSize = ScreenSize;
	window.MetaVision.PatientDistance = 50;
	
	Object.defineProperty(Cali, "LeftColor", {
		get : function () {
			return window.PIXI.utils.rgb2hex([0, 0, 1]);
		}
	});
	
	Object.defineProperty(Cali, "RightColor", {
		get : function () {
			return window.PIXI.utils.rgb2hex([1, 0, 0]);
		}
	});
	
	Object.defineProperty(Cali, "LeftColorMax", {
		get : function () {
			return window.PIXI.utils.rgb2hex([0, 0, 1]);
		}
	});
	
	Object.defineProperty(Cali, "RightColorMax", {
		get : function () {
			return window.PIXI.utils.rgb2hex([1, 0, 0]);
		}
	});
	
	Object.defineProperty(Cali, "Ready", {
		get : function () {
			return ScreenCert;
		}
	});
	
	Object.defineProperty(Cali, "ScreenReady", {
		get : function () {
			return ScreenCert;
		}
	});
	
	Object.defineProperty(Cali, "ColorReady", {
		get : function () {
			return true;
		}
	});
}( window.Cali = window.Cali || {}));
