import { Grid, Typography, Stack, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const HeaderCard = (props) => {

  const { t } = useTranslation();

  return(
    <Grid item xs={12} lg={3}>
      <Box
        sx={{
          "&:hover": {
            "& > .on": {display: 'none'},
            "& > .off": {display: 'flex'}
          }
        }}
      >
        <Stack
          height={280}
          display={'flex'}
          alignItems={'center'}
          href={props.activity.randomArray[Math.floor(Math.random() * props.activity.randomArray.length)]}
          py={1}
          className="on"
        >
          <Box width="100%" height="100%" display={'flex'} justifyContent={'center'}>
            {props.activity.img}
          </Box>
          <Typography color={'#fafafa'} variant="h2" align="center" pt={2} gutterBottom>{ t(props.activity.title) }</Typography>
          {/* <Typography color={'#fafafa'} variant="h5" align="center">{dailyString}</Typography> */}
        </Stack>
        <Stack
          height={280}
          alignItems={'center'}
          justifyContent={'center'}
          href={props.activity.randomArray[Math.floor(Math.random() * props.activity.randomArray.length)]}
          py={1}
          px={2}
          className="off" display={'none'}
        >
          <Typography variant="h5" color={'#fafafa'} align="center" py={2} gutterBottom>{t(props.activity.description)}</Typography>
          <Button variant="contained" color="secondary" href={props.activity.notion}>{t('home.learn-more')}</Button>
        </Stack>
      </Box>
    </Grid>
  );
}

export default HeaderCard;