import Arrow from './Arrow.png'
import { createUserPlayedGame } from '../firebase/userPlayedGames';
import { updateUserDailyActivity } from '../firebase/userDailyActivities';
import { CountdownScreen, Katalyst, ReadyScreen } from './Katalyst';
import { instructionData } from '../data/instructionData';

const { LegacyEmbed, PlayerCursor, Trackerz, TumblingEKreator } = require("./StandardEngines")
const { VT2, RDS, AX, Tracker, TrackerS, Reakt, Kreators, Shuffle4, Spritors, AXLR, TrackerLR, VectorUtils, Raincheck } = require("./LegacyEngines");
const { VT_Base } = require("./StandardEngines");
const { Meta, MetaRandom, Randex } = require("./metaengine");

export function LaunchGame(GID){
	const engine = {
		Start : function(){
			if(instructionData[GID].pregame.reqL) {
				console.log(GID);
				window.Meta.set("Pregame", instructionData[GID].pregame)
				window.Meta.SubDo(ReadyScreen, Katalyst.Start);
			} else {
				window.Meta.SubDo(CountdownScreen, Katalyst.Start);
			}
		}
	};

	engine.ENG = _Game_Library_[GID][0];

	const SEND = {};
	Object.entries(_Game_Library_[GID][1]).forEach((e)=>{
		const parser = _Settings_Procs_[e[0]];
		if (parser){
			engine[e[0]] = parser(e[1]);
			//~ SEND[e[0]] = parser(e[1]);
		}else{
			engine[e[0]] = e[1];
			//~ SEND[e[0]] = e[1];
		}
	});

	//~ if(metadata.pregame && metadata.pregame.reqL){
		//~ combo["_SEND_"] = SEND;
	//~ }

	window.Meta.Do(engine, function(res){
		if (res){
			//Temp Backend
			const json = localStorage.getItem("RES_" + GID);
			const array = json ? JSON.parse(json) : [];

			array.push(res);
			localStorage.setItem("RES_" + GID, JSON.stringify(array));
		}
	});
}

//Temp Chart Results
export function GetSessionResults(GID, Stat, dex=null){
	const json = localStorage.getItem("RES_" + GID);
	const array = json ? JSON.parse(json) : [];

	const ret = [];
	for (let R of array){
		switch(Stat){
		case "Points":
			ret.push(R.Score);
			break;
		default:
			if (dex != null){
				ret.push(R[Stat][dex]);
			}else{
				ret.push(R[Stat]);
			}
			break;
		}
	}

	return ret;
}

export function ClearResults(GID){
	localStorage.removeItem("RES_" + GID);
}

export const _Settings_Procs_ = {
	Acuity : function(val){const ns = val.split("/"); return ns[0]/ns[1];},
	StereoAcuity : function(val){return parseInt(val);},
	SpeedPerHour : function(val){return (val * 10000)/60/60;},
	ThreshSpeed : function(val){return {Dex: val, Val: function(){return ((this.Dex*0.2) * 100000)/60/60}}},
	// ThreshStereo : function(val){return {Dex: val, Val: function(){this.Dex = Math.min(this.Dex, ThreshholdStereoArray.length); return ThreshholdStereoArray[this.Dex-1];}}},
	SpeedRPM : function(val){return val/60},
	FlashSpeed : function(val){return parseFloat(val);},
};

export const ScreenCali_Engine = {
	Start : function(){
		const Small = window.Meta.Stage.Height < 1080;

		const TITLE = window.Meta.Text("Screen Calibration", {fill:"white", size:Small?32:48});
		TITLE.Top = Small?-320:-500;
		TITLE.Left = 0;
		TITLE.Right = 0;
		window.Meta.Stage.Background("#000000");

		const CARD = window.Meta.Image(286, 180, "id.png", true);
		CARD.Top = -90;
		CARD.Left = -143;

		const INST = window.Meta.Text("Hold a credit card to the middle of the screen. Use the Up/Down arrow keys to match the box to the size of the card as closely as possible.", {fill:"white", size:Small?25:36});
		INST.Top = Small?-275:-430;
		INST.Left = 0;
		INST.Right = 0;

		const BW = Small?135:150;
		const BH = Small?35:50;
		const BR = Small?74:100;

		const BUT = window.Meta.Text("Done", {fill:"white", size:Small?28:40});
		BUT.onclick = function(){window.Meta.Return("end")};
		BUT.Bot = Small?320:475;
		BUT.Left = Small?35:60;

		const TEXT = window.Meta.Text("", {fill:"white", size:Small?28:40});
		TEXT.Bot = (Small?340:500) - BR/4;
		TEXT.Left = 0;
		TEXT.Right = 0;
		TEXT.ClickThrough = true;

		function DrawCard(){
			if (window.Cali.ScreenSize == 0) window.Cali.ScreenSize = 20;

			const CardWith = window.MetaVision.CmToPix(8.56);
			const CardHeight = window.MetaVision.CmToPix(5.398);
			CARD.Width = CardWith;
			CARD.Height = CardHeight;
			CARD.Top = -CardHeight/2;
			CARD.Left = -CardWith/2;
			window.Meta.Redraw();
			TEXT.Text = window.Cali.ScreenSize+"in";
		}
		DrawCard();

		const SS_Up = ()=>{
			window.Cali.ScreenSize++;
			DrawCard();
		};

		const SS_Down = ()=>{
			window.Cali.ScreenSize = Math.max(window.Cali.ScreenSize-1, 7);
			DrawCard();
		};

		window.Meta.KeyDown("Left", SS_Down);
		window.Meta.KeyDown("Right", SS_Up);
		window.Meta.KeyDown("Up", SS_Up);
		window.Meta.KeyDown("Down", SS_Down);

		window.Meta.Redraw();
	},
};

const Diamonds_FG = window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {
	LegacyEngine :  window.Meta.Combine(RDS, VT2, AX, Tracker, TrackerS, Reakt, Kreators, Shuffle4, Spritors, {
		Start : function(meta){
			meta.backgroundColor = 0x000000;
			this.Time = window.Meta.get("Time");
			this.VergenceType = window.Meta.get("VergenceType");
			this.SeparationRateD = window.Meta.get("SeparationRateD");
			this.SeparationRateC = window.Meta.get("SeparationRateC");
			this.SeparationRateS = window.Meta.get("SeparationRateS");
			this.SeparationRateI = window.Meta.get("SeparationRateI");
			this.Acuity = window.Meta.get("Acuity");
			this.PixelSize = window.Meta.get("PixelSize");
			this.StereoAcuity = window.Meta.get("StereoAcuity");


			window.Meta.get("SetScore")(0);
			window.Meta.get("StartTimer")(this.Time);

			meta.PixiApp.stage.addChild(this.RDS);
			this.RDS.PixelSize = this.PixelSize;
			this.RDS.Depth =  window.MetaVision.CmToPix(window.MetaVision.ArcToCm(this.StereoAcuity));

			switch(this.VergenceType){
			case "Convergence":
				this.TrackerS.mode = "C";
				this.TrackerS.sepAmnt = [this.SeparationRateC];
				break;
			case "Divergence":
				this.TrackerS.mode = "D";
				this.TrackerS.sepAmnt = [this.SeparationRateD];
				break;
			case "Jump":
				this.TrackerS.mode = "J";
				this.TrackerS.sepAmnt = [this.SeparationRateC, this.SeparationRateD];
				break;
			case "Supra":
				this.TrackerS.mode = "BU";
				this.TrackerS.sepAmnt = [this.SeparationRateS];
				break;
			case "Infra":
				this.TrackerS.mode = "BD";
				this.TrackerS.sepAmnt = [this.SeparationRateI];
				break;
			}

			this.ScoreMult = this._ScoreMult ? this._ScoreMult : 1;

			this.Diamond = meta.Object();
			this.Diamond.Kreate = (self)=>{
				const dim = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(window.MetaVision.VaToArc(this.Acuity)));
				self.beginFill(0xff00ff).drawRect(-dim/2,-dim/2,dim, dim);
				self.rotation = Math.PI/4;
				self.x = this.Stage.midX;
				self.y = this.Stage.midY;

				switch(self.randex){
				case 0:
					self.y -= this.RDS.RDIM/2 - dim;
					break;
				case 1:
					self.x += this.RDS.RDIM/2 - dim;
					break;
				case 2:
					self.y += this.RDS.RDIM/2 - dim;
					break;
				case 3:
					self.x -= this.RDS.RDIM/2 - dim;
					break;
				}
			}
			this.Diamond.randex = 0;
			this.AddKreator(this.Diamond);
			this.RDS.addChild(this.Diamond.S);

			const StdSounds = window.Meta.get("StdSounds")();
			this.Shuffle4.Shuffle = ()=>{
				this.RDS.ReSeed();
				const randex = window.MetaRandom.Compass();
				this.Diamond.S.randex = randex;
				this.Diamond.Kreate(this.Diamond.S);
				return randex;
			};
			this.Shuffle4.Correct = ()=>{
					window.Meta.get("UnpauseTimer")();
				this.ScoreUp(100 * this.ScoreMult);
				//this.RDS.Separation = [5, 0];
				this.ScoreUpL(100 * this.ScoreMult);
				this.ScoreUpR(100 * this.ScoreMult);
				window.Meta.get("SetScore")(this.Score);
				this.AX.Acc(this.Reakt_Mark());
				this.Tracker.Correct();
				this.TrackerS.Correct();
				StdSounds.Hit();
				this.RDS.Separation = this.TrackerS.val();
			
			};
			this.Shuffle4.Wrong = ()=>{
				window.Meta.get("UnpauseTimer")();
				this.ScoreDown(50 * this.ScoreMult);
				this.ScoreDownL(50 * this.ScoreMult);
				this.ScoreDownR(50 * this.ScoreMult);
				window.Meta.get("SetScore")(this.Score);
				this.AX.Acc(this.Reakt_Mark());
				this.Tracker.Wrong();
				this.TrackerS.Wrong();
				StdSounds.Miss();
				this.RDS.Separation = this.TrackerS.val();
			
		
			};

			window.Meta.KeyDown("Accept", ()=>{
				this.TrackerS.Mercy();
				this.RDS.Separation = this.TrackerS.val();
			});
		},
		Resize : function(meta){
			this.RDS.RDIM = meta.Screen.height*0.7;
			this.Shuffle4.Init();
		},
		KountdownEnd : function(meta){
			if(this.Score > 0) {
				const type = {
					"Divergence": "Relax",
					"Convergence": "Flex",
					"Jump": "Stamina"
				}
	
				const scoreData = {
					score: this.Score,
					hits: this.Tracker.val(),
					avg_hit_time: this.AX.val() || 0,
					break: this.TrackerS.mode==="J" ? [this.TrackerS.best[0], this.TrackerS.best[1]] : this.TrackerS.best[0],
					recovery: this.TrackerS.mode==="J" ? [this.TrackerS.worst[0], this.TrackerS.worst[1]] : this.TrackerS.worst[0],
				}
	
				createUserPlayedGame(
					'Vision Stamina',
					type[this.VergenceType],
					'Diamonds',
					scoreData
				)
				
				updateUserDailyActivity('vision stamina', this.VergenceType)
	
				window.localStorage.setItem('finishScreen', 'true');
	
				setTimeout(function() {
					window.location.reload();
				}, 1500);
			}

			window.Meta.Return({
				Score: this.Score,
				Hits: this.Tracker.val(),
				AvgHitTime : this.AX.val(),
				Break : this.TrackerS.mode=="J" ? [this.TrackerS.best[0], this.TrackerS.best[1]] : this.TrackerS.best[0],
				Recovery : this.TrackerS.mode=="J" ? [this.TrackerS.worst[0], this.TrackerS.worst[1]] : this.TrackerS.worst[0],
			}); 
		},
	})
});

const TrackingBaseLR = window.Meta.Combine(VT2, AXLR, TrackerLR, Reakt, Shuffle4, VectorUtils, Raincheck, {
	Load : [
		["Arrow", Arrow],
	],
	Start : function(meta){
		meta.backgroundColor = 0x000000;
		this.WidthSave = 0;
		this.HeightSave = 0;

		this.Time = window.Meta.get("Time");
		this.MM = window.Meta.get("SizeMM");
		this.KPH = window.Meta.get("SpeedPerHour");
		this.Orientation = window.Meta.get("Orientation");
		if (!this.Orientation) this.Orientation = window.Meta.get("OrientationSaccade");
		this.SaccadeType = window.Meta.get("SaccadeType");

		window.Meta.get("SetScore")(0);
		window.Meta.get("StartTimer")(this.Time);
		this.MainArrow = {};
		this.InitStimulus(meta);
		meta.backgroundColor = 0x000000;
		var mainTint;

		if (this.DoPeripheral){
			this.CenterCircle = new window.PIXI.Graphics().lineStyle(4, mainTint).drawCircle(0,0,300);
			meta.PixiApp.stage.addChild(this.CenterCircle);

			this.CenterFix = new window.PIXI.Text("#",{fontFamily : 'Arial', fontSize: 34, fill : mainTint, align : 'center', fontWeight: 'bold'});
			this.CenterCircle.addChild(this.CenterFix);
		}

		this.MainArrow.ShufflePosition = ()=>{
			this.MainArrow.S.x = window.MetaRandom.Range(this.Stage.left + this.MainArrow.S.width/2, this.Stage.right - this.MainArrow.S.width/2);
			this.MainArrow.S.y = window.MetaRandom.Range(this.Stage.top + this.MainArrow.S.height/2, this.Stage.bot - this.MainArrow.S.height/2);

			if (this.Orientation == "H"){
				this.MainArrow.S.y = this.Stage.midY;
			}else if (this.Orientation == "V"){
				this.MainArrow.S.x = this.Stage.midX;
			}else if (this.Orientation == "CC"){
				this.MainArrow.cX = this.MainArrow.S.x;
				this.MainArrow.cY = this.MainArrow.S.y;
			}

			if (this.DoPeripheral){
				if (this.Vector.Distance([this.MainArrow.S.x, this.MainArrow.S.y], [this.CenterCircle.x, this.CenterCircle.y]) < this.CenterCircle.width/2 + this.MainArrow.S.width/2){
					const Vec = this.Vector.Direction([this.MainArrow.S.x, this.MainArrow.S.y], [this.CenterCircle.x, this.CenterCircle.y]);
					Vec[0] *= this.CenterCircle.width/2 + this.MainArrow.S.width/2;
					Vec[1] *= this.CenterCircle.width/2 + this.MainArrow.S.width/2;
					this.MainArrow.S.x = this.CenterCircle.x + Vec[0];
					this.MainArrow.S.y = this.CenterCircle.y + Vec[1];
				};
			}

			this.MainArrow.Phase = window.MetaRandom.Range(0, 2*Math.PI);
		}

		this.MainArrow.ShuffleRotation = ()=>{
			const randex = window.MetaRandom.Compass();
			this.MainArrow.S.rotation = Math.PI/2 * randex;
			return randex;
		}

		this.MainArrow.ShuffleVector = ()=>{
			if (this.Orientation == "R"){
				this.MainArrow.Mode = "R";
				this.MainArrow.Phase = 0;
				this.MainArrow.Shift = window.MetaRandom.Dex(2)*2 - 1;
				this.MainArrow.Vec = [Math.sin(0), Math.cos(0)];
			}else if (this.Orientation == "C"){
				this.MainArrow.Mode = "C";
				this.MainArrow.Shift = window.MetaRandom.Dex(2)*2 - 1;
			}else if (this.Orientation == "CC"){
				this.MainArrow.Mode = "CC";
				this.MainArrow.cR = window.MetaRandom.Range(30, 45);
				this.MainArrow.Shift = window.MetaRandom.Dex(2)*2 - 1;
			}else if (this.Orientation == "Fig8"){
				this.MainArrow.Mode = "Fig8";
				this.MainArrow.Shift = MetaRandom.Dex(2)*2 - 1;
			}else{
				this.MainArrow.Mode = "V";

				//~ if (this.Orientation == "4"){
					//~ if (this.MainArrow.S.x == this.Stage.midX){
						//~ this.MainArrow.Vec = MetaRandom.Vector(MetaVision.CmToPix(this.KPH), "V");
					//~ }else{
						//~ this.MainArrow.Vec = MetaRandom.Vector(MetaVision.CmToPix(this.KPH), "H");
					//~ }
				//~ }else{
					this.MainArrow.Vec = window.MetaRandom.Vector(window.MetaVision.CmToPix(this.KPH), this.Orientation);
				//~ }
			}
		}

		this.MainArrow.VecMove = (delta)=>{
			if (this.MainArrow.Mode == "V"){
				// this.MainArrow.S.x += this.MainArrow.Vec[0] * delta/60, 1;
				// this.MainArrow.S.y += this.MainArrow.Vec[1] * delta/60, 1;

				this.MainArrow.S.x += this.MainArrow.Vec[0] * delta/60;
				this.MainArrow.S.y += this.MainArrow.Vec[1] * delta/60;

				if (this.MainArrow.S.x < this.Stage.left + this.MainArrow.S.width/2){
					this.MainArrow.Vec[0] = Math.abs(this.MainArrow.Vec[0]);
				}else if (this.MainArrow.S.x > this.Stage.right - this.MainArrow.S.width/2){
					this.MainArrow.Vec[0] = -Math.abs(this.MainArrow.Vec[0]);
				}

				if (this.MainArrow.S.y < this.Stage.top + this.MainArrow.S.height/2){
					this.MainArrow.Vec[1] = Math.abs(this.MainArrow.Vec[1]);
				}else if (this.MainArrow.S.y > this.Stage.bot - this.MainArrow.S.height/2){
					this.MainArrow.Vec[1] = -Math.abs(this.MainArrow.Vec[1]);
				}
			}else if (this.MainArrow.Mode == "R"){
				// this.MainArrow.S.x += this.MainArrow.Vec[0] * delta/60, 1;
				// this.MainArrow.S.y += this.MainArrow.Vec[1] * delta/60, 1;
				this.MainArrow.S.x += this.MainArrow.Vec[0] * delta/60;
				this.MainArrow.S.y += this.MainArrow.Vec[1] * delta/60;

				const spd = window.MetaVision.CmToPix(this.KPH);
				if (Math.abs(this.MainArrow.Phase) < 6){
					this.MainArrow.Phase = Math.min((this.MainArrow.Phase + (this.MainArrow.Shift*delta/20)), 180);
					this.MainArrow.Vec = [Math.sin(this.MainArrow.Phase) * spd, Math.cos(this.MainArrow.Phase) * spd];
				}

				if (this.MainArrow.S.x < this.Stage.left + this.MainArrow.S.width/2){
					this.MainArrow.S.x = this.Stage.left + this.MainArrow.S.width/2;
					this.MainArrow.Phase = Math.PI / 2;
					//this.MainArrow.Shift *= -1;
				}else if (this.MainArrow.S.x > this.Stage.right - this.MainArrow.S.width/2){
					this.MainArrow.S.x = this.Stage.right - this.MainArrow.S.width/2;
					this.MainArrow.Phase = 3 * Math.PI / 2;
					//this.MainArrow.Shift *= -1;
				}

				if (this.MainArrow.S.y < this.Stage.top + this.MainArrow.S.height/2){
					this.MainArrow.S.y = this.Stage.top + this.MainArrow.S.height/2;
					this.MainArrow.Phase = 0;
					this.MainArrow.Shift *= -1;
				}else if (this.MainArrow.S.y > this.Stage.bot - this.MainArrow.S.height/2){
					this.MainArrow.S.y = this.Stage.bot - this.MainArrow.S.height/2;
					this.MainArrow.Phase = Math.PI;
					this.MainArrow.Shift *= -1;
				}
			}else if (this.MainArrow.Mode == "C"){
				const R = this.Stage.bot - this.Stage.midY - this.MainArrow.S.height/2;
				this.MainArrow.S.x = this.Stage.midX + Math.cos(this.MainArrow.Phase) * R;
				this.MainArrow.S.y = this.Stage.midY + Math.sin(this.MainArrow.Phase) * R;

				const spd = window.MetaVision.CmToPix(this.KPH);
				const C = 2*Math.PI*R;
				this.MainArrow.Phase += this.MainArrow.Shift * delta/60 * 2*Math.PI * (spd/C);
			}else if (this.MainArrow.Mode == "CC"){
				this.MainArrow.S.x = this.MainArrow.cX + Math.cos(this.MainArrow.Phase) * this.MainArrow.cR;
				this.MainArrow.S.y = this.MainArrow.cY + Math.sin(this.MainArrow.Phase) * this.MainArrow.cR;

				const spd = window.MetaVision.CmToPix(this.KPH);
				const C = 2*Math.PI*this.MainArrow.cR;
				this.MainArrow.Phase += this.MainArrow.Shift * delta/60 * 2*Math.PI * (spd/C);
			}else if (this.MainArrow.Mode == "Fig8"){
				const Rx = this.Stage.right - this.Stage.midX - this.MainArrow.S.width/2;
				const Ry = this.Stage.bot - this.Stage.midY - this.MainArrow.S.height/2;
				this.MainArrow.S.x = this.Stage.midX + Math.cos(this.MainArrow.Phase) * Rx;
				this.MainArrow.S.y = this.Stage.midY + Math.sin(this.MainArrow.Phase*2) * Ry;

				const spd = window.MetaVision.CmToPix(this.KPH);
				this.MainArrow.Phase += this.MainArrow.Shift * delta/60 * 2*Math.PI * (spd/meta.PixiApp.screen.width);
			}

			if (this.DoPeripheral){
				if (this.Vector.Distance([this.MainArrow.S.x, this.MainArrow.S.y], [this.CenterCircle.x, this.CenterCircle.y]) < this.CenterCircle.width/2 + this.MainArrow.S.width/2){
					this.MainArrow.Vec = this.Vector.Direction([this.MainArrow.S.x, this.MainArrow.S.y], [this.CenterCircle.x, this.CenterCircle.y]);
					const spd = window.MetaVision.CmToPix(this.KPH);
					this.MainArrow.Vec[0] *= spd;
					this.MainArrow.Vec[1] *= spd;
				};
			}
		}

		const StdSounds = window.Meta.get("StdSounds")();
		this.Shuffle4.Shuffle = ()=>{
			this.ShuffleLook(meta);
			// this.CurRandSide = window.MetaRandom.Dex(2);
			if(this.CurRandSide) {
				this.CurRandSide = 0;
			} else {
				this.CurRandSide = 1;
			}
			this.MainArrow.S.tint = this.CurRandSide==0?window.Cali.LeftColor:window.Cali.RightColor; // Aarrow Color
			return this.ShuffleStimulus(meta);
		};
		this.Shuffle4.Correct = ()=>{
			window.Meta.get("UnpauseTimer")();
			window.Meta.get("SetScore")(this.Score);
			
			this.ScoreUp(100);
			if (this.CurRandSide==0){
				this.AXL.Acc(this.Reakt_Mark());
				this.TrackerL.Correct();
				this.ScoreUpL(100);
			}else{
				this.AXR.Acc(this.Reakt_Mark());
				this.TrackerR.Correct();
				this.ScoreUpR(100);
			}
			StdSounds.Hit();
		};
		this.Shuffle4.Wrong = ()=>{
			window.Meta.get("UnpauseTimer")();
			window.Meta.get("SetScore")(this.Score);
			
			this.ScoreDown(50);
			if (this.CurRandSide==0){
				this.AXL.Acc(this.Reakt_Mark());
				this.TrackerL.Wrong();
				this.ScoreDownL(50);
			}else{
				this.AXR.Acc(this.Reakt_Mark());
				this.TrackerR.Wrong();
				this.ScoreDownR(50);
			}
			StdSounds.Miss();
		};

		if (this.DoPeripheral){
			this.fixtimer = 0;
			this.BadHits = 0;
			this.xMisses = 0;
			this.xShows = 0;

			window.Meta.KeyDown("Accept", ()=>{
				if(this.CenterFix.text == "X"){
					this.xMisses--;
				}else{
					this.BadHits++;
				}
				this.CycleFixation();
			});
		}
	},
	CycleFixation : function(meta){
		if (this.CenterFix.text == "X"){
			this.xMisses++;
			this.xShows++;
		}
		if (Randex(10) == 0 && this.CenterFix.text != "X"){
			this.CenterFix.text = "X";
		}else{
			const letters = "ABCDEFGHIJKLMNOPQRSTUVWYZ";
			const randex = window.MetaRandom.Dex(25);
			const next = letters[randex];
			if(next == this.CenterFix.text){
				this.CenterFix.text = letters[(randex+1)%4];
			}else{
				this.CenterFix.text = next;
			}
		}
		this.fixtimer = 1;
	},
	Tick : function(meta, delta){
		this.StimulusMotion(meta, delta);

		if (this.DoPeripheral){
			this.fixtimer -= delta/60;
			if (this.fixtimer <= 0){
				this.CycleFixation();
			}
		}
	},
	KountdownEnd : function(meta){
		if(this.ScoreL > 0 && this.ScoreR > 0) {
			if (this.DoPeripheral){
				window.Meta.Return({
					Score: this.Score,
					Hits: this.Tracker.val(),
					AvgHitTime : this.AX.val(),
					FalsePositives : this.BadHits,
					FixationLoss : [this.xMisses, this.xShows],
				});
			}else{
				let gameName;
				if(this.KPH) {
					gameName = 'Chase'
					updateUserDailyActivity('eye syncing', 'chase')
				} else {
					gameName = 'Reflex'
					updateUserDailyActivity('eye syncing', 'reflex')
				}
	
				const scoreData = {
					score_l: this.ScoreL,
					score_r: this.ScoreR,
					hits_l: this.TrackerL.val(),
					avg_hit_time_l : this.AXL.val() || 0,
					hits_r: this.TrackerR.val(),
					avg_hit_time_r : this.AXR.val() || 0,
				}
	
				createUserPlayedGame(
					'Eye Syncing',
					'',
					gameName,
					scoreData
				)
	
				window.localStorage.setItem('finishScreen', 'true');
	
				setTimeout(function() {
					window.location.reload();
				}, 1500);
			}
		}
			
		window.Meta.Return({
			ScoreL: this.ScoreL,
			ScoreR: this.ScoreR,
			HitsL: this.TrackerL.val(),
			AvgHitTimeL : this.AXL.val(),
			HitsR: this.TrackerR.val(),
			AvgHitTimeR : this.AXR.val(),
		});
	},
	Resize : function(meta){
		if(this.CenterCircle){
			this.CenterCircle.x = this.Stage.midX;
			this.CenterCircle.y = this.Stage.midY;
		}
		this.MainArrow.ShufflePosition();
		this.Shuffle4.Init();
		//~ if (Meta.get("TrackingType") == "Pursuit")
	}
});

const JumpTracking = window.Meta.Combine(TrackingBaseLR, {
	ShuffleStimulus : function(meta){
		this.MainArrow.ShufflePosition();
		this.MainArrow.ShuffleVector();
		return this.MainArrow.ShuffleRotation();
	},
	StimulusMotion : function(meta, delta){
		this.MainArrow.VecMove(0);
	},
});

const SmoothTracking =  window.Meta.Combine(TrackingBaseLR, {
	Start : function(meta){
		this.MainArrow.ShufflePosition();
		this.MainArrow.ShuffleVector();

		const DoShuffle = ()=>{
			this.MainArrow.ShuffleVector();
			window.Meta.DoAfter(2500, DoShuffle);
		}

		if(this.SaccadeType == "Reflexive" || this.Orientation == "4" || this.Orientation == "8"){
			DoShuffle();
		}
	},
	ShuffleStimulus : function(meta){
		return this.MainArrow.ShuffleRotation();
	},
	StimulusMotion : function(meta, delta){
		this.MainArrow.VecMove(delta);
	},
});

const ComboTracking = window.Meta.Combine(TrackingBaseLR, {
	ShuffleStimulus : function(meta){
		this.MainArrow.ShufflePosition();
		this.MainArrow.ShuffleVector();
		return this.MainArrow.ShuffleRotation();
	},
	StimulusMotion : function(meta, delta){
		this.MainArrow.VecMove(delta);
	},
});

const StandardArrow = {
	ShuffleLook : function(meta){
		//~ this.MainArrow.S.tint = 0xffffff;
	},
	InitStimulus: function(meta){
		this.MainArrow.S = new window.PIXI.Sprite.from(Arrow);
		this.MainArrow.S.anchor.set(0.5);
		meta.PixiApp.stage.addChild(this.MainArrow.S);
	},
	Resize : function(meta){
		const dim = window.MetaVision.CmToPix(this.MM/10);
		this.MainArrow.S.width = dim;
		this.MainArrow.S.height = dim;
	},
};

const PhoneAccomm_FG = window.Meta.Engine(VT_Base, Trackerz, TumblingEKreator, {
	Start : function(){
		const inst = window.Meta.get("GameInst");
		const KreateE = window.Meta.get("KreateE");
		const Tracker = window.Meta.get("NewTracker")();
		const SetScore = window.Meta.get("SetScore");
		SetScore(Tracker.Str);
		window.Meta.set("Tracker", Tracker);
		const AX = window.Meta.get("NewAX")();
		const ReaktMark = window.Meta.get("ReaktMark");
		window.Meta.set("AX", AX);

		const DisplayImage = KreateE(false);
		DisplayImage.Size = 720;
		DisplayImage.Top = -360;
		DisplayImage.Left = -360;
		DisplayImage.Fill = "white";

		const Shuffle = ()=>{
			DisplayImage.Rotation = (DisplayImage.Rotation + (window.MetaRandom.Dex(3)+1) * 90) % 360;
			inst.Write("rot", 1);
		}

		inst.On("rot", (val)=>{
			if (val != null && val != 1){
				if (val == DisplayImage.Rotation){
					Tracker.Hit();
				}else{
					Tracker.Miss();
				}
				SetScore(Tracker.Str);
				AX.Acc(ReaktMark());
				Shuffle();
			}
		})

		Shuffle();
		window.Meta.get("StartTimer")(window.Meta.get("Time"));
	},
	EndGame : function(){
		const hits = window.Meta.get("Tracker").Val;
		window.Meta.Return({
			Hits: hits,
			AvgHitTime: Meta.get("AX").Val,
			Cycles: hits[0],
		});
	},
});

// const AccommMedly_FG = window.Meta.Engine(VT_Base, Trackerz, TumblingEKreator, {
// 	Start : function(){
// 		const inst = window.Meta.get("GameInst");  // New
// 		const KreateE = window.Meta.get("KreateE");
// 		window.Meta.Stage.Background("#000000");
// 		const NewTracker = window.Meta.get("NewTracker");
// 		const SetScore = window.Meta.get("SetScore");
// 		SetScore("");
// 		const NewAX = window.Meta.get("NewAX");
// 		const ReaktMark = window.Meta.get("ReaktMark");
// 		window.Meta.set("AX", AX);

// 		window.Meta.set("ResLeft", {
// 			Tracker: NewTracker(),
// 			AX: NewAX(),
// 		});
// 		window.Meta.set("ResRight", {
// 			Tracker: NewTracker(),
// 			AX: NewAX(),
// 		});
// 		window.Meta.set("ResBoth", {
// 			Tracker: NewTracker(),
// 			AX: NewAX(),
// 		});

// 		const DisplayImage = KreateE(false);
// 		DisplayImage.Size = 100;
// 		DisplayImage.Top = -50;
// 		DisplayImage.Left = -50;
// 		DisplayImage.Fill = "black";

// 		// const Shuffle = ()=>{
// 		// 	DisplayImage.Rotation = (DisplayImage.Rotation + (MetaRandom.Dex(3)+1) * 90) % 360;
// 		// 	inst.Write("rot", 1);
// 		// }

// 		const MainTxt = window.Meta.Text("", {fill:"#ffffff", size:64});
// 		MainTxt.Width = 500;
// 		MainTxt.Left = -250;
// 		MainTxt.Height = 100;
// 		MainTxt.Top = -50;

// 		const StdSounds = window.Meta.get("StdSounds")();

// 		var state = "";
// 		var answer = "";
// 		function Shuffle(){
// 			const L = window.MetaRandom.Dex(9) + 1;
// 			const R = window.MetaRandom.Dex(9) + 1;
// 			MainTxt.Text = L + "+" + R;
// 			const ROT = (DisplayImage.Rotation + (window.MetaRandom.Dex(3)+1) * 90) % 360;
// 			DisplayImage.Rotation = ROT;
// 			const CurRand = window.MetaRandom.Dex(3);
// 			window.Meta.set("CurRand",CurRand);
// 			if (CurRand == 0){
// 				MainTxt.Fill = window.Cali.LeftColor;
// 				DisplayImage.Fill = window.Cali.LeftColor;
// 				inst.Write("Color", window.Cali.LeftColor); // New
// 			}else if (CurRand == 1){
// 				MainTxt.Fill = window.Cali.RightColor;
// 				DisplayImage.Fill = window.Cali.RightColor;
// 				inst.Write("Color", window.Cali.RightColor); // New
// 			}else{
// 				MainTxt.Fill = "white";
// 				DisplayImage.Fill = "white";
// 				inst.Write("Color", "white"); // New
// 			}

// 			if(window.MetaRandom.Dex(5)==0){
// 				DisplayImage.Fill = "black";
// 				inst.Write("Solution", L + R); // New
// 			}else{
// 				MainTxt.Text = "";
// 				inst.Write("Solution", ROT); // New
// 			}
// 		}

// 		function Correct(){
// 			StdSounds.Hit();
// 			const reakt = ReaktMark();
// 			SetScore(Math.round(reakt*1000)/1000);
// 			var Res;
// 			switch(window.Meta.get("CurRand")){
// 			case 0:
// 				Res = window.Meta.get("ResLeft");
// 				break;
// 			case 1:
// 				Res = window.Meta.get("ResRight");
// 				break;
// 			case 2:
// 				Res = window.Meta.get("ResBoth");
// 				break;
// 			}
// 			Res.Tracker.Hit();
// 			Res.AX.Acc(reakt);
// 			Shuffle();
// 		}

// 		function Wrong(){
// 			StdSounds.Miss();
// 			const reakt = ReaktMark();
// 			SetScore(Math.round(reakt*1000)/1000);
// 			var Res;
// 			switch(window.Meta.get("CurRand")){
// 			case 0:
// 				Res = window.Meta.get("ResLeft");
// 				break;
// 			case 1:
// 				Res = window.Meta.get("ResRight");
// 				break;
// 			case 2:
// 				Res = window.Meta.get("ResBoth");
// 				break;
// 			}
// 			Res.Tracker.Miss();
// 			Res.AX.Acc(reakt);
// 			Shuffle();
// 		}

// 		Shuffle();
// 		window.Meta.get("StartTimer")(window.Meta.get("Time"));

// 		inst.On("a", (val)=>{ // New
// 			if (val){ // New
// 				inst.Write("a", null); // New
// 				if (val == 1337){ // New
// 					Correct(); // New
// 				}else{ // New
// 					Wrong(); // New
// 				} // New
// 			} // New
// 		}) // New

// 		window.Meta.KeyDown("x", Wrong); // Remove
// 		window.Meta.KeyDown("c", Correct); // Remove
// 	},
// 	EndGame : async function(meta){
// 		const Left = window.Meta.get("ResLeft");
// 		const Right = window.Meta.get("ResRight");
// 		const Both = window.Meta.get("ResBoth");

// 		if(Left.AX.Val > 0 && Right.AX.Val > 0 && Both.AX.Val > 0) {
// 			const scoreData = {
// 				avg_hit_time_l: Left.AX.Val,
// 				hits_l: Left.Tracker.Val,
// 				avg_hit_time_r: Right.AX.Val,
// 				hits_r: Right.Tracker.Val,
// 				avg_hit_time_b: Both.AX.Val,
// 				hits_b: Both.Tracker.Val,
// 			}
	
// 			await createUserPlayedGame(
// 				'Focus Speed',
// 				'',
// 				'Astucia',
// 				scoreData
// 			)
	
// 			await updateUserDailyActivity('focus speed', '')
// 			window.localStorage.setItem('finishScreen', 'true');
			
// 			setTimeout(function() {
// 				console.log('reload');
// 				window.location.reload();
// 			}, 1500);
// 		}
		
// 		window.Meta.Return({
// 			AvgHitTimeL: Left.AX.Val,
// 			HitsL: Left.Tracker.Val,
// 			AvgHitTimeR: Right.AX.Val,
// 			HitsR: Right.Tracker.Val,
// 			AvgHitTimeB: Both.AX.Val,
// 			HitsB: Both.Tracker.Val,
// 		});
// 	},
// });

/**************************************************************/

const AccommTumbleE_FG = window.Meta.Engine(VT_Base, Trackerz, TumblingEKreator, {
	Start : function(){
		const inst = window.Meta.get("GameInst");  // New
		const KreateE = window.Meta.get("KreateE");
		console.log(KreateE);
		window.Meta.Stage.Background("#000000");
		const NewTracker = window.Meta.get("NewTracker");
		const SetScore = window.Meta.get("SetScore");
		SetScore("");
		const NewAX = window.Meta.get("NewAX");
		const ReaktMark = window.Meta.get("ReaktMark");
		window.Meta.set("AX", AX);
		window.Meta.set("CurRand",1);

		window.Meta.set("ResLeft", {
			Tracker: NewTracker(),
			AX: NewAX(),
		});
		window.Meta.set("ResRight", {
			Tracker: NewTracker(),
			AX: NewAX(),
		});
		window.Meta.set("ResBoth", {
			Tracker: NewTracker(),
			AX: NewAX(),
		});

		const DisplayImage = KreateE(false);
		DisplayImage.Size = 100;
		DisplayImage.Top = -50;
		DisplayImage.Left = -50;
		DisplayImage.Fill = "black";

		// const Shuffle = ()=>{
		// 	DisplayImage.Rotation = (DisplayImage.Rotation + (MetaRandom.Dex(3)+1) * 90) % 360;
		// 	inst.Write("rot", 1);
		// }

		const StdSounds = window.Meta.get("StdSounds")();

		var state = "";
		var answer = "";
		function Shuffle(){
			const ROT = (DisplayImage.Rotation + (window.MetaRandom.Dex(3)+1) * 90) % 360;
			DisplayImage.Rotation = ROT;
			const OldRand = window.Meta.get("CurRand");
			const CurRand = OldRand == 0 ? 1 : 0;
			window.Meta.set("CurRand",CurRand);
			if (CurRand == 0){
				DisplayImage.Fill = window.Cali.LeftColor;
				inst.Write("Color", window.Cali.LeftColor); // New
			}else if (CurRand == 1){
				DisplayImage.Fill = window.Cali.RightColor;
				inst.Write("Color", window.Cali.RightColor); // New
			}

			inst.Write("Solution", ROT); // New
		}

		function Correct(){
			window.Meta.get("UnpauseTimer")();
			StdSounds.Hit();
			const reakt = ReaktMark();
			SetScore(Math.round(reakt*1000)/1000);
			var Res;
			switch(window.Meta.get("CurRand")){
			case 0:
				Res = window.Meta.get("ResLeft");
				break;
			case 1:
				Res = window.Meta.get("ResRight");
				break;
			case 2:
				Res = window.Meta.get("ResBoth");
				break;
			}
			Res.Tracker.Hit();
			Res.AX.Acc(reakt);
			Shuffle();
		}

		function Wrong(){
			window.Meta.get("UnpauseTimer")();
			StdSounds.Miss();
			const reakt = ReaktMark();
			SetScore(Math.round(reakt*1000)/1000);
			var Res;
			switch(window.Meta.get("CurRand")){
			case 0:
				Res = window.Meta.get("ResLeft");
				break;
			case 1:
				Res = window.Meta.get("ResRight");
				break;
			case 2:
				Res = window.Meta.get("ResBoth");
				break;
			}
			Res.Tracker.Miss();
			Res.AX.Acc(reakt);
			Shuffle();
		}

		Shuffle();
		window.Meta.get("StartTimer")(window.Meta.get("Time"));

		inst.On("a", (val)=>{ // New
			if (val){ // New
				inst.Write("a", null); // New
				if (val == 1337){ // New
					Correct(); // New
				}else{ // New
					Wrong(); // New
				} // New
			} // New
		}) // New

		window.Meta.KeyDown("x", Wrong); // Remove
		window.Meta.KeyDown("c", Correct); // Remove
	},
	EndGame : async function(meta){
		const Left = window.Meta.get("ResLeft");
		const Right = window.Meta.get("ResRight");
		const Both = window.Meta.get("ResBoth");

		if(Left.AX.Val > 0 && Right.AX.Val > 0) {
			const scoreData = {
				avg_hit_time_l: Left.AX.Val,
				hits_l: Left.Tracker.Val,
				avg_hit_time_r: Right.AX.Val,
				hits_r: Right.Tracker.Val,
				avg_hit_time_b: Both.AX.Val,
				hits_b: Both.Tracker.Val,
			}
	
			await createUserPlayedGame(
				'Focus Speed',
				'Speed',
				'Symbols',
				scoreData
			)
	
			await updateUserDailyActivity('focus speed', '')
			window.localStorage.setItem('finishScreen', 'true');
			
			setTimeout(function() {
				console.log('reload');
				window.location.reload();
			}, 1500);
		}

		window.Meta.Return({
			AvgHitTimeL: Left.AX.Val,
			HitsL: Left.Tracker.Val,
			AvgHitTimeR: Right.AX.Val,
			HitsR: Right.Tracker.Val,
			AvgHitTimeB: Both.AX.Val, //Remove
			HitsB: Both.Tracker.Val, //Remove
		});
	},
});

const AccommMath_FG = window.Meta.Engine(VT_Base, Trackerz, {
	Start : function(){
		const inst = window.Meta.get("GameInst");  // New
		window.Meta.Stage.Background("#000000");
		const NewTracker = window.Meta.get("NewTracker");
		const SetScore = window.Meta.get("SetScore");
		SetScore("");
		const NewAX = window.Meta.get("NewAX");
		const ReaktMark = window.Meta.get("ReaktMark");
		window.Meta.set("AX", AX);
		window.Meta.set("CurRand",1);

		window.Meta.set("ResLeft", {
			Tracker: NewTracker(),
			AX: NewAX(),
		});
		window.Meta.set("ResRight", {
			Tracker: NewTracker(),
			AX: NewAX(),
		});
		window.Meta.set("ResBoth", {
			Tracker: NewTracker(),
			AX: NewAX(),
		});


		// const Shuffle = ()=>{
		// 	DisplayImage.Rotation = (DisplayImage.Rotation + (MetaRandom.Dex(3)+1) * 90) % 360;
		// 	inst.Write("rot", 1);
		// }

		const MainTxt = window.Meta.Text("", {fill:"#ffffff", size:64});
		MainTxt.Width = 500;
		MainTxt.Left = -250;
		MainTxt.Height = 100;
		MainTxt.Top = -50;

		const StdSounds = window.Meta.get("StdSounds")();

		var state = "";
		var answer = "";
		function Shuffle(){
			const L = window.MetaRandom.Dex(9) + 1;
			const R = window.MetaRandom.Dex(9) + 1;
			MainTxt.Text = L + "+" + R;
			const OldRand = window.Meta.get("CurRand");
			const CurRand = OldRand == 0 ? 1 : 0;
			window.Meta.set("CurRand",CurRand);
			if (CurRand == 0){
				MainTxt.Fill = window.Cali.LeftColor;
				// DisplayImage.Fill = window.Cali.LeftColor;
				inst.Write("Color", window.Cali.LeftColor); // New
			}else if (CurRand == 1){
				MainTxt.Fill = window.Cali.RightColor;
				// DisplayImage.Fill = window.Cali.RightColor;
				inst.Write("Color", window.Cali.RightColor); // New
			}
			inst.Write("Solution", L + R); // New
		}

		function Correct(){
			window.Meta.get("UnpauseTimer")();
			StdSounds.Hit();
			const reakt = ReaktMark();
			// SetScore(Math.round(reakt*1000)/1000);
			SetScore(Math.round(reakt*1000)/1000 + ' s');
			var Res;
			switch(window.Meta.get("CurRand")){
			case 0:
				Res = window.Meta.get("ResLeft");
				break;
			case 1:
				Res = window.Meta.get("ResRight");
				break;
			case 2:
				Res = window.Meta.get("ResBoth");
				break;
			}
			Res.Tracker.Hit();
			Res.AX.Acc(reakt);
			Shuffle();
		}

		function Wrong(){
			window.Meta.get("UnpauseTimer")();
			StdSounds.Miss();
			const reakt = ReaktMark();
			SetScore(Math.round(reakt*1000)/1000 + ' s');
			var Res;
			switch(window.Meta.get("CurRand")){
			case 0:
				Res = window.Meta.get("ResLeft");
				break;
			case 1:
				Res = window.Meta.get("ResRight");
				break;
			case 2:
				Res = window.Meta.get("ResBoth");
				break;
			}
			Res.Tracker.Miss();
			Res.AX.Acc(reakt);
			Shuffle();
		}

		Shuffle();
		window.Meta.get("StartTimer")(window.Meta.get("Time"));

		inst.On("a", (val)=>{ // New
			if (val){ // New
				inst.Write("a", null); // New
				if (val == 1337){ // New
					Correct(); // New
				}else{ // New
					Wrong(); // New
				} // New
			} // New
		}) // New

		window.Meta.KeyDown("x", Wrong); // Remove
		window.Meta.KeyDown("c", Correct); // Remove
	},
	EndGame : async function(meta){
		const Left = window.Meta.get("ResLeft");
		const Right = window.Meta.get("ResRight");
		const Both = window.Meta.get("ResBoth");

		if(Left.AX.Val > 0 && Right.AX.Val > 0) {
			const scoreData = {
				avg_hit_time_l: Left.AX.Val,
				hits_l: Left.Tracker.Val,
				avg_hit_time_r: Right.AX.Val,
				hits_r: Right.Tracker.Val,
				avg_hit_time_b: Both.AX.Val,
				hits_b: Both.Tracker.Val,
			}

			console.log('AAA');
	
			await createUserPlayedGame(
				'Focus Speed',
				'Speed',
				'Math',
				scoreData
			)
	
			await updateUserDailyActivity('focus speed', '')
			window.localStorage.setItem('finishScreen', 'true');
			
			setTimeout(function() {
				console.log('reload');
				window.location.reload();
			}, 1500);
		}
		window.Meta.Return({
			AvgHitTimeL: Left.AX.Val,
			HitsL: Left.Tracker.Val,
			AvgHitTimeR: Right.AX.Val,
			HitsR: Right.Tracker.Val,
			AvgHitTimeB: Both.AX.Val, //Remove
			HitsB: Both.Tracker.Val, //Remove
		});
	},
});


/**************************************************************/

export const RealtimeClient_Base = {
	Start : function(){
		const inst = window.Meta.get("GameInst");
		inst.On("", val=>{
			if (val == null){
				window.Meta.Return(true);
			}
		})
		//whatever you might need it for
	},
	NullResponse : function(){
		var Box = window.Meta.get("RTC_Box");
		if (!Box){
			const CM = window.Meta.get("ContrastMode");
			const line = CM == "WoB" ? "white" : "black";
			const fill = line == "white" ? "black" : "white";
			const newBox = window.Meta.Rect(700, 350, {fill, line, stroke:2});
			newBox.Left = -350;
			newBox.Top = -175;
			newBox.Hide();
			
			const BoxText = window.Meta.Text("", {fill:line, size:50});
			BoxText.Left = 0;
			BoxText.Right = 0;
			BoxText.Bot = -20;
			newBox.Txt = BoxText;
			BoxText.Hide();
			
			const BackBut = window.Meta.Button("Exit", window.Meta.Return, {fill, line, size:70});
			BackBut.Width = 200;
			BackBut.Height = 100;
			BackBut.Left = -100;
			BackBut.Top = 20;
			newBox.But = BackBut;
			BackBut.Hide();
			
			window.Meta.set("RTC_Box", newBox);
			Box = newBox;
		}
		
		if (window.Meta.get("RTC_Cert")){
			//~ Box.Show();
			//~ Box.Txt.Show();
			//~ Box.Txt.Text = "Error";
			//~ Box.But.Show();
		}else{
			Box.Show();
			Box.Txt.Show();
			Box.Txt.Text = "Get Ready!";
		}
	},
	ClearNullBox : function(){
		const Box = window.Meta.get("RTC_Box");
		if (Box){
			Box.Hide();
			Box.Txt.Hide();
			Box.But.Hide();
		}
		window.Meta.set("RTC_Cert", true); // While not ontological, it's empircal
	}
}

export const AccommMedley_Client = window.Meta.Engine(RealtimeClient_Base, TumblingEKreator, {
	Start : function(){
		const inst = window.Meta.get("GameInst");
		const KreateE = window.Meta.get("KreateE");
		window.Meta.Stage.Background("#000000");
		
		var state = "wait";

		
		inst.On("Color", (col)=>{
			if (col){
				Answer1.Fill =col;
				Answer2.Fill = col;
				Answer3.Fill = col;
				Answer4.Fill = col;
				DisplayImage1.Fill = col;
				DisplayImage2.Fill = col;
				DisplayImage3.Fill = col;
				DisplayImage4.Fill = col;
				inst.Write("Color", null);
			}
		});
		
		function ATap(){
			if (state != "go") return;
			
			inst.Write("a", this.Text == answer ? 1337 : 1);
			state = "sync";
			inst.Write("Solution", null);
		}
		
		window.MetaVision.PatientDistance = 40;
		const FontSize = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(window.MetaVision.VaToArc(20/200)));
		
		const BoxDim = Math.min(FontSize*8, 500);
		const HalfSpace = Math.round(BoxDim* 0.066666);
		
		const Box1 = window.Meta.Rect(BoxDim, BoxDim, {line:"white", stroke:3});
		Box1.Left = -BoxDim - HalfSpace;
		Box1.Top = -BoxDim - HalfSpace;
		
		const Box2 = window.Meta.Rect(BoxDim, BoxDim, {line:"white", stroke:3});
		Box2.Left = HalfSpace;
		Box2.Top = -BoxDim - HalfSpace;
		
		const Box3 = window.Meta.Rect(BoxDim, BoxDim, {line:"white", stroke:3});
		Box3.Left = -BoxDim - HalfSpace;
		Box3.Top = HalfSpace;

		const Box4 = window.Meta.Rect(BoxDim, BoxDim, {line:"white", stroke:3});
		Box4.Left = HalfSpace;
		Box4.Top = HalfSpace;
		
		
		const Answer1 = window.Meta.Text("?", {color: "white", size:FontSize});
		Answer1.Width = BoxDim;
		Answer1.Left = -BoxDim - HalfSpace;
		Answer1.Height = BoxDim;
		Answer1.Top = -BoxDim - HalfSpace;
		Answer1.onclick = ATap;
		
		const Answer2 = window.Meta.Text("?", {color: "white", size:FontSize});
		Answer2.Width = BoxDim;
		Answer2.Left = HalfSpace;
		Answer2.Height = BoxDim;
		Answer2.Top = -BoxDim - HalfSpace;
		Answer2.onclick = ATap;
		
		const Answer3 = window.Meta.Text("?", {color: "white", size:FontSize});
		Answer3.Width = BoxDim;
		Answer3.Left = -BoxDim - HalfSpace;
		Answer3.Height = BoxDim;
		Answer3.Top = HalfSpace;
		Answer3.onclick = ATap;
		
		const Answer4 = window.Meta.Text("?", {color: "white", size:FontSize});
		Answer4.Width = BoxDim;
		Answer4.Left = HalfSpace;
		Answer4.Height = BoxDim;
		Answer4.Top = HalfSpace;
		Answer4.onclick = ATap;
		
		const MathAnswers = [Answer1, Answer2, Answer3, Answer4];
		
		const DisplayImage1 = KreateE(false);
		DisplayImage1.Size = FontSize;
		DisplayImage1.Left = -BoxDim/2 - HalfSpace - DisplayImage1.Width/2;
		DisplayImage1.Top = -BoxDim/2 - HalfSpace - DisplayImage1.Width/2;
		DisplayImage1.Fill = "white";
		
		const DisplayImage2 = KreateE(false);
		DisplayImage2.Size = FontSize;
		DisplayImage2.Left = BoxDim/2 + HalfSpace - DisplayImage1.Width/2;
		DisplayImage2.Top = -BoxDim/2 - HalfSpace - DisplayImage1.Width/2;
		DisplayImage2.Fill = "white";
		
		const DisplayImage3 = KreateE(false);
		DisplayImage3.Size = FontSize;
		DisplayImage3.Top = BoxDim/2 + HalfSpace - DisplayImage1.Width/2;
		DisplayImage3.Left = -BoxDim/2 - HalfSpace - DisplayImage1.Width/2;
		DisplayImage3.Fill = "white";
		
		const DisplayImage4 = KreateE(false);
		DisplayImage4.Size = FontSize;
		DisplayImage4.Top = BoxDim/2 + HalfSpace - DisplayImage1.Width/2;
		DisplayImage4.Left = BoxDim/2 + HalfSpace - DisplayImage1.Width/2;
		DisplayImage4.Fill = "white";
		
		function ImgClick(){
			
			if (state != "go") return;
			
			inst.Write("a", this.DI.Rotation == answer ? 1337 : 1);
			state = "sync";
			inst.Write("Solution", null);
		}
		Box1.onclick = ImgClick;
		Box1.DI = DisplayImage1;
		Box2.onclick = ImgClick;
		Box2.DI = DisplayImage2;
		Box3.onclick = ImgClick;
		Box3.DI = DisplayImage3;
		Box4.onclick = ImgClick;
		Box4.DI = DisplayImage4;

		DisplayImage1.Hide()
		DisplayImage2.Hide()
		DisplayImage3.Hide()
		DisplayImage4.Hide()
		const Images = [DisplayImage1, DisplayImage2, DisplayImage3, DisplayImage4];
		
		var answer = "";
		var Answers = ['', '', '', ''];
		
		inst.On("Solution", (sol)=>{
			if (sol != null){
				answer = sol;
				const rdex = [0, 90, 180, 270];
				if (rdex.includes(sol)){
					Images[0].Rotation = rdex.splice(window.MetaRandom.Dex(4), 1)[0];
					Images[1].Rotation = rdex.splice(window.MetaRandom.Dex(3), 1)[0];
					Images[2].Rotation = rdex.splice(window.MetaRandom.Dex(2), 1)[0];
					Images[3].Rotation = rdex[0];
					Answer1.Hide();
					Answer2.Hide();
					Answer3.Hide();
					Answer4.Hide();
					DisplayImage1.Show();
					DisplayImage2.Show();
					DisplayImage3.Show();
					DisplayImage4.Show();
				}else{
					const wrongs = [0, 0, 0];
					do{
						for (var s = 0; s < 3; s++){
							const dif = window.MetaRandom.Dex(5) + 1;
							wrongs[s] = answer + dif * (window.MetaRandom.Dex(2)==0?1:-1);
						}
					}while(wrongs[0] == answer || wrongs[1] == answer || wrongs[2] == answer || wrongs[0] == wrongs[1] || wrongs[0] == wrongs[2] || wrongs[1] == wrongs[2])
					
					const adex = window.MetaRandom.Dex(4);


					for (var s = 0; s < 4; s++){
						if (s == adex){
							MathAnswers[s].Text = answer;
						}else{
							MathAnswers[s].Text = wrongs.pop();
						}
					}
					Answer1.Show();
					Answer2.Show();
					Answer3.Show();
					Answer4.Show();
					DisplayImage1.Hide();
					DisplayImage2.Hide();
					DisplayImage3.Hide();
					DisplayImage4.Hide();
				}
				
				state = "go";
				window.Meta.get("ClearNullBox")();
				window.Meta.Redraw();
			}else{
				if (state == "go"){
					// Answer1.Text = "?";
					// Answer2.Text = "?";
					// Answer3.Text = "?";
					// Answer4.Text = "?";
				}
				state = "wait";
				window.Meta.get("NullResponse")();
			}
			window.Meta.Redraw();
		});
	},
});

const UfoDestroyer_FG = window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {
	LegacyEngine : window.Meta.Combine(RDS, VT2, AX, Tracker, TrackerS, Reakt, VectorUtils, {
		Load : [
			["Spaceship", "/img/Spaceship.png"],
			["TheBullet", "/img/Bullet.png"],
		],
		Start : function(meta){
			meta.backgroundColor = 0x000000;

			this.Time = window.Meta.get("Time");
			this.VergenceType = window.Meta.get("VergenceType");
			this.SeparationRateD = window.Meta.get("SeparationRateD");
			this.SeparationRateC = window.Meta.get("SeparationRateC");
			this.SeparationRateS = window.Meta.get("SeparationRateS");
			this.SeparationRateI = window.Meta.get("SeparationRateI");
			this.Acuity = window.Meta.get("Acuity");
			this.PixelSize = window.Meta.get("PixelSize");
			this.StereoAcuity = window.Meta.get("StereoAcuity");

			meta.PixiApp.stage.addChild(this.RDS);
			this.RDS.PixelSize = this.PixelSize;
			this.RDS.Depth = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(this.StereoAcuity));

			window.Meta.get("SetScore")(0);
			window.Meta.get("StartTimer")(this.Time);

			switch(this.VergenceType){
			case "Convergence":
				this.TrackerS.mode = "C";
				this.TrackerS.sepAmnt = [this.SeparationRateC];
				break;
			case "Divergence":
				this.TrackerS.mode = "D";
				this.TrackerS.sepAmnt = [this.SeparationRateD];
				break;
			case "Jump":
				this.TrackerS.mode = "J";
				this.TrackerS.sepAmnt = [this.SeparationRateC, this.SeparationRateD];
				break;
			case "Supra":
				this.TrackerS.mode = "BU";
				this.TrackerS.sepAmnt = [this.SeparationRateS];
				break;
			case "Infra":
				this.TrackerS.mode = "BD";
				this.TrackerS.sepAmnt = [this.SeparationRateI];
				break;
			default:
				break;
			}

			const dim = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(window.MetaVision.VaToArc(this.Acuity)));
			this.DIM = dim;

			this.ShipL = new window.PIXI.Sprite(this.Spaceship);
			this.ShipL.width = 75;
			this.ShipL.height = 75;
			this.ShipL.tint = window.Cali.LeftColorMax;
			this.ShipL.blendMode = window.PIXI.BLEND_MODES.ADD;
			meta.PixiApp.stage.addChild(this.ShipL);

			this.ShipR = new window.PIXI.Sprite(this.Spaceship);
			this.ShipR.width = 75;
			this.ShipR.height = 75;
			this.ShipR.tint = window.Cali.RightColorMax;
			this.ShipR.blendMode = window.PIXI.BLEND_MODES.ADD;
			meta.PixiApp.stage.addChild(this.ShipR);

			this.ShipSpot = 0.0;

			this.BulletL = new window.PIXI.Sprite(this.TheBullet);
			this.BulletL.width = 75;
			this.BulletL.height = 75;
			this.BulletL.tint = window.Cali.LeftColor;
			this.BulletL.blendMode = window.PIXI.BLEND_MODES.ADD;
			meta.PixiApp.stage.addChild(this.BulletL);

			this.BulletR = new window.PIXI.Sprite(this.TheBullet);
			this.BulletR.width = 75;
			this.BulletR.height = 75;
			this.BulletR.tint = window.Cali.RightColor;
			this.BulletR.blendMode = window.PIXI.BLEND_MODES.ADD;
			meta.PixiApp.stage.addChild(this.BulletR);

			this.BulletX = 500;
			this.BulletY = -1;

			this.UFOS = [];
			this.ReseedTime = 0;
			this.SpawnUFO(meta);

			const Shot = window.Meta.Sound("/sfx/Shot.wav");
			window.Meta.set("Explosion", window.Meta.Sound("/sfx/Pop.wav"));
			window.Meta.set("Wiff", window.Meta.Sound("/sfx/Whoosh.wav", 0.9));
			const FIRE = ()=>{
				if (this.BulletY < 0){
					this.BulletX = (this.ShipL.x + this.ShipR.x) / 2;
					this.BulletY = 0;
					Shot.Play();
				}
			};

			window.Meta.KeyDown("Up", ()=>{
				window.Meta.get("UnpauseTimer")();
				FIRE();
			});

			window.Meta.KeyDown("Accept", ()=>{
				this.TrackerS.Mercy();
				this.RDS.Separation = this.TrackerS.val();
			});
		},
		Tick : function(meta, delta){
			const dT = delta/60;

			this.ReseedTime += dT;
			if (this.ReseedTime > 0.06){
				this.ReseedTime = 0;
				this.RDS.ReSeed();
			}

			const Correct=()=>{
				window.Meta.get("UnpauseTimer")();
				this.ScoreUp(150);
				window.Meta.get("SetScore")(this.Score);
				this.AX.Acc(this.Reakt_Mark());
				this.Tracker.Correct();
				this.TrackerS.Correct();
				this.RDS.Separation = this.TrackerS.val();
				window.Meta.get("Explosion").Play();
				this.SpawnUFO(meta);
			}

			const Wrong=()=>{
				window.Meta.get("UnpauseTimer")();
				this.ScoreDown(50);
				window.Meta.get("SetScore")(this.Score);
				this.Tracker.Wrong();
				this.TrackerS.Wrong();
				window.Meta.get("Wiff").Play();
				this.RDS.Separation = this.TrackerS.val();
			}

			var ufodex = 0;
			while (ufodex < this.UFOS.length){
				this.UFOS[ufodex]._h += dT/5;
				if (this.UFOS[ufodex]._h > 1){
					this.UFOS[ufodex].obj.parent.removeChild(this.UFOS[ufodex].obj);
					this.UFOS.splice(ufodex, 1);
					Wrong();
					this.SpawnUFO(meta);
				}else{
					this.UFOS[ufodex].obj.x = meta.Screen.width/2 + (this.RDS.RDIM-this.UFOS[ufodex].obj.width)*this.UFOS[ufodex]._x;
					this.UFOS[ufodex].obj.y = this.RDS.RDIM * this.UFOS[ufodex]._h;
					ufodex++;
				}
			}

			if (window.Meta.Key("Left")){
				this.ShipSpot = window.Clamp(this.ShipSpot - 0.75*dT, -0.5, 0.5);
			}
			if (window.Meta.Key("Right")){
				this.ShipSpot = window.Clamp(this.ShipSpot + 0.75*dT, -0.5, 0.5);
			}

			if (this.BulletY >= 0){
				this.BulletY += dT;
				if (this.BulletY > 1){
					this.BulletY = -1;
					Wrong();
				}
			}
			this.DrawFlats(meta);

			for(var u = 0; u < this.UFOS.length; u++){
				if(this.Vector.Distance([this.UFOS[u].obj.x, this.UFOS[u].obj.y], [this.BulletX, this.BulletL.y]) <= this.DIM + 35){
					this.UFOS[u].obj.parent.removeChild(this.UFOS[u].obj);
					this.UFOS.splice(u, 1);
					this.BulletY = -1;
					Correct();
					break;
				}
			}
		},
		Resize : function(meta){
			this.RDS.RDIM = meta.Screen.height*0.7;
			this.DrawFlats(meta);
		},
		KountdownEnd : function(meta){
			if(this.Score > 0) {
				const type = {
					"Divergence": "Relax",
					"Convergence": "Flex",
					"Jump": "Stamina"
				}
	
				const scoreData = {
					score: this.Score,
					hits: this.Tracker.val(),
					avg_hit_time: this.AX.val() || 0,
					break: this.TrackerS.mode==="J" ? [this.TrackerS.best[0], this.TrackerS.best[1]] : this.TrackerS.best[0],
					recovery: this.TrackerS.mode==="J" ? [this.TrackerS.worst[0], this.TrackerS.worst[1]] : this.TrackerS.worst[0],
				}
				// Game Score
				createUserPlayedGame(
					'Vision Stamina',
					type[this.VergenceType],
					'UFO Destroyer',
					scoreData
				);
				// Daily check
				updateUserDailyActivity('vision stamina', this.VergenceType);
	
				window.localStorage.setItem('finishScreen', 'true');
				
				setTimeout(function() {
					window.location.reload();
				}, 1500);
			}

			window.Meta.Return({
				Score: this.Score,
				Hits: this.Tracker.val(),
				AvgHitTime : this.AX.val(),
				Break : this.TrackerS.mode=="J" ? [this.TrackerS.best[0], this.TrackerS.best[1]] : this.TrackerS.best[0],
				Recovery : this.TrackerS.mode=="J" ? [this.TrackerS.worst[0], this.TrackerS.worst[1]] : this.TrackerS.worst[0],
			});
		},
		SpawnUFO : function(meta){
			const circle = new window.PIXI.Graphics().beginFill(0xffffff).drawCircle(0, 0, this.DIM/2);
			circle.y = -100;
			circle.x = meta.Screen.width/2;
			this.RDS.addChild(circle);
			this.UFOS.push({obj:circle, _h:0, _x:window.MetaRandom.Range(-0.5, 0.5)});
		},
		DrawFlats : function(meta){
			const sep = this.TrackerS.val();
			this.ShipL.x = meta.Screen.width/2 + (this.RDS.RDIM-this.ShipL.width)*this.ShipSpot - this.ShipL.width/2 - sep[0];
			this.ShipL.y = meta.Screen.height/2 + this.RDS.RDIM/2 - sep[1];
			this.ShipR.x = meta.Screen.width/2 + (this.RDS.RDIM-this.ShipL.width)*this.ShipSpot - this.ShipL.width/2 + sep[0];
			this.ShipR.y = meta.Screen.height/2 + this.RDS.RDIM/2 + sep[1];

			this.BulletL.x = this.BulletX - sep[0];
			this.BulletL.y = (meta.Screen.height/2 + this.RDS.RDIM/2) - this.RDS.RDIM*this.BulletY - this.BulletL.height - sep[1];
			this.BulletR.x = this.BulletX + sep[0];
			this.BulletR.y = (meta.Screen.height/2 + this.RDS.RDIM/2) - this.RDS.RDIM*this.BulletY - this.BulletL.height + sep[1];
		}
	})
});

const UfoSmasher_FG = window.Meta.Engine(VT_Base, LegacyEmbed, {
	LegacyEngine : window.Meta.Combine(RDS, VT2, AX, Tracker, TrackerS, Reakt, VectorUtils, {
		Load : [
			["TheMallet", "/img/Mallet.png"],
		],
		Start : function(meta){
			meta.backgroundColor = 0x000000;
			window.Meta.Cursor(0);

			this.Time = window.Meta.get("Time");
			this.VergenceType = window.Meta.get("VergenceType");
			this.SeparationRateD = window.Meta.get("SeparationRateD");
			this.SeparationRateC = window.Meta.get("SeparationRateC");
			this.SeparationRateS = window.Meta.get("SeparationRateS");
			this.SeparationRateI = window.Meta.get("SeparationRateI");
			this.Acuity = window.Meta.get("Acuity");
			this.PixelSize = window.Meta.get("PixelSize");
			this.StereoAcuity = window.Meta.get("StereoAcuity");

			meta.PixiApp.stage.addChild(this.RDS);
			this.RDS.PixelSize = this.PixelSize;
			this.RDS.Depth = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(this.StereoAcuity));

			window.Meta.get("SetScore")(0);
			window.Meta.get("StartTimer")(this.Time);

			switch(this.VergenceType){
			case "Convergence":
				this.TrackerS.mode = "C";
				this.TrackerS.sepAmnt = [this.SeparationRateC];
				break;
			case "Divergence":
				this.TrackerS.mode = "D";
				this.TrackerS.sepAmnt = [this.SeparationRateD];
				break;
			case "Jump":
				this.TrackerS.mode = "J";
				this.TrackerS.sepAmnt = [this.SeparationRateC, this.SeparationRateD];
				break;
			case "Supra":
				this.TrackerS.mode = "BU";
				this.TrackerS.sepAmnt = [this.SeparationRateS];
				break;
			case "Infra":
				this.TrackerS.mode = "BD";
				this.TrackerS.sepAmnt = [this.SeparationRateI];
				break;
			}

			const dim = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(window.MetaVision.VaToArc(this.Acuity)));
			this.UFO = new window.PIXI.Graphics().beginFill(0xffffff).drawCircle(0,0,dim/2);
			this.RDS.addChild(this.UFO);

			const Smash = window.Meta.Sound("/sfx/Pop.wav");
			const Whoosh = window.Meta.Sound("/sfx/Whoosh.wav", 0.9);
			this.RDS.interactive = true;
			this.RDS.on('pointerdown', () => {
				const Dist = this.Vector.Distance([meta.PixiApp.renderer.plugins.interaction.mouse.global.x, meta.PixiApp.renderer.plugins.interaction.mouse.global.y], [this.UFO.x, this.UFO.y]);

				if (Dist <= dim/2 + 30){
					window.Meta.get("UnpauseTimer")();
					this.ScoreUp(100);
					this.Tracker.Correct();
					this.TrackerS.Correct();
					Smash.Play();
				}else{
					window.Meta.get("UnpauseTimer")();
					this.ScoreDown(50);
					this.Tracker.Wrong();
					this.TrackerS.Wrong();
					Whoosh.Play();
				}

				window.Meta.get("SetScore")(this.Score);
				this.AX.Acc(this.Reakt_Mark());
				this.RDS.Separation = this.TrackerS.val();
				this.Shuffle(meta);
			});

			this.MalletL = new window.PIXI.Sprite(this.TheMallet);
			this.MalletL.width = 100;
			this.MalletL.height = 100;
			this.MalletL.tint = window.Cali.LeftColorMax;
			this.MalletL.blendMode = window.PIXI.BLEND_MODES.ADD;
			meta.PixiApp.stage.addChild(this.MalletL);

			this.MalletR = new window.PIXI.Sprite(this.TheMallet);
			this.MalletR.width = 100;
			this.MalletR.height = 100;
			this.MalletR.tint = window.Cali.RightColorMax;
			this.MalletR.blendMode = window.PIXI.BLEND_MODES.ADD;
			meta.PixiApp.stage.addChild(this.MalletR);

			window.Meta.KeyDown("Accept", ()=>{
				this.TrackerS.Mercy();
				this.RDS.Separation = this.TrackerS.val();
			});

			this.Shuffle(meta);
		},
		Tick : function(meta, delta){
			this.DrawFlats(meta);
		},
		Shuffle : function(meta){
			const MIN = - this.RDS.RDIM/2 + this.UFO.height/2;
			const MAX =  this.RDS.RDIM/2 - this.UFO.height/2;
			this.UFO.x = meta.PixiApp.screen.width/2 + window.MetaRandom.Range(MIN, MAX);
			this.UFO.y = meta.PixiApp.screen.height/2 + window.MetaRandom.Range(MIN, MAX);

			this.RDS.ReSeed();
		},
		Resize : function(meta){
			this.RDS.RDIM = meta.Screen.height*0.7;
			this.Shuffle(meta);
			this.DrawFlats(meta);
		},
		KountdownEnd : function(meta) {
			if(this.Score > 0) {
				const type = {
					"Divergence": "Relax",
					"Convergence": "Flex",
					"Jump": "Stamina"
				}
	
				const scoreData = {
					score: this.Score,
					hits: this.Tracker.val(),
					avg_hit_time: this.AX.val() || 0,
					break: this.TrackerS.mode==="J" ? [this.TrackerS.best[0], this.TrackerS.best[1]] : this.TrackerS.best[0],
					recovery: this.TrackerS.mode==="J" ? [this.TrackerS.worst[0], this.TrackerS.worst[1]] : this.TrackerS.worst[0],
				}
	
				createUserPlayedGame(
					'Vision Stamina',
					type[this.VergenceType],
					'UFO Smasher',
					scoreData
				);
	
				updateUserDailyActivity('vision stamina', this.VergenceType);
	
				window.localStorage.setItem('finishScreen', 'true');
				
				setTimeout(function() {
					window.location.reload();
				}, 1500);
			}

			window.Meta.Return({
				Score: this.Score,
				Hits: this.Tracker.val(),
				AvgHitTime : this.AX.val(),
				Break : this.TrackerS.mode=="J" ? [this.TrackerS.best[0], this.TrackerS.best[1]] : this.TrackerS.best[0],
				Recovery : this.TrackerS.mode=="J" ? [this.TrackerS.worst[0], this.TrackerS.worst[1]] : this.TrackerS.worst[0],
			});
		},
		DrawFlats : function(meta){
			const sep = this.TrackerS.val();
			this.MalletL.x = meta.PixiApp.renderer.plugins.interaction.mouse.global.x - 30 - sep[0];
			this.MalletL.y = meta.PixiApp.renderer.plugins.interaction.mouse.global.y - 30 - sep[1];
			this.MalletR.x = meta.PixiApp.renderer.plugins.interaction.mouse.global.x - 30 + sep[0];
			this.MalletR.y = meta.PixiApp.renderer.plugins.interaction.mouse.global.y - 30 + sep[1];
		}
	})
});

const _Game_Library_ = [];
// Relax
_Game_Library_["diamonds-relax"] = [Diamonds_FG, {Time:2, VergenceType:"Divergence", Acuity:"20/800", StereoAcuity:"800", SeparationRateD:1}];
_Game_Library_["destroyer-relax"] = [UfoDestroyer_FG, {Time:2, VergenceType:"Divergence", Acuity:"20/800", StereoAcuity:"800", SeparationRateD:1}]
_Game_Library_["astucia-relax"] = [UfoSmasher_FG, {Time:2, VergenceType:"Divergence", Acuity:"20/800", StereoAcuity:"800", SeparationRateD:1}]

// Flex
_Game_Library_["diamonds-flex"] = [Diamonds_FG, {Time:2, VergenceType:"Convergence", Acuity:"20/800", StereoAcuity:"800", SeparationRateC:1}];
_Game_Library_["destroyer-flex"] = [UfoDestroyer_FG, {Time:2, VergenceType:"Convergence", Acuity:"20/800", StereoAcuity:"800", SeparationRateC:1}]
_Game_Library_["astucia-flex"] = [UfoSmasher_FG, {Time:2, VergenceType:"Convergence", Acuity:"20/800", StereoAcuity:"800", SeparationRateC:1}]

// Stamina
_Game_Library_["diamonds-stamina"] = [Diamonds_FG, {Time:2, VergenceType:"Jump", Acuity:"20/600", StereoAcuity:"800", SeparationRateD:1, SeparationRateC:1}];
_Game_Library_["destroyer-stamina"] = [UfoDestroyer_FG, {Time:2, VergenceType:"Jump", Acuity:"20/600", StereoAcuity:"800", SeparationRateD:1, SeparationRateC:1}]
_Game_Library_["astucia-stamina"] = [UfoSmasher_FG, {Time:2, VergenceType:"Jump", Acuity:"20/800", StereoAcuity:"800", SeparationRateD:1, SeparationRateC:1}]

_Game_Library_["chase"] = [window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {LegacyEngine: window.Meta.Combine(SmoothTracking, StandardArrow)}),
						{Time:1, SizeMM:12, Orientation:"8", SpeedPerHour:5}];
_Game_Library_["reflex"] = [window.Meta.Engine(VT_Base, PlayerCursor, LegacyEmbed, {LegacyEngine: window.Meta.Combine(JumpTracking, StandardArrow)}),
						{Time:1, SizeMM:12, Orientation:"8"}];

_Game_Library_["math"] = [AccommMath_FG, {Time:2}]
_Game_Library_["symbols"] = [AccommTumbleE_FG, {Time:2}]
