import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { createUser } from '../firebase/user';
import { signOut, reauthenticateWithCredential, EmailAuthProvider, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password, clinician) {
    return createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      try {
        await sendEmailVerification(userCredential.user);
        await createUser(userCredential.user.uid, true, email, clinician);
        signOut(auth);
      } catch (error) {
        console.log(error);
      }
    });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function reauthenticate(password) {
    const credential = EmailAuthProvider.credential(currentUser.email, password);
    return reauthenticateWithCredential(currentUser, credential);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updatePassword,
    reauthenticate
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
