import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import LineChart from '../../components/LineChart'
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';

function ChartContainer(data) {
  const {t}= useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  return (
    <Box backgroundColor={colors.primary[400]} borderRadius={2} py={2}>
      <Typography
        variant="h3"
        fontWeight="bold"
        color={colors.greenAccent[500]}
        align={'center'}
      >
        {t(data.data.name)}
      </Typography>
      <Box height={'25rem'} m={2}>
        <LineChart chartData={data.data.data} />
      </Box>
    </Box>
  )
}

export default ChartContainer