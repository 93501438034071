const glassInstruction = {
  image: '/instructions/all_instruction_1.svg',
  text: 'instructions.glass-instruction'
}

const diamondsInstructions = [
  glassInstruction,
  {
    image: '/instructions/Diamonds - pop out.svg',
    text: 'instructions.diamonds-instructions.diamonds-1'
  },
  {
    image: '/instructions/Diamonds - Arrow keys.svg',
    // text: 'Push the arrow keys for the part of the screen where the shape appears.'
    text: 'instructions.diamonds-instructions.diamonds-2'
  },
  {
    image: '/instructions/Diamonds - Split.svg',
    text: 'instructions.diamonds-instructions.diamonds-3'
  },
];

const astuciaInstructions = [
  glassInstruction,
  {
    image: '/instructions/Destroyer - pop out.svg',
    text: 'instructions.astucia-instructions.astucia-1'
  },
  {
    image: '/instructions/Smasher - hammer.svg',
    text: 'instructions.astucia-instructions.astucia-2'
  },
  // {
  //   image: '/instructions/HammerAlien.svg',
  //   text: 'Click to smash the Shape.'
  // },
  {
    image: '/instructions/Destroyer - Split.svg',
    text: 'instructions.astucia-instructions.astucia-3'
  },
];

const destroyerInstructions = [
  glassInstruction,
  {
    image: '/instructions/Destroyer - pop out.svg',
    text: 'instructions.destroyer-instructions.destroyer-1'
  },
  {
    image: '/instructions/Destroyer - left right.svg',
    text: 'instructions.destroyer-instructions.destroyer-2'
  },
  {
    image: '/instructions/Destroyer - shoot.svg',
    text: 'instructions.destroyer-instructions.destroyer-3'
  },
  {
    image: '/instructions/Destroyer - Split.svg',
    text: 'instructions.destroyer-instructions.destroyer-4'
  },
];

const chaseInstrucions = [
  glassInstruction,
  // {
  //   image: '/instructions/tracking_instruction_2.svg',
  //   text: 'Follow the arrow with your eyes as it moves.'
  // },
  {
    image: '/instructions/Synching - moving arrow.svg',
    text: 'instructions.chase-instructions.chase-1'
  },
  {
    image: '/instructions/Recurso-20.svg',
    text: 'instructions.chase-instructions.chase-2'
  },
];

const reflexInstructions = [
 glassInstruction,
  // {
  //   image: '/instructions/tracking_instruction_2.svg',
  //   text: 'Follow the arrow with your eyes as it jumps positions.'
  // },
  {
    image: '/instructions/Synching - arrow keys.svg',
    text: 'instructions.reflex-instructions.reflex-1'
  },
  {
    image: '/instructions/Recurso-20.svg',
    text: 'instructions.reflex-instructions.reflex-2'
  },
]

const symbolsInstructions = [
  glassInstruction,
  {
    image: '/instructions/Focusing - QR.svg',
    text: 'instructions.speed-instructions.speed-1'
  },
  {
    image: '/instructions/Recurso-27.svg',
    text: 'instructions.speed-instructions.speed-2'
  },

  {
    image: '/instructions/Focusing - Symbols.svg',
    text: 'instructions.speed-instructions.speed-3'
  },

];

const mathInstructions = [
  glassInstruction,
  {
    image: '/instructions/Focusing - QR.svg',
    text: 'instructions.speed-instructions.speed-1'
  },
  {
    image: '/instructions/Recurso-27.svg',
    text: 'instructions.speed-instructions.speed-2'
  },


  {
    image: '/instructions/Focusing - Math.svg',
    text: 'instructions.speed-instructions.speed-4'
  },
];

export const instructionData = {
  // Diamonds
  'diamonds-relax': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Relax',
      game: 'Diamonds'
    },
    next: '/games/diamonds-flex',
    game: 'instructions.diamonds-instructions.titles.diamonds-relax',
    instructions: diamondsInstructions,
    pregame: {
      reqL: false
    }
  },
  'diamonds-flex': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Flex',
      game: 'Diamonds'
    },
    next: '/games/diamonds-stamina',
    game: 'instructions.diamonds-instructions.titles.diamonds-flex',
    instructions: diamondsInstructions,
    pregame: {
      reqL: false
    }
  },
  'diamonds-stamina': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Stamina',
      game: 'Diamonds'
    },
    next: '/games/chase',
    game: 'instructions.diamonds-instructions.titles.diamonds-stamina',
    instructions: diamondsInstructions,
    pregame: {
      reqL: false
    }
  },
  // Astucia
  'astucia-relax': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Relax',
      game: 'UFO Smasher'
    },
    next: '/games/astucia-flex',
    game: 'instructions.astucia-instructions.titles.astucia-relax',
    instructions: astuciaInstructions,
    pregame: {
      reqL: false
    }
  },
  'astucia-flex': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Flex',
      game: 'UFO Smasher'
    },
    next: '/games/astucia-stamina',
    game: 'instructions.astucia-instructions.titles.astucia-flex',
    instructions: astuciaInstructions,
    pregame: {
      reqL: false
    }
  },
  'astucia-stamina': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Stamina',
      game: 'UFO Smasher'
    },
    next: '/games/chase',
    game: 'instructions.astucia-instructions.titles.astucia-stamina',
    instructions: astuciaInstructions,
    pregame: {
      reqL: false
    }
  },
  // UFO Destroyer
  'destroyer-relax': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Relax',
      game: 'UFO Destroyer'
    },
    next: '/games/destroyer-flex',
    game: 'instructions.destroyer-instructions.titles.destroyer-relax',
    instructions: destroyerInstructions,
    pregame: {
      reqL: false
    }
  },
  'destroyer-flex': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Flex',
      game: 'UFO Destroyer'
    },
    next: '/games/destroyer-stamina',
    game: 'instructions.destroyer-instructions.titles.destroyer-flex',
    instructions: destroyerInstructions,
    pregame: {
      reqL: false
    }
  },
  'destroyer-stamina': {
    fbData: { // Firebase data
      category: 'Vision Stamina',
      subcategory: 'Stamina',
      game: 'UFO Destroyer'
    },
    next: '/games/chase',
    game: 'instructions.destroyer-instructions.titles.destroyer-stamina',
    instructions: destroyerInstructions,
    pregame: {
      reqL: false
    }
  },
  'chase': {
    fbData: { // Firebase data
      category: 'Eye Syncing',
      subcategory: '',
      game: 'Chase'
    },
    next: '/games/reflex',
    game: 'instructions.chase-instructions.title',
    instructions: chaseInstrucions,
    pregame: {
      reqL: false
    }
  },
  'reflex': {
    fbData: { // Firebase data
      category: 'Eye Syncing',
      subcategory: '',
      game: 'Reflex'
    },
    next: '/games/speed',
    game: 'instructions.reflex-instructions.title',
    instructions: reflexInstructions,
    pregame: {
      reqL: false
    }
  },
  'symbols': {
    fbData: { // Firebase data
      category: 'Focus Speed',
      subcategory: 'Speed',
      game: 'Symbols'
    },
    next: '/',
    game: 'Symbols',
    instructions: symbolsInstructions,
    pregame: {
      reqL: true
    }
  },
  'math': {
    fbData: { // Firebase data
      category: 'Focus Speed',
      subcategory: 'Speed',
      game: 'Math'
    },
    next: '/',
    game: 'Math',
    instructions: mathInstructions,
    pregame: {
      reqL: true
    }
  }
}