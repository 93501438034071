import { Box, Grid, Typography, Card, useTheme, CardActionArea } from "@mui/material";
import { tokens } from "../../theme";
import { arcadeData } from "../../data/arcadeData";
import Header from "../../components/Header";

const Arcade = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return(
    <Box sx={{ width: { xs: '100%', md: '90%' } }} m={'auto'}>
      <Header title="" subtitle="" />
      <Grid container>
        {arcadeData.map((activity, index) =>{
          return(
            <Grid item xs={12} key={index} pb={2}>
              <Typography variant="h2">{activity.title}</Typography>
                <Grid container spacing={2} my={1}>
                  <Grid item xs={6} md={2}>
                    <Card sx={{ background: colors.blueAccent[800] }}>
                      <Box display={'flex'} justifyContent={'center'} margin={1}>
                        <img src={require("../../assets/elipses.png")} alt={'Game'} width={'75%'} />
                      </Box>
                      <Typography variant="h4" align="center" gutterBottom>{activity.title}</Typography>
                      <Typography align="center" >2 of {activity.games.length} Completed</Typography>
                    </Card>
                  </Grid>
                  {activity.games.map((game, index) =>{
                    return(
                    <Grid item xs={6} md={2} key={index}>
                      <Card sx={{ background: colors.grey[900] }}>
                        <CardActionArea href={game.href}>
                          <Box display={'flex'} justifyContent={'center'} padding={1}>
                            <img src={require("../../assets/"+game.img)} alt={'Game'} key={game.name} width={'75%'} />
                          </Box>
                        <Typography variant="h4" align="center" gutterBottom>{game.name}</Typography>
                        <Typography align="center" >{activity.title}</Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>)})
                  }
                </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  );
}

export default Arcade;