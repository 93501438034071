import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { createOne } from "./crud";

/*******************************************************/
/***********************Create**************************/
/*******************************************************/

/**
 * Create a document eact time a user completes a game
 * @param category principal division for games: Vision Stamina, Eye Syncing, Focus Speed, etc...
 * @param subcategory division within category: {relax, flex, stamina}, {etc...}
 * @param game actual name of the game
 * @param scoreData score need to be safe for each game
 */
export function createUserPlayedGame(
  category,
  subcategory,
  game,
  scoreData
) {
  const auth = getAuth()

  onAuthStateChanged(auth, (user) => {
    if(user) {
      let userPlayedGame = {
        user: user.uid,
        category: category,
        subcategory: subcategory,
        game: game,
        date: new Date(),
      }
      userPlayedGame = Object.assign(userPlayedGame, scoreData);

      createOne('userPlayedGames', userPlayedGame);
    }
  })
}

/*******************************************************/
/*************************Read**************************/
/*******************************************************/

/**
 * Get all documents from vision stamina game
 * @param uid user id
 * @param subcategory vergence type from: Relax, Flex or Stamina
 * @returns score data of one vision stamins game
 */
export async function getVisionStaminaPlayedGames(uid, subcategory) {
  const q = query(collection(db, 'userPlayedGames'), where("user", "==", uid), where("category", "==", 'Vision Stamina'), where("subcategory", "==", subcategory), orderBy("date", "asc"));

  const querySnapshot = await getDocs(q);
  const data = []
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });

  const scoreData = [];

  data.map((document, index) => {
    scoreData.push({x: index + 1, y: document.score})
  })

  const type = {
    "Relax": "Relax",
    "Flex": "Flex",
    "Stamina": "Stamina Challenge"
  }

  const chartData = {
    name: type[subcategory],
    data: [
      {
        id: 'Score',
        color: "hsl(172, 70%, 50%)",
        data: scoreData
      }
    ]
  }
  return chartData;
}

/**
 * Get all documents from vision stamina game
 * @param uid user id
 * @returns score data for each game
 */
export async function getEyeSyncingPlayedGames(uid, type) {
  const q = query(collection(db, 'userPlayedGames'), where('user', '==', uid), where("game", "==", type), orderBy('date', "asc"));

  const querySnapshot = await getDocs(q);
  const data = [];

  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });

  // Right, left
  const scoreData = [[], []];

  data.map((document, index) => {
    scoreData[0].push({x: index + 1, y: document.score_l});
    scoreData[1].push({x: index + 1, y: document.score_r});
  })
  
  const chartData = {
    name: type,
    data:[
      {
        id: "Left Eye",
        color: "hsl(172, 70%, 50%)",
        data: scoreData[0],
      },
      {
        id: "Right Eye",
        color: "hsl(172, 70%, 50%)",
        data: scoreData[1],
      },
    ]
  }

  return chartData;
}

/**
 * Get all documents from vision stamina game
 * @param uid user id
 * @returns score data for each game
 */
export async function getFocusingPlayedGames(uid, game) {
  const q = query(collection(db, 'userPlayedGames'), where('user', '==', uid), where("game", "==", game), orderBy('date', "asc"));

  const querySnapshot = await getDocs(q);
  const data = [];

  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  });

  // Left, Right, Both
  const scoreData = [[], [], []];

  data.map((document, index) => {
    scoreData[0].push({x: index + 1, y: document.avg_hit_time_l});
    scoreData[1].push({x: index + 1, y: document.avg_hit_time_r});
    scoreData[2].push({x: index + 1, y: document.avg_hit_time_b});
  })
  
  const chartData = {
    name: 'Astucia',
    data:[
      {
        id: "Left Eye",
        color: "hsl(172, 70%, 50%)",
        data: scoreData[0],
      },
      {
        id: "Right Eye",
        color: "hsl(172, 70%, 50%)",
        data: scoreData[1],
      },
      // {
      //   id: "Both Eyes",
      //   color: "hsl(172, 70%, 50%)",
      //   data: scoreData[2],
      // },
    ]
  }

  return chartData;
}

/*******************************************************/
/***********************Update**************************/
/*******************************************************/
