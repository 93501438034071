import { TextField } from '@mui/material';

const CustomTextField = (props) => {
  const { type, range, ...rest } = props; // Destructure the 'type' and 'range' props

  const inputProps = {};

  if (type === 'number' && range) {
    inputProps.min = range.min; // Set the minimum value
    inputProps.max = range.max; // Set the maximum value
  }

  return (
    <TextField
      {...rest}
      type={type || 'text'}
      color='secondary'
      variant='outlined'
      sx={{
        backgroundColor: '#293040',
        borderRadius: '1rem',
        maxWidth: '17.063rem',
        '& .MuiOutlinedInput-root': {
          borderRadius: '1rem',
        },
        '& .MuiOutlinedInput-input': {
          paddingBottom: '0.313rem',
          paddingTop: '0.313rem',
          paddingLeft: '1rem',
          fontSize: '1.188rem'
        }
      }}
      inputProps={inputProps} // Pass the inputProps to the TextField component
    />
  );
};

export default CustomTextField;
