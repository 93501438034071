import { useEffect } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const CustomEmailActionHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleEmailAction = async () => {
      // Get the action code from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get('mode');
      const actionCode = urlParams.get('oobCode');

      // Handle the user management action.
      switch (mode) {
        case 'resetPassword':
          console.log(actionCode);
          navigate(`/reset-password?oobCode=${encodeURIComponent(actionCode)}`);
          break;
        case 'verifyEmail':
          try {
            await auth.applyActionCode(actionCode);
            navigate('/success');
          } catch (error) {
            console.error('Error handling the action:', error);
            navigate('/error');
          }
          break;
        default:
          // Error: invalid mode.
          navigate('/error');
      }

      // try {
      //   // Apply the action code to handle the custom action
      //   await auth.applyActionCode(actionCode);

      //   // Handle the action success, e.g., redirect to a success page
      //   window.location.href = '/success';
      // } catch (error) {
      //   // Handle the action error, e.g., redirect to an error page
      //   console.error('Error handling the action:', error);
      //   window.location.href = '/error';
      // }
    };

    handleEmailAction();
  }, [navigate]);

  return null; // or render a loading indicator if desired
};

export default CustomEmailActionHandler;