import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

export async function createUser(uid, tos, email, clinician) {
  try {
    await setDoc(doc(db, 'users', uid), {
      accepted_tos: tos,
      email: email,
      clinician: clinician
    })
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}