import { useTheme } from '@mui/material'
import { tokens } from "../../theme";
import React from 'react'
import { useEffect } from 'react';
import { ScreenCali_Engine } from '../../scripts/GameEngines';
import ColorCalibrationModal from '../../components/ColorCalibrationModal';
import CalibrationModal from '../home/CalibrationModal'



const Calibration = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    window.Meta.Window(document.getElementById("MetaEngine"));
  }, []);

  const handleButton = () => {
    window.Meta.Do(ScreenCali_Engine)
  }

  return (
    // <Box
    // display="flex"
    // justifyContent="center"
    // alignItems="center"
    // gap={40}
    // sx={{ height: '100%' }}>
    //   <Button onClick={handleButton} sx=
    //   {[
    //     { 
    //       background: colors.greenAccent[500],
    //       '&:hover': {
    //         color: colors.grey[900],
    //         backgroundColor: colors.grey[500],
    //       },
    //     }
    //   ]}>Start Calibration</Button>
    //   <ColorCalibrationModal />
    // </Box>
    < CalibrationModal/>
  )
}
export default Calibration;