import React, { useState } from 'react'
import { Box, Typography, FormControl, TextField, Button, Link, useTheme, Alert } from '@mui/material'
import { useForm } from 'react-hook-form';
import { tokens } from '../../theme';
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { useTranslation } from 'react-i18next';

class AuthenticateError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthenticateError';
  }
}

const Login = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Error message
  const [error, setError] = useState('');
  // Loading for signup function, not create multiple accounts at the same time
  const [loading, setLoading] = useState(false);
  const { login } = useAuth()

  const navigate = useNavigate()
  
  // Form hook
  const { register, handleSubmit } = useForm();

  async function onSubmit(data,e)  {
    e.preventDefault();
    
    try{
      setError('')
      setLoading(true);
      const userCredential = await login(data.email, data.password);
      const user = userCredential.user;
      if (!user.emailVerified) {
        throw new AuthenticateError(t('login.error1'))
      }
      toast.success(t('login.success'), {
        position: toast.POSITION.TOP_RIGHT
      });
      navigate('/');
    } catch(error) {
      if(error instanceof AuthenticateError) {
        setError(t('login.error2'))
      } else {
        toast.error(t('login.error3'), {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
    setLoading(false)
  }  

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
      width={{ xs: '90%', md: '20%'}}
      margin={'auto'}
    >
      <Logo width={200} height={200} />
      <Typography variant='h2' fontWeight={'bold'} pb={2} gutterBottom>{t('login.login')}</Typography>
      {error && <Alert sx={{ mb: 2, fontSize: '1rem' }} variant='filled' severity='error' color='secondary' >{error}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" method='post' style={{ width: '100%' }}>
        <FormControl fullWidth variant="outlined" sx={{ paddingBottom: 3 }}>
          <TextField {...register("email")} id="email" label="Email" type={'email'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
          <TextField {...register("password")} id="password" label={t('login.password')} type={'password'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
          <Button type='submit' variant='contained' color='secondary' size='large' sx={{ borderRadius: '1rem' }}>{t('login.login')}</Button>
        </FormControl>
      </form>
      {/* <button onClick={showToastMessage}>Notify</button> */}
      <Typography gutterBottom>{t('login.dont-have-account')}<Link href='/signup' underline='none' color={colors.greenAccent[500]} fontWeight={'bold'}> {t('login.create-account')}</Link></Typography>
      <Typography gutterBottom><Link href='forgot-password' underline='none' color={colors.greenAccent[500]} fontWeight={'bold'}>{t('login.forgot-password')}</Link></Typography>
    </Box>
  )
}

export default Login;