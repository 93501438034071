import { ReactComponent as Astucia } from "../assets/games/Astucia.svg";
import { ReactComponent as Chase } from "../assets/games/Chase.svg";
import { ReactComponent as Flex } from "../assets/games/Flex.svg";
import { ReactComponent as Reflex } from "../assets/games/Reflex.svg";
import { ReactComponent as Relax } from "../assets/games/Relax.svg";
import { ReactComponent as StaminaChallenge } from "../assets/games/StaminaChallenge.svg";
import { ReactComponent as Synching } from "../assets/category/Synching.svg"
import { ReactComponent as Focusing } from "../assets/category/Focusing.svg"

export const gameData = [
  {
    title: "home.category.stamina.title", // Card header
    img: <img src={require("../assets/category/visionStamina-edited.png")} alt={'Game'} width={'140px'} height={'auto'} style={{ margin: '0', paddingTop: '0.5rem' }} />,
    description: "home.category.stamina.description",
        notion: '#',
    randomArray: ["/games/ufo_smasher", "/games/ufo_destroyer"],
    tour: 'tour-3',
    types: [
      {
        name: "home.category.stamina.subcategory.relax.title",
        img: <Relax width={'80%'} height={'auto'} 
       style={{
        margin:'auto'
       }}
        />,
        games: [ // Card games
          {
            name: 'home.category.stamina.subcategory.relax.games.diamonds',
            href: "/games/diamonds-relax"
          },
          {
            name: 'home.category.stamina.subcategory.relax.games.ufo-destroyer',
            href: "/games/destroyer-relax"
          },
          {
            name: 'home.category.stamina.subcategory.relax.games.astucia',
            href: "/games/astucia-relax"
          }
        ]
      },
      {
        name: "home.category.stamina.subcategory.flex.title",
        img: <Flex width={'80%'} height={'auto'} 
        style={{
         margin:'auto'
        }} />,
        games: [
          {
            name: 'home.category.stamina.subcategory.flex.games.diamonds',
            href: "/games/diamonds-flex"
          },{
            name: 'home.category.stamina.subcategory.flex.games.ufo-destroyer',
            href: "/games/destroyer-flex"
          },
          {
            name: 'home.category.stamina.subcategory.flex.games.astucia',
            href: "/games/astucia-flex"
          }
        ]
      },
      {
        name: "home.category.stamina.subcategory.stamina-challenge.title",
        img: <StaminaChallenge width={'80%'} height={'auto'} 
        style={{
         margin:'auto'
        }} />,
        games: [
          {
            name: 'home.category.stamina.subcategory.stamina-challenge.games.diamonds',
            href: "/games/diamonds-stamina"
          },{
            name: 'home.category.stamina.subcategory.stamina-challenge.games.ufo-destroyer',
            href: "/games/destroyer-stamina"
          },
          {
            name: 'home.category.stamina.subcategory.stamina-challenge.games.astucia',
            href: "/games/astucia-stamina"
          }
        ]
      }
    ]
  },
  {
    title: "home.category.synching.title",
    // img: <img src={require("../assets/category/Synching.png")} alt={'Game'} width={'220px'} height={'auto'} style={{ margin: '0', paddingTop: '0.5rem' }} />,
    img: <Synching style={{ width: '50%', height: '100%', margin: 'auto' }} />,
    description: 'home.category.synching.description',
    notion: '#',
    randomArray: [],
    tour: '',
    types: [
      {
        name: "home.category.synching.subcategory.chase.title",
        img: <Chase width={'80%'} height={'auto'} 
        style={{
         margin:'auto'
        }} />,
        games: [
          {
            name: 'home.start',
            href: "/games/chase"
          }
        ]
      },
      {
        name: "home.category.synching.subcategory.reflex.title",
        img: <Reflex width={'80%'} height={'auto'}
        style={{
         margin:'auto'
        }} />,
        games: [
          {
            name: 'home.start',
            href: "/games/reflex"
          }
        ]
      }
    ]
  },
  {
    title: "home.category.focusing.title",
    // img: <img src={require("../assets/category/Focusing.png")} alt={'Game'} width={'250px'} height={'auto'} style={{ margin: '0', paddingTop: '0.5rem' }} />,
    img: <Focusing style={{ width: '35%', height: '100%', margin: 'auto' }} />,
    description: 'home.category.focusing.description',
    notion: '#',
    randomArray: [],
    tour: '',
    types: [
      {
        name: "home.category.focusing.subcategory.speed.title",
        img: <Astucia width={'80%'} height={'auto'} 
        style={{
         margin:'auto'
        }} />,
        games: [
          {
            name: 'Math',
            href: "/games/math"
          },
          {
            name: 'Symbols',
            href: "/games/symbols"
          }
        ]
      }
    ]
  }
]