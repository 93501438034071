import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, FormControl, Button, Stack, Select, MenuItem, CircularProgress, Checkbox } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/AuthContext';
import Admin from './Admin';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../components/CustomFieldText';
import ChangePassword from './ChangePassword';
import Clinician from './Clinician';

const Account = () => {
  const { t } = useTranslation();

  // User
  const { currentUser } = useAuth();
  const [userInfo, setUserInfo] = useState(null);

  const [loading, setLoading] = useState(false);

  const updateUser = async (data) => {
    setLoading(true);
    if (!data.professional_athlete) {
      data.sport = "";
    }
    try {
      const api = 'https://us-central1-visi-play.cloudfunctions.net/app/api/users/' + currentUser.uid;
      await fetch(api, {
        method: 'PUT',
        body: JSON.stringify(data), // Convert data to JSON string
        headers: {
          'Content-Type': 'application/json' // Specify the content type as JSON
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { handleSubmit, control, setValue, watch } = useForm();
  const professionalAthlete = watch('professional_athlete', false);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const api = `https://us-central1-visi-play.cloudfunctions.net/app/api/users/${currentUser.uid}`;
        const res = await fetch(api, { mode: 'cors', method: 'GET' });
        const userData = await res.json();
        setUserInfo(userData);
  
        // Set the form field values using setValue
        if (userData) {
          Object.keys(userData).forEach((key) => {
            setValue(key, userData[key]);
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    fetchUserData();
  }, [currentUser.uid, setValue]);

  return (
    <Box sx={{ width: { xs: '95%', md: '90%', lg: '80%' } }} mx={'auto'} py={2}>
      {userInfo ? (
        <Fragment>
          <form onSubmit={handleSubmit(updateUser)} autoComplete="off" method="put">
            <FormControl variant="outlined" sx={{ py: 3 }}>
              <Typography variant="h2" gutterBottom pb={2}>
                {t('account.personal-information.title')}
              </Typography>
              <Stack direction={'row'} spacing={5} pb={2}>
                {/* First Name */}
                <Stack>
                  <Typography variant="h4" gutterBottom>
                    {t('account.personal-information.first-name')}
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <CustomTextField {...field} />}
                  />
                </Stack>
                {/* Last Name */}
                <Stack>
                  <Typography variant="h4" gutterBottom>
                    {t('account.personal-information.last-name')}
                  </Typography>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => <CustomTextField {...field} />}
                  />
                </Stack>
              </Stack>
              {/* Date of Birth */}
              <Stack pb={2}>
                <Typography variant="h4" gutterBottom>
                  {t('account.personal-information.date-birth')}
                </Typography>
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      type="date"
                      value={field.value} // Pass the raw date value directly
                    />
                  )}
                />
              </Stack>
              {/* Gender */}
              <Stack pb={2}>
                <Typography variant="h4" gutterBottom>
                  {t('account.personal-information.gender.title')}
                </Typography>
                <Controller
                  name="gender"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{
                        fontSize: '1.25rem',
                        backgroundColor: '#293040',
                        width: {
                          xs: '100%',
                          md: '50%'
                        },
                        '& .MuiSelect-select' : {
                          padding: 0,
                          paddingBottom: '0.438rem',
                          paddingTop: '0.438rem',
                          paddingLeft: '1rem',
                        }
                      }}
                    >
                      <MenuItem value="male">{t('account.personal-information.gender.male')}</MenuItem>
                      <MenuItem value="female">{t('account.personal-information.gender.female')}</MenuItem>
                      <MenuItem value="other">{t('account.personal-information.gender.other')}</MenuItem>
                      <MenuItem value="prefer_not_to_say">{t('account.personal-information.gender.no-say')}</MenuItem>
                    </Select>
                  )}
                />
              </Stack>
              <Stack pb={2}>
                <Typography variant="h4" gutterBottom>
                  {t('account.personal-information.screen-time')}
                </Typography>
                <Controller
                  name="screen_hours"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <CustomTextField type='number' range={{ min: 0, max: 24 }} {...field} />}
                />
              </Stack>
              {/* Professional athlete */}
              <Stack direction={'row'} alignItems="center" spacing={2} pb={2}>
                <Typography variant="h4">
                  {t('account.personal-information.professional-athlete')}
                </Typography>
                <Controller
                  name="professional_athlete"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="secondary"
                    />
                  )}
                />
              </Stack>
              {/* Sport */}
              {professionalAthlete && (
                <Stack pb={2}>
                  <Typography variant="h4" gutterBottom>
                    {t('account.personal-information.professional-athlete')}
                  </Typography>
                  <Controller
                    name="sport"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomTextField {...field} />
                    )}
                  />
                </Stack>
              )}
              {/* Research program */}
              <Stack direction={'row'} alignItems="center" spacing={2} pb={2}>
                <Typography variant="h4">
                  {t('account.personal-information.research-program')}
                </Typography>
                <Controller
                  name="research_program"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="secondary"
                    />
                  )}
                />
              </Stack>
              <Button variant="contained" color="secondary" sx={{ borderRadius: '5rem', maxWidth: '17.063rem' }} type="submit" disabled={loading}>
                {loading ? <CircularProgress size={21} /> : t('account.personal-information.button')}
              </Button>
            </FormControl>
          </form>
          <ChangePassword />
          {userInfo.admin ? <Admin /> : <Fragment />}
          {userInfo.clinician ? <Clinician email={userInfo.email} /> : <Fragment />}
        </Fragment>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="90vh"
        >
          <CircularProgress color="secondary" size={64} />
        </Box>
      )}
    </Box>
  );
};

export default Account;
