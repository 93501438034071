import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";

/*******************************************************/
/*************************Read**************************/
/*******************************************************/

export async function getUserDailyActivity(uid) {
  const q = query(collection(db, 'userDailyActivities'), where('user', '==', uid));
  const querySnapshot = await getDocs(q)

  return querySnapshot.docs[0].data()
}

/*******************************************************/
/***********************Update**************************/
/*******************************************************/

export async function updateUserDailyActivity(category, subCategory) {
  const auth = getAuth()

  onAuthStateChanged(auth, async(user) => {
    if (user) { // User is signed in
      // Get userDailyActivity
      const q = query(collection(db, 'userDailyActivities'), where('user', '==', user.uid))
      const querySnapshot = await getDocs(q)
      const userDailyActivity = querySnapshot.docs[0].data()

      const visionStamina =  userDailyActivity.vision_stamina
      const eyeSyncing =  userDailyActivity.eye_syncing
      const focusSpeed =  userDailyActivity.focus_speed

      switch(category) {
        case 'vision stamina':
          // Change game status
          if (subCategory === 'Divergence') { // Relax
            visionStamina.relax = true
          } else if(subCategory === 'Convergence') { // Flex
            visionStamina.flex = true
          } else { // Stamina Challenge
            visionStamina.stamina = true
          }

          // if already complete dont add +1 to total
          if(!visionStamina.completed) {
            visionStamina.total++
          }

          // Change completed status and add 
          if(visionStamina.relax && visionStamina.flex && visionStamina.stamina) {
            visionStamina.completed = true;
          }
          break;
        case 'eye syncing':
          // Change game status
          if (subCategory === 'chase') { // Chase
            eyeSyncing.chase = true
          } else if(subCategory === 'reflex') { // Reflex
            eyeSyncing.reflex = true
          }

          // if already complete dont add +1 to total
          if(!eyeSyncing.completed) {
            eyeSyncing.total++
          }
          // Change completed status and add 
          if(eyeSyncing.chase && eyeSyncing.reflex) {
            eyeSyncing.completed = true;
          }
          break;
        case 'focus speed':
          focusSpeed.astucia = true;
          // if already complete dont add +1 to total
          if(!focusSpeed.completed) {
            focusSpeed.total++
          }
          focusSpeed.completed = true;
          break;
        default:
          break;
      }

      // Check if adding counter to streak
      if(visionStamina.completed && eyeSyncing.completed && focusSpeed.completed) {
        userDailyActivity.streak++
        userDailyActivity.completed = true
      }

      await updateDoc(querySnapshot.docs[0].ref, userDailyActivity);
    }
  });
}