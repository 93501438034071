import { addDoc, collection, getDoc } from "firebase/firestore";
import { db } from "../firebase";

/**
 * Creates a new Document
 * @param colname name of the collection
 * @param data the document to save
 * @returns the new document stored
 */

export async function createOne(colname, data) {
  try {
    const colRef = collection(db, colname);
    const newDocRef = await addDoc(colRef, data)
    const newDoc = await getDoc(newDocRef)
    return { ...newDoc.data(), id: newDoc.id }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}