import React, { useState } from 'react'
import { Box, Typography, FormControl, TextField, Button, Link, useTheme, Alert } from '@mui/material'
import { useForm } from 'react-hook-form';
import { tokens } from '../../theme';
import { useAuth } from '../../contexts/AuthContext'
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Error message
  const [error, setError] = useState('');
  // Loading for signup function, not create multiple accounts at the same time
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const { resetPassword } = useAuth()

  
  // Form hook
  const { register, handleSubmit, formState: { errors } } = useForm();

  async function onSubmit(data,e)  {
    e.preventDefault();
    
    try{
      setMessage('')
      setError('')
      setLoading(true);
      await resetPassword(data.email)
      setMessage(t('reset-password.reset-message'))
    } catch {
      setError(t('reset-password.error'))
    }
    setLoading(false)

  }  

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
      width={{ xs: '90%', md: '20%'}}
      margin={'auto'}
    >
      <Logo width={200} height={200} />
      <Typography variant='h3' fontWeight={'bold'} pb={2} gutterBottom>{t('reset-password.reset')}</Typography>
      {error && <Alert sx={{ mb: 2, fontSize: '1rem' }} variant='filled' severity='error'  >{error}</Alert>}
      {message && <Alert sx={{ mb: 2, fontSize: '1rem' }} variant='filled' severity='success' color='secondary' >{message}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" method='post' style={{ width: '100%' }}>
        <FormControl fullWidth variant="outlined" sx={{ paddingBottom: 3 }}>
          <TextField {...register("email")} id="email" label="Email" type={'email'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
          
          <Button type='submit' variant='contained' color='secondary' size='large' sx={{ borderRadius: '1rem' }}>{t('reset-password.reset')}</Button>
        </FormControl>
      </form>
      <Link href='/login' underline='none' color={colors.greenAccent[500]} fontWeight={'bold'}>{t('login.login')}</Link>
      {/* <Link href='/signup' underline='none' color={colors.greenAccent[500]} fontWeight={'bold'}>Create Account</Link> */}
    </Box>
  )
}

export default ForgotPassword;