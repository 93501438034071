import { Modal, Box, Typography, useTheme, Button, Stack, Slider, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { ScreenCali_Engine } from '../../scripts/GameEngines';
import { tokens } from '../../theme';
import CalibrationSettings from '../../assets/CalibrationSettings.png';
import { useTranslation } from 'react-i18next';

export default function CalibrationModal({ open, setOpen, handleClose }) {
  const {t} =useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    window.Meta.Window(document.getElementById("MetaEngine"));
  }, []);

  const handleScreenCali = () => {
    window.Meta.Do(ScreenCali_Engine);
    setOpenColor(true);
    window.localStorage.setItem('screenCali', 'true');
  }
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'black',
    border: '2px solid',
    borderColor: colors.greenAccent[500],
    borderRadius: '1rem',
    boxShadow: 24,
    p: 4,
  };
  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height:'100%',
    bgcolor: 'black',
    p: 4,
  };

  // Color calibration modal
  const [openColor, setOpenColor] = useState(false);
  
  const handleCloseColor = () => {
    setOpenColor(false);
    setOpen(false);
    setStage(false);
  }

  const [red, setRed] = useState(200);
  const [blue, setBlue] = useState(200);

  const handleChangeRed = (event, newValue) => {
    setRed(newValue);
  };

  const handleChangeBlue = (event, newValue) => {
    setBlue(newValue);
  };
  const breakpoint = useMediaQuery(theme.breakpoints.down('xl'))

  const [stage, setStage] = useState(false) // false = red, true = blue

  const handleNext = () => {
    setStage(true);
  }

  const handleBackdropClick = () => {
    // Do nothing or add custom logic here
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Inital calibration"
      aria-describedby="Screen size and color calibration"
      BackdropProps={
        !window.localStorage.getItem('screenCali') && {
          onClick: handleBackdropClick,
        }
      }
    >
      <Box sx={style}>
        <Typography variant="h1" align='center' gutterBottom>
          {!window.localStorage.getItem('screenCali') ?
            t('calibration.title')
            :
            t('calibration.title2')
          }
        </Typography>
        <Stack gap={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={CalibrationSettings} alt='Calibration settings' height={250} style={{ margin: 10 }} />
          <Stack direction={'row'} spacing={7}>
            <Button onClick={handleScreenCali} variant='contained' color='secondary' size='large' sx={{ width: { md: '100%' }, fontWeight: 'bold' }}>{t('calibration.calibrate')}</Button>
          </Stack>
          {/* Color calibration */}
          <Modal
            open={openColor}
            onClose={handleCloseColor}
          >
            <Box sx={style2} display={'flex'} flexDirection={'column'} alignItems={'space-between'} gap={5} justifyContent={'center'}>
              <Typography variant="h1" align='center' component="h2" gutterBottom>
                {t('calibration.color-instruction-glassees')}
              </Typography>
              <Typography variant="h1" align='center' component="h2" gutterBottom>
                {t('calibration.color-instruction-red')}
              </Typography>
              {!stage ?
                <Stack alignItems={'center'} gap={7}>
                  <Button
                    variant='contained'
                    size='large'
                    sx={{
                      fontSize: '1.5rem',
                      backgroundColor: '#FFFFFF',
                      color: '#000000',
                      borderRadius: 0
                    }}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                  <Box
                    width={breakpoint  ? '300px':'450px'}
                    height={breakpoint  ? '200px':'275px'}
                    backgroundColor={'rgb(' + red + ' 0 0)'}
                  />
                  <Box width={breakpoint  ? '300px':'450px'} mx={'auto'}>
                    <Slider
                      sx={{
                        color: 'white',
                        '& .MuiSlider-thumb': {
                          borderRadius: '0',
                          color: 'black',
                          height: '0.625rem',
                          width: '1rem',
                          '&:hover, &:focus, &:active': {
                            boxShadow: 'none'
                          },
                        },
                        '& .MuiSlider-rail': {
                          height: '1rem', // Increase the height to make the track thicker
                          borderRadius: '0',
                          opacity: 1.0
                        },
                        '& .MuiSlider-track': {
                          height: '0.5rem', // Increase the height to make the track thicker
                          borderRadius: '0',
                          backgroundColor: 'white',
                          border: 'none'
                        },
                      }}
                      defaultValue={255}
                      min={0}
                      max={255}
                      onChange={handleChangeRed}
                    />
                  </Box>
                </Stack>
                :
                <Stack alignItems={'center'} gap={7}>
                  <Button
                    variant='contained'
                    size='large'
                    sx={{
                      fontSize: '1.5rem',
                      backgroundColor: '#FFFFFF',
                      color: '#000000',
                      borderRadius: 0
                    }}
                    onClick={handleCloseColor}
                  >
                    Finish
                  </Button>
                  <Box
                    width={breakpoint  ? '300px':'450px'}
                    height={breakpoint  ? '200px':'275px'}
                    backgroundColor={'rgb(0 0 ' + blue + ')'}
                  />
                  <Box width={breakpoint  ? '300px':'450px'} mx={'auto'}>
                    <Slider
                      sx={{
                        color: 'white',
                        '& .MuiSlider-thumb': {
                          borderRadius: '0',
                          color: 'black',
                          height: '0.625rem',
                          width: '1rem',
                          '&:hover, &:focus, &:active': {
                            boxShadow: 'none'
                          },
                        },
                        '& .MuiSlider-rail': {
                          height: '1rem', // Increase the height to make the track thicker
                          borderRadius: '0',
                          opacity: 1.0
                        },
                        '& .MuiSlider-track': {
                          height: '0.5rem', // Increase the height to make the track thicker
                          borderRadius: '0',
                          backgroundColor: 'white',
                          border: 'none'
                        },
                      }}
                      color={'secondary'}
                      defaultValue={255}
                      min={0}
                      max={255}
                      onChange={handleChangeBlue}
                    />
                  </Box>
                </Stack>
              }
            </Box>
          </Modal>
          <Typography id="modal-modal-title" variant="h5" component="h2" align='center' gutterBottom>
          {t('calibration.bottom-text')}
          </Typography>
        </Stack>
      </Box>
    </Modal>
  )
}