import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import GamepadIcon from '@mui/icons-material/Gamepad';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SummarizeIcon from '@mui/icons-material/Summarize';
import TuneIcon from '@mui/icons-material/Tune';

export const topData = [
  {
    title: "sidebar.home",
    href: "/",
    icon: <PeopleOutlinedIcon />
  },
  {
    title: "sidebar.performance",
    href: "/performance",
    icon: <SummarizeIcon />
  },
  {
    title: "sidebar.calibration",
   
    icon: <TuneIcon />
  }
]

export const middleData = [
  // {
  //   title: "sidebar.about",
  //   href: "/about",
  //   icon: <InfoIcon />
  // },
  {
    title: "sidebar.help",
    href: "https://www.notion.so/VisiPlayAI-Help-Center-1c3221c2c2ca43aab989a3b478aa80a9?pvs=4",
    icon: <HelpIcon />
  },
  {
    title: "sidebar.account",
    href: "/account",
    icon: <PersonOutlinedIcon />
  },
  {
    title: "sidebar.arcade",
    href: "/arcade",
    icon: <GamepadIcon />
  }
  
]

export const bottomData = [
  {
    title: "sidebar.logout",
    icon: <LogoutIcon />
  }
]