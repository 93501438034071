import React, { useState } from 'react'
import { Box, Typography, FormControl, TextField, Button, Alert, useTheme, Link, Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/AuthContext';
import { tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const Signup = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Error message
  const [error, setError] = useState('');
  // Loading for signup function, not create multiple accounts at the same time
  const [loading, setLoading] = useState(false);
  const { signup } = useAuth()
  const navigate = useNavigate()
  
  // Form hook
  const { register, handleSubmit, watch } = useForm();

  async function onSubmit(data,e)  {
    e.preventDefault();
    setLoading(true);
    
    if (watch('password') !== watch('verify-password')) { // Same password
      setLoading(false);
      return setError(t('signup.errors.mismatch-passwword'));
    }
    if (watch('password').length < 8) {
      setLoading(false)
      return setError('Password must be at least 8 characters long.');
    }
    if (!/[A-Z]/.test(watch('password'))) {
      setLoading(false)
      return setError('Password must contain at least one uppercase letter.');
    }
    if (!/[a-z]/.test(watch('password'))) {
      setLoading(false)
      return setError('Password must contain at least one lowercase letter.');
    }
    if (!/[0-9]/.test(watch('password'))) {
      setLoading(false)
      return setError('Password must contain at least one digit.');
    }
    if (!watch('tos')) { // Accept Terms of Service
      setLoading(false);
      return setError(t('signup.errors.tos'))
    }
    // Promo code avaliable
    const api = "https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/isActive/" + data.promoCode;
    try {
      const res = await fetch(api, { mode: 'cors' });
      const data = await res.json();
      if(!data.isActive || data.isActive === undefined) {
        setLoading(false);
        return setError('Promo code is not active');
      }
    } catch (error) {
      setLoading(false);
      setError(t('signup.errors.unknown'));
    }

    try{
      setError('');
      await signup(data.email, data.password, false);
      const url = "https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/redeem/" + data.promoCode;
      await fetch(url, { mode: 'cors' });
      toast.success(t('signup.success'), {
        position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      navigate('/login');
    } catch (e) {
      setLoading(false);
      setError(t('signup.errors.unknown'));
    }
    setLoading(false)
  }

  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [termsRead, setTermsRead] = useState(false);
  const contentRef = useRef(null);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleScroll = () => {
    if (
      contentRef.current.scrollTop + contentRef.current.clientHeight >=
      contentRef.current.scrollHeight - 10
    ) {
      setScrolled(true);
    }
  };

  const handleCheckboxChange = () => {
    if (scrolled) {
      setTermsRead(!termsRead);
      handleCloseModal();
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
      width={{ xs: '90%', md: '50%', lg: '25%'}}
      margin={'auto'}
    >
      <Typography variant='h2' fontWeight={'bold'} pb={2} gutterBottom>{t('signup.signup')}</Typography>
      {error && <Alert sx={{ mb: 3, fontSize: '1rem' }} variant='filled' severity='error' color='secondary' >{error}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" method='post' style={{ width: '100%' }}>
        <FormControl fullWidth variant="outlined" sx={{ paddingBottom: 3 }}>
          <TextField {...register('email')} id="email" label="Email" type={'email'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
          <TextField {...register('password')} id="password" label={t('login.password')} type={'password'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
          <TextField {...register('verify-password', {required: true,})} id="verify-password" label={t('signup.verify-password')} type={'password'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
          <TextField {...register('promoCode')} id="promo-code" label={t('signup.promo-code')} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} />
          <Button variant="contained" color="info" onClick={handleOpenModal} sx={{ m: 'auto', mb: 3, borderRadius: '1rem' }} >
            Accept Terms of Service
          </Button>
          <Dialog
            open={open}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle sx={{ backgroundColor: colors.greenAccent[700], fontSize: '1.25rem' }}>Terms of Service</DialogTitle>
            <DialogContent dividers ref={contentRef} onScroll={handleScroll} sx={{ backgroundColor: colors.grey[900] }}>
              <Typography variant='h5' gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                pulvinar finibus lacus, in rutrum lorem finibus ut. Donec non
                tortor at nunc venenatis mattis at sit amet ante. Nulla facilisi.
                In vel diam et tortor fringilla feugiat. Aliquam facilisis ligula
                ut ex ultricies facilisis. Phasellus quis ultricies orci, in
                consequat velit. Sed fringilla libero id sem rutrum, in hendrerit
                purus hendrerit. Morbi sodales scelerisque feugiat.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Sed at tellus eu ligula commodo finibus. Duis nec nunc in nulla
                interdum finibus. Maecenas sed sapien ex. Morbi auctor augue id
                tristique semper. Aliquam non fringilla odio. Sed aliquet pretium
                erat, sed efficitur odio elementum a. Proin sit amet dolor nec
                felis gravida efficitur. Mauris id nisl quam. Suspendisse in ipsum
                sit amet velit dignissim laoreet.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                pulvinar finibus lacus, in rutrum lorem finibus ut. Donec non
                tortor at nunc venenatis mattis at sit amet ante. Nulla facilisi.
                In vel diam et tortor fringilla feugiat. Aliquam facilisis ligula
                ut ex ultricies facilisis. Phasellus quis ultricies orci, in
                consequat velit. Sed fringilla libero id sem rutrum, in hendrerit
                purus hendrerit. Morbi sodales scelerisque feugiat.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Sed at tellus eu ligula commodo finibus. Duis nec nunc in nulla
                interdum finibus. Maecenas sed sapien ex. Morbi auctor augue id
                tristique semper. Aliquam non fringilla odio. Sed aliquet pretium
                erat, sed efficitur odio elementum a. Proin sit amet dolor nec
                felis gravida efficitur. Mauris id nisl quam. Suspendisse in ipsum
                sit amet velit dignissim laoreet.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                pulvinar finibus lacus, in rutrum lorem finibus ut. Donec non
                tortor at nunc venenatis mattis at sit amet ante. Nulla facilisi.
                In vel diam et tortor fringilla feugiat. Aliquam facilisis ligula
                ut ex ultricies facilisis. Phasellus quis ultricies orci, in
                consequat velit. Sed fringilla libero id sem rutrum, in hendrerit
                purus hendrerit. Morbi sodales scelerisque feugiat.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Sed at tellus eu ligula commodo finibus. Duis nec nunc in nulla
                interdum finibus. Maecenas sed sapien ex. Morbi auctor augue id
                tristique semper. Aliquam non fringilla odio. Sed aliquet pretium
                erat, sed efficitur odio elementum a. Proin sit amet dolor nec
                felis gravida efficitur. Mauris id nisl quam. Suspendisse in ipsum
                sit amet velit dignissim laoreet.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                pulvinar finibus lacus, in rutrum lorem finibus ut. Donec non
                tortor at nunc venenatis mattis at sit amet ante. Nulla facilisi.
                In vel diam et tortor fringilla feugiat. Aliquam facilisis ligula
                ut ex ultricies facilisis. Phasellus quis ultricies orci, in
                consequat velit. Sed fringilla libero id sem rutrum, in hendrerit
                purus hendrerit. Morbi sodales scelerisque feugiat.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Sed at tellus eu ligula commodo finibus. Duis nec nunc in nulla
                interdum finibus. Maecenas sed sapien ex. Morbi auctor augue id
                tristique semper. Aliquam non fringilla odio. Sed aliquet pretium
                erat, sed efficitur odio elementum a. Proin sit amet dolor nec
                felis gravida efficitur. Mauris id nisl quam. Suspendisse in ipsum
                sit amet velit dignissim laoreet.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                pulvinar finibus lacus, in rutrum lorem finibus ut. Donec non
                tortor at nunc venenatis mattis at sit amet ante. Nulla facilisi.
                In vel diam et tortor fringilla feugiat. Aliquam facilisis ligula
                ut ex ultricies facilisis. Phasellus quis ultricies orci, in
                consequat velit. Sed fringilla libero id sem rutrum, in hendrerit
                purus hendrerit. Morbi sodales scelerisque feugiat.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Sed at tellus eu ligula commodo finibus. Duis nec nunc in nulla
                interdum finibus. Maecenas sed sapien ex. Morbi auctor augue id
                tristique semper. Aliquam non fringilla odio. Sed aliquet pretium
                erat, sed efficitur odio elementum a. Proin sit amet dolor nec
                felis gravida efficitur. Mauris id nisl quam. Suspendisse in ipsum
                sit amet velit dignissim laoreet.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                pulvinar finibus lacus, in rutrum lorem finibus ut. Donec non
                tortor at nunc venenatis mattis at sit amet ante. Nulla facilisi.
                In vel diam et tortor fringilla feugiat. Aliquam facilisis ligula
                ut ex ultricies facilisis. Phasellus quis ultricies orci, in
                consequat velit. Sed fringilla libero id sem rutrum, in hendrerit
                purus hendrerit. Morbi sodales scelerisque feugiat.
              </Typography>
              <Typography variant='h5' gutterBottom>
                Sed at tellus eu ligula commodo finibus. Duis nec nunc in nulla
                interdum finibus. Maecenas sed sapien ex. Morbi auctor augue id
                tristique semper. Aliquam non fringilla odio. Sed aliquet pretium
                erat, sed efficitur odio elementum a. Proin sit amet dolor nec
                felis gravida efficitur. Mauris id nisl quam. Suspendisse in ipsum
                sit amet velit dignissim laoreet.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                  {...register('tos')}
                    checked={termsRead}
                    onChange={handleCheckboxChange}
                    color="secondary"
                    required
                  />
                }
                label="I have read and agree to the terms of service"
              />
              <Button onClick={handleCloseModal} variant='outlined' color="secondary">
                Close
              </Button>
            </DialogContent>
          </Dialog>
          <Button disabled={loading} type='submit' variant='contained' color='secondary' sx={{ borderRadius: '1rem' }}>
            {loading?
            <CircularProgress size={23} />
            :
            t('signup.signup')
            }
          </Button>
        </FormControl>
      </form>
      <Typography gutterBottom>{t('signup.already-have-account')} <Link href='/login' underline='none' color={colors.greenAccent[500]} fontWeight={'bold'}>{t('login.login')}</Link></Typography>
    </Box>
  )
}

export default Signup;