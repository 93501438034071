import { Alert, Button, CircularProgress, FormControl, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {
  const { t } = useTranslation();
  // User
  const { updatePassword, reauthenticate } = useAuth();
  // Form
  const { handleSubmit, watch, register } = useForm();
  const [loading, setLoading] = useState(false);
  // Error message
  const [error, setError] = useState('');

  const changePassword = async (data) => {
    if (watch('newPassword') !== watch('confirmPassword')) {
      return setError('Passwords do not match');
    }
    if (watch('newPassword').length < 8) {
      return setError('Password must be at least 8 characters long.');
    }
    if (!/[A-Z]/.test(watch('newPassword'))) {
      return setError('Password must contain at least one uppercase letter.');
    }
    if (!/[a-z]/.test(watch('newPassword'))) {
      return setError('Password must contain at least one lowercase letter.');
    }
    if (!/[0-9]/.test(watch('newPassword'))) {
      return setError('Password must contain at least one digit.');
    }

    try {
      setError('');
      setLoading(true);
      
      // Reauthenticate the user before updating the password
      await reauthenticate(data.currentPassword);
      await updatePassword(data.newPassword);

      setLoading(false);
      // Optionally, you can display a success message or redirect the user
    } catch (error) {
      setError('Failed to update password');
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(changePassword)} method='PUT' autoComplete='off'>
      <FormControl>
        <Stack pb={2}>
        <Typography variant="h4" gutterBottom>{t('account.password.current-pass')}</Typography>
        <TextField
          name="currentPassword" type='password' {...register("currentPassword")}
          color='secondary'
          variant='outlined'
          sx={{
            backgroundColor: '#293040',
            borderRadius: '1rem',
            maxWidth: '17.063rem',
            '& .MuiOutlinedInput-root': {
              borderRadius: '1rem',
            },
            '& .MuiOutlinedInput-input': {
              paddingBottom: '0.313rem',
              paddingTop: '0.313rem',
              paddingLeft: '1rem',
              fontSize: '1.25rem'
            }
          }}
        />
        </Stack>
        <Stack direction={'row'} spacing={5} pb={2}>
          <Stack>
            <Typography variant="h4" gutterBottom>{t('account.password.new-pass')}</Typography>
            <TextField
              name="newPassword" type='password' {...register("newPassword")}
              color='secondary'
              variant='outlined'
              sx={{
                backgroundColor: '#293040',
                borderRadius: '1rem',
                maxWidth: '17.063rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '1rem',
                },
                '& .MuiOutlinedInput-input': {
                  paddingBottom: '0.313rem',
                  paddingTop: '0.313rem',
                  paddingLeft: '1rem',
                  fontSize: '1.25rem'
                }
              }}
            />
          </Stack>
          <Stack>
            <Typography variant="h4" gutterBottom>{t('account.password.confirm-pass')}</Typography>
            <TextField
              name="confirmPassword" type='password' {...register("confirmPassword")}
              color='secondary'
              variant='outlined'
              sx={{
                backgroundColor: '#293040',
                borderRadius: '1rem',
                maxWidth: '17.063rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '1rem',
                },
                '& .MuiOutlinedInput-input': {
                  paddingBottom: '0.313rem',
                  paddingTop: '0.313rem',
                  paddingLeft: '1rem',
                  fontSize: '1.25rem'
                }
              }}
            />
          </Stack>
        </Stack>
        <Button variant="contained" color="secondary" sx={{ borderRadius: '5rem', maxWidth: '17.063rem', mb: 2 }} type="submit" disabled={loading}>
          {loading ? <CircularProgress size={21} /> : t('account.password.button')}
        </Button>
        {error && <Alert sx={{ fontSize: '1rem', mb: 2, maxWidth: '17.063rem' }} variant='filled' severity='error' color='error' >{error}</Alert>}
      </FormControl>
    </form>
  )
}