import { Divider, Typography, Stack, Grid, Chip, Button, useTheme, Autocomplete, TextField, Box, CircularProgress, Alert } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { tokens } from '../../theme';
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../components/CustomFieldText";
import { useCallback } from "react";

function Admin() {
  const { t } = useTranslation();
  // Styles
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // User
  const { currentUser } = useAuth();
  // Codes
  const [quantity, setQuantity] = useState(1);
  const [clinicianEmail, setClinicianEmail] = useState(null);
  const [activeCodes, setActiveCodes] = useState([]);
  const [usedCodes, setUsedCodes] = useState([]);
  
  const [clinicians, setClinicians] = useState([]);

  const generateCodes = async() => {
    if (!clinicianEmail) {
      console.log('clinican email needed')
      return 
    }
    if(quantity > 0){
      const api = "https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/create";
      try {
        await fetch(api, {
          mode: 'cors',
          method: 'POST',
          body: JSON.stringify({
            "quantity": quantity,
            "clinicianEmail": clinicianEmail
          }),
          headers: {
            'Content-Type': 'application/json' // Specify the content type as JSON
          },
        });
        fetchPromoCodes();
      } catch (error) {
        
      }
    } else {
      // Add error alert
    }
  }

  const [email, setEmail] = useState();
  const [loadingClinician, setLoadingClinician] = useState(false);
  const [clinicianError, setClinicianError] = useState(false);
  const [clinicianSuccess, setClinicianSuccess] = useState(false);

  const addClinician = async() => {
    setLoadingClinician(true);
    try {
      // const url = 'http://localhost:5000/visi-play/us-central1/app/api/users/clinician';
      const url = 'https://us-central1-visi-play.cloudfunctions.net/app/api/users/clinician';

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ email: email }),
        headers: {
          'Content-Type': 'application/json' // Specify the content type as JSON
        },
      })

      if (!response.ok) {
        // Check if the response status is not in the 2xx range (e.g., 404 or 500)
        throw new Error('Failed to add clinician: ' + response.status);
      }

      setLoadingClinician(false);
      setClinicianError(false);
      setClinicianSuccess(true);
    } catch (error) {
      console.error('Error registering user:', error.code);
      setClinicianError(true);
      setClinicianSuccess(false);
      setLoadingClinician(false);
    }
  }

  const fetchPromoCodes = useCallback(async () => {
    try {
      const url = `https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/active/${clinicianEmail}`;
  
      const res = await fetch(url, { mode: 'cors' });
      if (!res.ok) {
        throw new Error(`Failed to fetch promo codes. Status: ${res.status}`);
      }
  
      const data = await res.json();
      setActiveCodes(data);
  
      // Fetch used codes
      const usedUrl = `https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/used/${clinicianEmail}`;
      const usedRes = await fetch(usedUrl, { mode: 'cors' });
      if (!usedRes.ok) {
        throw new Error(`Failed to fetch used promo codes. Status: ${usedRes.status}`);
      }
  
      const usedData = await usedRes.json();
      setUsedCodes(usedData);
    } catch (error) {
      console.log('Error retrieving promo codes:', error);
    }
  }, [clinicianEmail]);

  useEffect(() => {
    fetchClinicians();
    fetchPromoCodes();
  }, [currentUser.uid, fetchPromoCodes]);

  useEffect(() => {
    if (clinicianEmail) {
      fetchPromoCodes();
    }
  }, [clinicianEmail, fetchPromoCodes]);

  const fetchClinicians = async() => {
    try {
      const url = "https://us-central1-visi-play.cloudfunctions.net/app/api/users/clinician"
      const res = await fetch(url, { mode: 'cors' });
      let data = await res.json();
      setClinicians(data);
    } catch (error) {
      
    }
  }

  return (
    <Fragment>
      <Divider />
      <Typography variant='h2' gutterBottom pt={3}>{t('account.admin.title')}</Typography>
      <Typography variant='h3' gutterBottom pb={2}>{t('account.admin.add-clinician')}</Typography>
      <Stack direction={'row'} spacing={5} pb={2}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ borderRadius: '5rem', width: '9rem' }}
          onClick={addClinician}
          disabled={loadingClinician}
        >
          {loadingClinician?
            <CircularProgress size={21} />
            :
            t('account.admin.clinician-button')
          }
        </Button>
        <CustomTextField
          type="email" 
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder={'email@example.com'}
        />
      </Stack>
      {clinicianError?
        <Alert sx={{ fontSize: '1rem' }} variant="filled" severity="error">{t('account.clinician.error')}</Alert>
        :
        <Fragment />
      }
      {clinicianSuccess?
        <Alert sx={{ fontSize: '1rem' }} variant="filled" severity="success">{t('account.clinician.success')}</Alert>
        :
        <Fragment />
      }
      {/* Geenerate codes section */}
      <Typography variant='h3' gutterBottom py={2}>{t('account.admin.codes-title')}</Typography>
      <Stack direction={'row'} spacing={5} pb={2}>
        <Stack>
          <Typography variant="h4" gutterBottom>{t('account.admin.codes-quantity')}</Typography>
          <CustomTextField
            type="number" 
            onChange={(e) => {
              setQuantity(e.target.value)
            }}
            defaultValue={quantity}
          />
        </Stack>
        <Stack>
          <Typography variant="h4" gutterBottom>{t('account.admin.codes-clinician-email')}</Typography>
          <Autocomplete
            sx={{ 
              '& .MuiOutlinedInput-input': {
                paddingTop: '0 !important',
              }
            }}
            options={clinicians}
            value={clinicianEmail}
            onChange={(e, value) =>{
              setClinicianEmail(value);
              fetchPromoCodes();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                type="email"
                sx={{
                  '& .MuiAutocomplete-inputRoot': {
                    height: '2.5rem',
                    width: '20rem',
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: 0,
                    paddingBottom: '0.25rem', // Adjust this value to vertically center the text
                    paddingTop: '0.25rem', // Adjust this value to vertically center the text
                    paddingLeft: '1rem',
                    fontSize: '1.188rem',
                    color: '#ffffff',
                    overflow: 'hidden'
                  },
                  '& .MuiInputLabel-root': {
                    color: '#ffffff',
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#293040',
                    borderRadius: '1rem',
                    '& fieldset': {
                      borderRadius: '1rem',
                    },
                  },
                }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Button
        variant='contained'
        color='secondary'
        pb={2}
        sx={{ borderRadius: '5rem', width: '9rem' }}
        onClick={generateCodes}
      >{t('account.admin.codes-button')}</Button>
      {/* Codes display */}
      <Grid container spacing={2} py={2}>
        <Grid item xs={12} md={6}>
          <Typography variant='h3' gutterBottom>{t('account.admin.active')}</Typography>
          <Box
            height={400}
            overflow={'auto'}
            backgroundColor={colors.greenAccent[800]}
            borderRadius={2}
          >
            <Grid container p={0.5} >
              {activeCodes.map((promoCode, index) => {
                return(
                  <Grid item xs={12} key={index} display={'flex'} justifyContent={'center'} py={1}>
                    <Chip label={promoCode} sx={{ width: '95%', fontSize: '1rem' }} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
        <Typography variant='h3' gutterBottom>{t('account.admin.used')}</Typography>
          <Box
            height={400}
            overflow={'auto'}
            backgroundColor={colors.redAccent[800]}
            borderRadius={2}
          >
            <Grid container p={0.5} >
              {usedCodes.map((promoCode, index) => {
                return(
                  <Grid item xs={12} key={index} display={'flex'} justifyContent={'center'} py={1}>
                    <Chip label={promoCode} sx={{ width: '95%', fontSize: '1rem' }} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Admin;
