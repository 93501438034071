import { Modal, useTheme, Box, Stack, Typography, Button, CircularProgress, useMediaQuery } from '@mui/material'
import React, { Fragment } from 'react';
import { tokens } from "../../theme";
import GaugeChart from '../../components/GaugeChart';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';


export default function FinishModal(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    window.localStorage.setItem('finishScreen', 'false');
    setOpen(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '90vh',
    bgcolor: colors.primary[500],
    border: '2px solid',
    borderColor: colors.greenAccent[500],
    borderRadius: '1rem',
    boxShadow: 24,
    p: 4,
  };

  const [chartData, setChartData] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchData() {
      const fbData = props.fbData;

      const api = "https://us-central1-visi-play.cloudfunctions.net/app/api/userPlayedGames/finish-game-screen/"
      // const api = "http://localhost:5000/visi-play/us-central1/app/api/userPlayedGames/finish-game-screen/"
      
      const uid = currentUser.uid;
      const category =  fbData.category.replace(/\s+/g, '%20');;
      const subcategory =  fbData.subcategory || "%20";
      const game = fbData.game;
      const url = api + uid + "/" + category + "/" + subcategory + "/" + game;
      console.log(url);
      try {
        const res = await fetch(url, { mode: 'cors'});
        const data = await res.json();
        let gaugeData = [];

        let game;

        switch (data.last_game.category) {
          case 'Vision Stamina':
            game = [
              { // Last game score
                value: data.last_game.score,
                name: 'Score',
                title: {
                  offsetCenter: ['-50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['-50%', '100%']
                },
                pointercolor:'#8e2699'
              },
              { // Highscore
                value: data.high_score[0].score,
                name: 'Max Score',
                title: {
                  offsetCenter: ['50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['50%', '100%']
                }
              }
            ];
            gaugeData.push(game);
            setChartData(gaugeData);
            break;
          case 'Eye Syncing':
            game = [
              { // Last game score
                value: data.last_game.score_l,
                name: 'Score',
                title: {
                  offsetCenter: ['-50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['-50%', '100%']
                },
                pointercolor:'#8e2699'
              },
              { // Highscore
                value: data.high_score[0].score_l,
                name: 'Max Score',
                title: {
                  offsetCenter: ['50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['50%', '100%']
                }
              }
            ];
            gaugeData.push(game);
            game = [
              { // Last game score
                value: data.last_game.score_r,
                name: 'Score',
                title: {
                  offsetCenter: ['-50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['-50%', '100%']
                },
                pointercolor:'#8e2699'
              },
              { // Highscore
                value: data.high_score[1].score_r,
                name: 'Max Score',
                title: {
                  offsetCenter: ['50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['50%', '100%']
                }
              }
            ];
            gaugeData.push(game);
            setChartData(gaugeData);
            break;
          case 'Focus Speed':
            game = [
              { // Last game score
                value: parseFloat(data.last_game.avg_hit_time_b.toFixed(3)),
                name: 'Score',
                title: {
                  offsetCenter: ['-50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['-50%', '110%']
                },
                pointercolor:'#8e2699'
              },
              { // Highscore
                value: parseFloat(data.high_score[2].avg_hit_time_b.toFixed(3)),
                name: 'Max Score',
                title: {
                  offsetCenter: ['50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['50%', '110%']
                }
              }
            ];
            gaugeData.push(game);
            game = [
              { // Last game score
                value: parseFloat(data.last_game.avg_hit_time_l.toFixed(3)),
                name: 'Score',
                title: {
                  offsetCenter: ['-50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['-50%', '110%']
                },
                pointercolor:'#8e2699'
              },
              { // Highscore
                value: parseFloat(data.high_score[0].avg_hit_time_l.toFixed(3)),
                name: 'Max Score',
                title: {
                  offsetCenter: ['50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['50%', '110%']
                }
              }
            ];
            gaugeData.push(game);
            game = [
              { // Last game score
                value: parseFloat(data.last_game.avg_hit_time_r.toFixed(3)),
                name: 'Score',
                title: {
                  offsetCenter: ['-50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['-50%', '110%']
                },
                pointercolor:'#8e2699'
              },
              { // Highscore
                value: parseFloat(data.high_score[1].avg_hit_time_r.toFixed(3)),
                name: 'Max Score',
                title: {
                  offsetCenter: ['50%', '85%'],
                  color: '#fefefe'
                },
                detail: {
                  offsetCenter: ['50%', '110%']
                }
              }
            ];
            gaugeData.push(game);
            setChartData(gaugeData);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [currentUser.uid, props.fbData]);

  const handleHome = () => {
    window.localStorage.setItem('finishScreen', 'false');
    navigate('/');
  }

  const handleNext = () => {
    window.localStorage.setItem('finishScreen', 'false');
    handleClose();
    navigate(props.next);
  }
  const breakpoint = useMediaQuery(theme.breakpoints.down('xl'))


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        { chartData ? (
          chartData.length === 1 ? (
            <Stack direction={'column'} mx={'auto'}>
              <Typography variant='h2' align={'center'} gutterBottom>Score</Typography>
              <Box >
              <GaugeChart chartData={chartData[0]} chartHeight={breakpoint  ? '350px':'450px'} inverse={false} />
              </Box>
            </Stack>
          ) : chartData.length === 2 ? (
            <Box>
              <Typography variant='h1' align={'center'} pb={3} gutterBottom>Score</Typography>
              <Stack direction={'row'} justifyContent={'space-around'} height={'100%'} alignItems={'center'}>
                <Stack width={'100%'}>
                  <Typography variant='h2' align={'center'} gutterBottom>Left eye</Typography>
                  <GaugeChart chartData={chartData[0]} chartHeight={breakpoint  ? '400px':'550px'} />
                </Stack>
                <Stack width={'100%'}>
                  <Typography variant='h2' align={'center'} gutterBottom>Right eye</Typography>
                  <GaugeChart chartData={chartData[1]} chartHeight={breakpoint  ? '400px':'550px'} />
                </Stack>
              </Stack>
            </Box>
          ) : chartData.length === 3 ? (
            <Box>
              <Typography variant='h1' align={'center'} pb={3} gutterBottom>Score</Typography>
              <Stack direction={'row'} justifyContent={'space-around'} height={'100%'} alignItems={'center'}>
                <Stack width={'100%'}>
                  <Typography variant='h2' align={'center'} gutterBottom>Left eye</Typography>
                  <GaugeChart chartData={chartData[1]} chartHeight={breakpoint  ? '400px':'550px'} inverse={true} />
                </Stack>
                <Stack width={'100%'}>
                  <Typography variant='h2' align={'center'} gutterBottom>Right eye</Typography>
                  <GaugeChart chartData={chartData[2]} chartHeight={breakpoint  ? '400px':'550px'} inverse={true} />
                </Stack>
              </Stack>
            </Box>
            // <Fragment alignItems={'center'}>
            //   <Typography variant='h1' align={'center'} gutterBottom>Reaction time</Typography>
            //   <Stack mx={'auto'}>
            //     <Typography variant='h2' align={'center'} >Both eyes score</Typography>
            //     <GaugeChart chartData={chartData[0]} chartHeight={breakpoint  ? '200px':'280px'} inverse={true} />
            //   </Stack>
            //   <Stack direction={'row'} mx={'auto'}>
            //     <Stack width={'100%'}>
            //       <Typography variant='h2' align={'center'} >Left eye score</Typography>
            //       <GaugeChart chartData={chartData[1]} chartHeight={breakpoint  ? '200px':'280px'} inverse={true} />
            //     </Stack>
            //     <Stack width={'100%'}>
            //       <Typography variant='h2' align={'center'} >Right eye score</Typography>
            //       <GaugeChart chartData={chartData[2]} chartHeight={breakpoint  ? '200px':'280px'} inverse={true} />
            //     </Stack>
            //   </Stack>
            // </Fragment>
          ) : <Fragment />
        )
        :
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress color="secondary" size={64} />
        </Box>
        }
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, py: 4 }}>
          <Stack flexDirection={'row'} justifyContent={'space-around'}>
            <Button onClick={handleHome} variant='contained' color='secondary'>Home</Button>
            <Button onClick={props.launchGame} variant='contained' color='secondary'>Retry Game</Button>
            <Button onClick={handleNext} variant='contained' color='secondary'>Next Game</Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}
