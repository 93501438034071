import { Box, Button, CircularProgress, Grid, Link, Stack, Typography } from "@mui/material";
import { gameData } from "../../data/homeData";
import HeaderCard from "../../components/HeaderCard";
import GameCard from "../../components/GameCard";
import { getAuth } from "firebase/auth";
import { getUserDailyActivity } from "../../firebase/userDailyActivities";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import CalibrationModal from "./CalibrationModal";
import bg from '../../assets/Smooth_background_blur_1.jpg';
import { useTranslation } from "react-i18next";

const Home = () => {
  const { currentUser } = getAuth()
  const [completedGames, setCompletedGames] = useState([])
  const [completedSectionProgress, setCompletedSectionProgress] = useState([])
  const [dailyCompleted, setDailyCompleted] = useState(0);

  // Color border
  const firstNotPlayedRef = useRef([8, 8]);

  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const daily = await getUserDailyActivity(currentUser.uid);
  
        // Game type complete
        const completedGameType = [];
        let relax = false;
        let flex = false;
        let stamina = false;
        let chase = false;
        let reflex = false;
        let astucia = false;
        // Daily games complete per section
        const completedSectionProgress = [];
        let visionStaminaDailyNum = 0;
        let eyeSyncingDailyNum = 0;
        let focusSpeedDailyNum = 0;
        // Vision Stamina
        if (daily.vision_stamina.relax) {
          relax = true;
          visionStaminaDailyNum++;
        }
        if (daily.vision_stamina.flex) {
          flex = true;
          visionStaminaDailyNum++;
        }
        if (daily.vision_stamina.stamina) {
          stamina = true;
          visionStaminaDailyNum++;
        }
        // Eye Syncing
        if (daily.eye_syncing.chase) {
          chase = true;
          eyeSyncingDailyNum++;
        }
        if (daily.eye_syncing.reflex) {
          reflex = true;
          eyeSyncingDailyNum++;
        }
        // Focus Speed
        if (daily.focus_speed.astucia) {
          astucia = true;
          focusSpeedDailyNum++;
        }
  
        completedGameType.push([relax, flex, stamina]);
        completedGameType.push([chase, reflex]);
        completedGameType.push([astucia]);
        setCompletedGames(completedGameType);
  
        completedSectionProgress.push(visionStaminaDailyNum);
        completedSectionProgress.push(eyeSyncingDailyNum);
        completedSectionProgress.push(focusSpeedDailyNum);
        setCompletedSectionProgress(completedSectionProgress);
  
        let sum = 0;
        completedSectionProgress.forEach((section) => {
          sum += section;
        });
        setDailyCompleted(sum);

        let flag = false;
  
        completedGameType.forEach((category, x) => {
          category.forEach((subcategory, y) => {
            if (!completedGameType[x][y] && !flag) {
              flag = true;
              firstNotPlayedRef.current = [x, y];
            }
          });
        });
  
        window.Meta.Window(document.getElementById("MetaEngine"));
      } catch (error) {
        // Handle error here
        console.log(error);
      }
    };
  
    fetchData();
  }, [currentUser.uid]);

  const { t } = useTranslation();

  const [openCaliModal, setOpenCaliModal] = useState(true);
  const handleCloseCali = () => setOpenCaliModal(false);

  return(
    <Box className="tour-2" sx={{ width: { xs: '95%', md: '90%' } }} mx={'auto'} py={1}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h4" gutterBottom>{ t('home.daily-routine') }</Typography>
        <Typography variant="h4">{dailyCompleted}{t('home.of6')}</Typography>
      </Stack>
      {!window.localStorage.getItem('screenCali') && !window.localStorage.getItem('colorCali') ?
        <CalibrationModal open={openCaliModal} setOpen={setOpenCaliModal} handleClose={handleCloseCali} />
        :
        <Fragment />
      }
      {completedSectionProgress.length > 0  ?
        gameData.map((activity, x) => {
          const num = completedSectionProgress[x]
          return(
            <Grid container
              key={x}
              borderRadius={3}
              my={1}
              sx={{
                backgroundImage: `url(${bg})`
              }}>
              {/* First element, header category */}
              <HeaderCard activity={activity} activityDailyNum={num} />
              {activity.types.map((type, y) => {
                let isNext = false;
                if(firstNotPlayedRef.current[0] === x && firstNotPlayedRef.current[1] === y) {
                  isNext = true;
                }
                return(
                  <GameCard type={type} played={completedGames[x][y]} next={isNext} key={y} />
                )
              })}
              {x === 2 ?
              <Grid item xs={12} lg={3} px={1}>
                <Box
                  height={240}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  mt={'16px'}
                >
                  <Typography variant="h4" align="center" gutterBottom pb={4}>{t('home.info-message')}</Typography>
                  <Button LinkComponent={Link} target="_blank" variant="contained" color="secondary" href={i18n.language === "en" ? "https://jagged-aardwolf-5e8.notion.site/VisiPlayAI-Help-Center-1c3221c2c2ca43aab989a3b478aa80a9" : "https://jagged-aardwolf-5e8.notion.site/VisiPlayAI-Centro-de-Ayuda-Espa-ol-be31b8fb1279482c933d79f19ba23c72"}>{t('home.more-info')}</Button>
                </Box>
              </Grid>
              :
              <Fragment />
              }
            </Grid>
            )
        })
        :
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="90vh"
        >
          <CircularProgress color="secondary" size={64} />
        </Box>
      }
    </Box>
  );
}

export default Home;