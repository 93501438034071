import React from "react";
import JoyRide, {STATUS} from "react-joyride";
const TOUR_STEPS = [
  {
    target: ".sidebar-tour",
    content: "Sidebar",
    disableBeacon: true,
    placement: 'right'
  },
  {
    target: ".tour-2",
    content: "Game sections",
    placement: 'center',
    disableBeacon: true,
  },
  {
    target: ".tour-3",
    content: "Check daily progress",
    placement: 'bottom',
    disableBeacon: true,
  },
];

const Tour = () => {
  return (
    <>
      <JoyRide
        steps={TOUR_STEPS}
        continuous={true}
        callback={({ status }) => {
          if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
            window.localStorage.setItem('tutorialPassed', 'true');
          }
        }}
        styles={{
          tooltipContainer: {
            textAlign: "left"
          },
          buttonNext: {
            backgroundColor: "green"
          },
          buttonBack: {
            marginRight: 10
          }
        }}
      />
    </>
  );
};
export default Tour;

// https://www.smashingmagazine.com/2020/08/guide-product-tours-react-apps/