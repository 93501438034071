import { Box, Grid, Typography, Stack, Button, useTheme } from "@mui/material";
import bg from '../assets/Smooth_background_blur_1.jpg';
import { tokens } from '../theme';
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

const GameCard = (props) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const { type, played, next } = props;

  /* Games mapping */
  return(
    <Grid item xs={12} lg={3} px={1}>
      <Box
        height={240}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={3}
        mt={'16px'}
        boxShadow={10}
        border={next? '2px solid #ccbf10' : ''}
        sx={{
          backgroundImage: `url(${bg})`,
          filter: played ? 'grayscale(100%)' : '',
          backgroundPosition: '-15rem -40rem',
          "&:hover": {
            "& > .on": {display: 'none'},
            "& > .off": {display: 'flex'}
          }
        }}
      >
        <Stack className="on" spacing={0.75}>
          {type.img}
          <Typography color={'#fafafa'} variant='h3' align="center" gutterBottom>{ t(type.name) }</Typography>
        </Stack>
        <Stack className="off" spacing={2.5} display={'none'}>
          {type.games.length > 1 ?
            <Typography color={'#fafafa'} variant='h4'>{t('home.preference')}</Typography>
            :
            <Fragment />
          }
          {type.games.map((game, index) => {
            return(
              <Button
                href={game.href} size="large" variant="contained" key={index}
                sx={{
                  ':hover': {
                    color: colors.greenAccent[500]
                  }
                }}
              >
                {t(game.name)}
              </Button>
            );
          })}
        </Stack>
      </Box>
    </Grid>
  );
}

export default GameCard;