import { Box, Button, Container, Typography, useTheme } from '@mui/material'
import { tokens } from "../../theme";
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { LaunchGame} from '../../scripts/GameEngines';
import { instructionData } from '../../data/instructionData';
import { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FinishModal from './FinishModal';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const Game = () => {
  const params = useParams();
  const gameName = params.gameName;
  const {t} = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [finishScreen, setFinishScreen] = useState(window.localStorage.getItem('finishScreen'));

  // Arrows
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <ArrowForwardIosIcon
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      color='secondary'
      sx={[
        {background: colors.primary,
          fontSize: 40,
          '&:hover': {
            color: colors.greenAccent[100],
          },
        }
      ]}
    />
  );

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <ArrowBackIosIcon
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
      color='secondary'
      sx={[
        {background: colors.primary,
          fontSize: 40,
          '&:hover': {
            color: colors.greenAccent[100],
          },
        }
      ]}
    />
  );

  // Carrousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />
  };

  let data = useState(null)
  data = instructionData[gameName]

  const displayCarrusel = data.instructions.map((value, index) => {
    return(
      <Box key={index} sx={{justifyContent: 'center', mt:4}}>
        <img src={require('../../assets' + value.image)} style={{ width: '30rem', height: 'auto', maxHeight: '331px', marginRight: 'auto', marginLeft: 'auto' }} loading="lazy" alt='instruction'/>
        <Typography variant='h1' textAlign={'center'} paddingTop={4} gutterBottom>{ index + 1 } - { t(data.game) }</Typography>
        <Typography variant='h4' textAlign={'center'} >{ t(value.text) }</Typography>
      </Box>
    )
  });

  useEffect(() => {
    // Game div
    window.Meta.Window(document.getElementById("MetaEngine"));
    // Finish modal
    const handleStorageChange = () => {
      setFinishScreen(window.localStorage.getItem('finishScreen'));
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    
  }, []);

  const handleButton = () => {
    LaunchGame(gameName);
  }
 
  return (
    <Container>
      <Box width={{ xs: '100%', md: '75%' }} height={'65vh'} margin={'auto'} display={'flex'} flexDirection={'column'} justifyContent={'end'} overflow={'hidden'}>
        <Slider {...settings}>
          { displayCarrusel }
        </Slider>
      </Box>
      <Box display={'flex'} justifyContent={'center'} pt={4}>
        <Button
          onClick={handleButton}
          sx= {[{
            background: colors.greenAccent[500],
              mt:4,
              fontSize: '1.25rem',
              fontWeight: 600,
              px: 3,
              borderRadius: '1rem',
              '&:hover': {
                color: colors.grey[900],
                backgroundColor: colors.grey[500],
              },
            }]}
          >
            Start
          </Button>
      {finishScreen === 'true' ?
        <FinishModal fbData={data.fbData} next={data.next} launchGame={handleButton} />
        :
        <Fragment />
      }
      </Box>
    </Container>
  )
}
export default Game;