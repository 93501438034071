import { AccommMedley_Client } from "./GameEngines";
import { KatalyticCombo } from "./Katalyst";

const Lynk = {
  Start: function() {
    const inst = window.Meta.get("GameInst");
    const metadata = window.Meta.get("metadata");
    var SEND = null;

    function Launch(){
      window.Meta.Stage.Clear();
      window.Meta.SubDo(window.Meta.Engine(metadata.client, SEND), ret=>{
        // if (ret){
        //   $('#ended').modal("show");
        // }
        window.Meta.Return();
      });
    }

    inst.Read("SEND").then(s=>{
      SEND = s;

      inst.Read("STATE").then(state => {
        if (state === 2){
          Launch();
        }else{
          var txt = "";
          if (Array.isArray(metadata.prompt)){
            metadata.prompt.forEach(p=>{
              if (txt !== "") txt += "<br>";
              switch(p){
                case "FarDistance":
                  txt += "Move 300cm away from your screen.";
                  break;
                case "Phone40":
                  txt += "Hold your phone 40cm away from your eyes.";
                  break;
                case "PressStart":
                  txt += "Press Start when ready.";
                  break;
                default:
                  break;
              }
            })
          }else{
            txt = metadata.prompt;
          }
          const Prompt = window.Meta.Text(txt, {fill:"#ffffff", size:50});
          Prompt.Top = -500;
          Prompt.Left = 0;
          Prompt.Right = 0;
          const START = window.Meta.Image(200, 200, "/ico/base/NavStart.svg");
          // IMAGE.Bot=500
          // IMAGE.Left=-100

          START.onclick = function(){inst.Write("STATE", 2);Launch()};
          START.Bot = 500;
          START.Left = -100;
          window.Meta.Redraw();
        }
      });
    })
  },
}

const RVT = {
  Start: function() {
    const inst = window.Meta.get("GameInst");
    var sticky = {};

    function PrintResults(res=null){
      const RO = window.Meta.Text("", {fill:"#ffffff", size:50});
      RO.Top = -350;
      RO.Left = 0;
      RO.Right = 0;

      if (res == null){
        RO.Text = "Waiting for Remote Command...";
      }else{
        var txt = "Results<br>";
        // const metadata = _Exercise_Metadata_[sticky.eid];
        // const headers = Array.isArray(metadata.results) ? metadata.results : metadata.results(set);
        // headers.forEach(r=>{
        //   const parser = _Results_Parsers_[r];
        //   if (parser){
        //     console.log(res);
        //     txt += parser.label + " : ";
        //     txt += parser.parse(res, sticky.settings) + "<br>";
        //   }else{
        //     txt += r + " : ";
        //     txt += "N/A";
        //   }
        // })
        RO.Text = txt;
      }
    
      window.Meta.Redraw();
    }

    PrintResults();

    inst.On("RemoteDo", obj=>{
      if (obj){
        sticky = obj;
        window.Meta.Cursor(1);
        window.Meta.SubDo(KatalyticCombo(obj.eid, obj.settings), (ret)=>{							
          PrintResults(ret);
          sticky.results = ret;
          inst.Write("_sticky_", sticky);
        });
        inst.Write("RemoteDo", null);
      }
    })
  },
}

export function GO(code) {
  // const inst = window.Meta.JoinGame(document.getElementById('Code').value);
  const inst = window.Meta.JoinGame(code);
  inst.Read("eid").then(eid => {
    if (!window.Cali.ColorReady){
      window.Cali.LeftColor = 1;
      window.Cali.RightColor = 1;
    }
    // if (!window.Cali.Ready){
    //   $('#cali').modal("show");
    //   return;
    // }

    // const metadata = _Client_Data_Map_[eid];
    const metadata = {
      client: AccommMedley_Client,
      prompt: ["FarDistance", "Phone40", "PressStart"],
    }

    if (metadata) {
      window.Meta.Do(window.Meta.Engine(Lynk, {metadata, GameInst: inst}));
    } else if (eid === "VTR"){
      window.Meta.Do(window.Meta.Engine(RVT, {GameInst: inst}));
    } else {
      alert("Invalid Code");
    }
  });
}

// export function QuickCaliStart(){
//   const ss = document.getElementById("ScreenCaliMobile").value;
//   if (ss){
//     window.Cali.ScreenSize = ss;
//     $('#caliMobile').modal("hide");
//     GO();
//   }
// }