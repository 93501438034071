import React, { useState } from 'react'
import { Alert, Box, Button, FormControl, Stack, TextField, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme';
import { useForm } from 'react-hook-form';
import { auth } from '../../firebase';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { register, handleSubmit, watch, getValues, formState: { errors } } = useForm();

  const [actionCode, setActionCode] = useState();
  const [resetError, setResetError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('oobCode');

    if (code) {
      // Use the action code for resetting the password
      // Implement your password reset logic here
      setActionCode(code);
    } else {
      // Handle the case when action code is missing
      console.error('Action Code is missing');
    }
  }, [location]);

  const handlePasswordReset = async (data) => {
    try {
      if (watch('newPassword') !== watch('confirmPassword')) {
        return setResetError(t('signup.error1'));
      }
      // Confirm the password reset with the new password
      await auth.confirmPasswordReset(actionCode, data.newPassword);
      toast.success(t('reset-password.success'), {
        position: toast.POSITION.TOP_RIGHT
      });
      navigate('/login');
    } catch (error) {
      console.error('Error resetting password:', error);
      setResetError(t('reset-password.error'));
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
      width={{ xs: '90%', md: '30%'}}
      margin={'auto'}
    >
      {resetError && <Alert sx={{ mb: 2, fontSize: '1rem' }} variant='filled' severity='error' color='secondary' >{resetError}</Alert>}
      <Stack
        mt={5}
        p={3}
        width={'100%'}
        border={1}
        spacing={2}
        borderRadius={5}
        borderColor={colors.greenAccent[500]}
      >
        <Typography variant='h2' align='center' gutterBottom>{t('reset-password.reset-password')}</Typography>
        <form onSubmit={handleSubmit(handlePasswordReset)} autoComplete="off" method='post' style={{ width: '100%' }}>
          <FormControl fullWidth variant="outlined" sx={{ paddingBottom: 3 }}>
            <TextField {...register('newPassword')} id="newPassword" label="New password" type={'password'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
            <TextField {...register('confirmPassword')} id="confirmPassword" label="Confirm password" type={'password'} color='secondary' sx={{ pb: 3, borderRadius: '1rem' }} required />
            <Button type='submit' variant='contained' color='secondary' size='large' sx={{ borderRadius: '1rem' }}>{t('reset-password.reset')}</Button>
          </FormControl>
        </form>
      </Stack>
    </Box>
  )
}

export default ResetPassword;