import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';

const GaugeChart = (props) => {
  const chartRef = useRef(null);
  const { chartHeight } = props;
  
  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const { chartData, inverse } = props;

    let option = {
      series: [
        {
          type: 'gauge',
          anchor: {
            show: true,
            showAbove: true,
            size: 18,
            itemStyle: {
              color: '#fefefe'
            }
          },
          pointer: {
            icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
            width: 8,
            length: '80%',
            offsetCenter: [0, '8%'],
          },
          progress: {
            show: true,
            overlap: true,
            roundCap: true
          },
          axisLine: {
            roundCap: true
          },
          data: chartData,
          title: {
            fontSize: 14
          },
          detail: {
            width: 40,
            height: 14,
            fontSize: 14,
            color: '#fff',
            backgroundColor: 'inherit',
            borderRadius: 3,
            formatter: '{value}'
          },
            clockwise: true,
            min: inverse ?  Math.ceil(chartData[0].value + (chartData[1].value * 0.1)): 0,
            max: inverse ? 0 : Math.ceil(chartData[1].value + (chartData[1].value * 0.1)),
            radius: "75%",
            startAngle: 225,
            endAngle: -45
        }
      ],
      color: [
        "#F02915",
        "#156FF0",
        "#fefefe",
        "#F02915",
        "#156FF0",
        "#fefefe",
        "#156FF0",
        "#FEFEFE",
        "#156FF0"
      ]
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [props]);

  return <div ref={chartRef} style={{ width: '100%', height: chartHeight }}></div>;
};

export default GaugeChart;