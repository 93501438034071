import { Box, Button, FormControl, Stack, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useForm } from "react-hook-form";
import { GO } from "../../scripts/Link";
import { useEffect } from "react";

const LinkGame = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Get code from url parameteer
  const queryParameters = new URLSearchParams(window.location.search)
  const code = queryParameters.get("code")
  // Form hook
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    window.Meta.Window(document.getElementById("MetaEngine"));
    window.Cali.ScreenSize = 6;
  }, []);

  function onSubmit(data, e) {
    e.preventDefault();
    GO(data.code);
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
      width={{ xs: '90%', md: '25%'}}
      margin={'auto'}
    >
      <img
        alt="profile-user"
        width="125px"
        height="125px"
        src={require('../../assets/logo.png')}
      />
      <Stack
        mt={5}
        p={3}
        width={'100%'}
        border={1}
        spacing={2}
        borderRadius={5}
        borderColor={colors.greenAccent[500]}
      >
        <Typography variant="h4" align="center" gutterBottom>Virtual Connect Code</Typography>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" method='post'>
          <FormControl fullWidth>
            <TextField type="number" {...register('code')} inputProps={{min: 0, style: { textAlign: 'center', fontSize: '1.125rem' }}} sx={{ pb: 3 }} defaultValue={code} id={"code"}></TextField>
            <Button type="submit" variant="contained" color="secondary">Play</Button>
          </FormControl>
        </form>
      </Stack>
    </Box>
  );
};

export default LinkGame;