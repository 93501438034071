import { Container } from '@mui/material';
import React from 'react';

const Error = () => {
  return (
    <Container>
      <h1>Oops! Something went wrong.</h1>
      <p>We apologize for the inconvenience. Please try again later.</p>
    </Container>
  );
};

export default Error;
