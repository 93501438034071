import { useTheme } from "@emotion/react"
import { Box, Typography } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle })  => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return(
    <Box mb={1.75}>
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight={"bold"}
        sx={{ mb: '0.125rem' }}
      >
        { title }
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>{ subtitle }</Typography>
    </Box>
  )
}
export default Header;