import React from 'react'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme';

const Success = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'90vh'}
      alignItems={'center'}
      justifyContent={'center'}
      width={{ xs: '90%', md: '30%'}}
      margin={'auto'}
    >
      <Stack
        mt={5}
        p={3}
        width={'100%'}
        border={1}
        spacing={2}
        borderRadius={5}
        borderColor={colors.greenAccent[500]}
      >
        <Typography variant='h2'>Welcome to Visiplay</Typography>
        <Typography variant='h5' gutterBottom>You can now sign in with your new account.</Typography>
        <Button href='/' color='secondary' variant='contained'>Log in</Button>
      </Stack>
    </Box>
  )
}

export default Success;