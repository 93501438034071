import{ ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Sidebar from './scenes/global/Sidebar';
import Home from './scenes/home';
import { Route, Routes, useLocation } from 'react-router-dom';
import Game from './scenes/games/Game';
import Arcade from './scenes/arcade';
import Performance from './scenes/performance';
import Calibration from './scenes/calibration/Calibration';
import Login from './scenes/login';
import Tour from './components/Tour'
import Signup from './scenes/signup';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import ForgotPassword from './scenes/forgotPassword';
import Account from './scenes/account';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LinkGame from './scenes/games/LinkGame';
import { Fragment } from 'react';
import Success from './scenes/success';
import CustomEmailActionHandler from './firebase/customEmailActionHandler'
import ResetPassword from './scenes/resetPassword';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import Error from './scenes/error';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    es: { translation: esTranslation }
  },
  lng: window.localStorage.getItem('defaultLanguage'), // Set the default language here
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

function App() {
  const [theme, colorMode] = useMode();

  const publicRoutes = ['/login', '/signup', '/forgot-password', '/success', '/email-handler', '/reset-password', '/error'];
  const location = useLocation();

  return (
    <ColorModeContext.Provider value={colorMode}>
      {!window.localStorage.getItem('tutorialPassed') ?
        <Tour />
        :
        <Fragment />
      }
	    <div id="MetaEngine" style={{ width: 0 }}></div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
        <div className="app">
          {publicRoutes.includes(location.pathname) || location.pathname.includes("/link") ?
            <Fragment />
            :
            <Sidebar/>
          }
          <main className='content' style={{ paddingTop: '1rem' }}>
            <ToastContainer />
            <Routes>
              {/* <Route exact path='/' element={<Home />} /> */}
              {/* Login page */}
              <Route path='/login' element={<Login />} />
              {/* Sign up page */}
              <Route path='/signup' element={<Signup />} />
              <Route path='forgot-password' element={<ForgotPassword/>}/>
              {/* Astucia mobile game */}
              <Route path='/link' element={<LinkGame />} />
              <Route path='/success' element={<Success />} />
              <Route path='/error' element={<Error />} />
              {/* Email handler to verified email, reset password */}
              <Route path='/email-handler' element={<CustomEmailActionHandler />} />
              {/* Reset password */}
              <Route path='/reset-password' element={<ResetPassword />} />
              {/* Home */}
              <Route path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route path="/calibration"
                element={
                  <PrivateRoute>
                    <Calibration />
                  </PrivateRoute>
                }
              />
              <Route path="/performance"
                element={
                  <PrivateRoute>
                    <Performance />
                  </PrivateRoute>
                }
              />
              <Route path="/arcade"
                element={
                  <PrivateRoute>
                    <Arcade />
                  </PrivateRoute>
                }
              />
              <Route path="/games/:gameName"
                element={
                  <PrivateRoute>
                    <Game />
                  </PrivateRoute>
                }
              />
              <Route path="/account"
                element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
        </div>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
