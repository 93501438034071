export const arcadeData = [
  {
    title: "Eye Strain",
    games: [
      {
          name: "Relax",
          img: "elipses.png",
          href: "/games/_name"
      },
      {
        name: "Flex",
        img: "elipses.png",
        href: "/games/_name"
      },
      {
        name: "Stamina Challenge",
        img: "elipses.png",
        href: "/games/_name"
      }
    ]
  },
  {
    title: "Sports Performance",
    games: [
      {
        name: "Horizontal Chase",
        img: "elipses.png",
        href: "/games/_name"
      },
      {
        name: "Vertical Chase",
        img: "elipses.png",
        href: "/games/_name"
      },
      {
        name: "Horizontal Reflex",
        img: "elipses.png",
        href: "/games/_name"
      },
      {
        name: "Vertical Reflex",
        img: "elipses.png",
        href: "/games/_name"
      }
    ]
  },
  {
    title: "Reading",
    games: [
      {
        name: "Right Eye",
        img: "elipses.png",
        href: "/games/_name"
      },
      {
        name: "Left Eye",
        img: "elipses.png",
        href: "/games/_name"
      },
      {
        name: "Both Eyes",
        img: "elipses.png",
        href: "/games/_name"
      }
    ]
  },
]