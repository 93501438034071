import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LineChart1 from "../../components/LineChart1";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { getEyeSyncingPlayedGames, getVisionStaminaPlayedGames, getFocusingPlayedGames } from "../../firebase/userPlayedGames";
import { useAuth } from '../../contexts/AuthContext.js'
import { useState } from "react";
import ChartContainer from "./ChartContainer";
import { useTranslation } from "react-i18next";
import LineChart2 from "../../components/LineChart2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Performance = () => {
  const {t} = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { currentUser } = useAuth()

  // Tab handle
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [relaxData, setRelaxData] = useState();
  const [flexData, setFlexData] = useState();
  const [staminaData, setStaminaData] = useState();

  const [chaseData, setChaseData] = useState()
  const [reflexData, setReflexData] = useState()

  const [mathData, setMathData] = useState();
  const [symbolsData, setSymbolsData] = useState();

  // Activities reports getters
  useEffect(() => {
    const visionStamina = [
      {
        type: 'Relax',
        set: setRelaxData
      },{
        type: 'Flex',
        set: setFlexData
      },{
        type: 'Stamina',
        set: setStaminaData
      }
    ];

    const eyeSyncing = [
      {
        type: 'Chase',
        set: setChaseData
      },
      {
        type: 'Reflex',
        set: setReflexData
      }
    ];

    const focusing = [
      {
        type: 'Math',
        set: setMathData
      },
      {
        type: 'Symbols',
        set: setSymbolsData
      }
    ]
    
    visionStamina.map((element) => {
      const promise = getVisionStaminaPlayedGames(currentUser.uid, element.type)
      promise.then((value) => {
        element.set(value)
      })
      return true;
    });

    eyeSyncing.map((element) => {
      const promise = getEyeSyncingPlayedGames(currentUser.uid, element.type)
      promise.then((value) => {
        element.set(value);
      })
      return true;
    });

    focusing.map((element) => {
      const promise = getFocusingPlayedGames(currentUser.uid, element.type);
      promise.then((value) => {
        element.set(value);
      })
      return true;
    })

  }, [currentUser]);

  return (
    <Box>
      <Typography variant="h2" textAlign="center" gutterBottom>
        {t("performance.title")}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab {...a11yProps(0)} style={{minWidth:"15rem"}} sx={{ fontSize: '1rem' }} label={t("performance.stamina")} {...a11yProps(0)} />
          <Tab {...a11yProps(0)} style={{minWidth:"15rem"}} sx={{ fontSize: '1rem' }} label={t("performance.synching")} {...a11yProps(1)} />
          <Tab {...a11yProps(0)} style={{minWidth:"15rem"}} sx={{ fontSize: '1rem' }} label={t("performance.focusing")} {...a11yProps(2)} />
        </Tabs>
      </Box>
      {/* Vision Stamina Panel */}
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          {/* Relax */}
          <Grid item xs={12} md={4}>
            {relaxData && <ChartContainer data={relaxData} />}
          </Grid>
          {/* Flex */}
          <Grid item xs={12} md={4}>
            {flexData && <ChartContainer data={flexData} />}
          </Grid>
          {/* Stamina */}
          <Grid item xs={12} md={4}>
            {staminaData && <ChartContainer data={staminaData} />}
          </Grid>
        </Grid>
      </TabPanel>
      {/* Eye Syncing Panel */}
      <TabPanel value={value} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2} py={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                align={'center'}
              >
                {t('performance.chase')}
              </Typography>
              <Box height={'40rem'} m={2}>
                {chaseData && <LineChart1 chartData={chaseData.data} />}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2} py={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                align={'center'}
              >
                 {t('performance.reflex')}
              </Typography>
              <Box height={'40rem'} m={2}>
                {reflexData && <LineChart1 chartData={reflexData.data} />}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2} py={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                align={'center'}
              >
                 {t('performance.speed')}
              </Typography>
              <Box height={'40rem'} m={2}>
                {/* {mathData && <InvertedLineChart chartData={mathData.data} />} */}
                {mathData && <LineChart2 chartData={mathData.data} inverse={true} />}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box backgroundColor={colors.primary[400]} borderRadius={2} py={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
                align={'center'}
              >
                 {t('performance.speed')}
              </Typography>
              <Box height={'40rem'} m={2}>
                {symbolsData && <LineChart2 chartData={symbolsData.data} inverse={true} />}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
};

export default Performance;
