import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, useTheme, Divider, Modal, FormControl, Select, Button, MenuItem as SelectItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { bottomData } from "../../data/sidebarData";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { useTranslation } from "react-i18next";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import GamepadIcon from '@mui/icons-material/Gamepad';
import HelpIcon from '@mui/icons-material/Help';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SummarizeIcon from '@mui/icons-material/Summarize';
import TuneIcon from '@mui/icons-material/Tune';
import CalibrationModal from '../home/CalibrationModal';
import InfoIcon from '@mui/icons-material/Info';

const Item = ({ title, to, icon, selected, setSelected, href }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography fontSize={'1rem'}>{title}</Typography>
      <Link to={to} href={href}/>
    </MenuItem>
  );
};

const ItemClick = ({ title, icon, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      style={{
        color: colors.grey[100],
      }}
      onClick={() => onClick()}
      icon={icon}
    >
      <Typography fontSize={'1rem'}>{title}</Typography>
    </MenuItem>
  );
};

const ItemModal = ({ title, icon, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      style={{
        color: colors.grey[100],
      }}
      onClick={() => onClick()}
      icon={icon}
    >
      <Typography fontSize={'1rem'}>{title}</Typography>
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { t } = useTranslation()
  // Firebase log out
  const { logout } = useAuth();
  const navigate = useNavigate()

  async function handleLogout() {
    try {
      await logout()

      navigate('/login')
      toast.success('Successfully logged out!', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch {
      toast.error('Set to logged out!', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const [openArcade, setOpenArcade] = useState(false);
  const handleOpen = () => setOpenArcade(true);
  const handleClose = () => setOpenArcade(false);

  // Modal style
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: 'full',
    backgroundColor: colors.primary[500],
    border: '2px solid',
    borderColor: colors.greenAccent[500],
    borderRadius: '1rem',
    boxShadow: 24,
    p: 4,
  };
  // Language
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.localStorage.setItem('defaultLanguage', lng);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };

  const [openCaliModal, setOpenCaliModal] = useState(false);
  const handleOpenCali = () => setOpenCaliModal(true);
  const handleCloseCali = () => setOpenCaliModal(false);

  return (
    <Box
      sx={{
        "& .pro-sidebar": {
          minWidth: '15.5rem',
          width: 'auto'
        },
        "& .pro-sidebar-inner": {
          position: "fixed !important",
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 12px 5px 12px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={false}>
        <Menu iconShape="square">
          {/* Logo and app name */}
          <Box my="1rem">
            <Link to={'/'}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Logo width={155} height={155} />
              </Box>
            </Link>
            <Box textAlign="center">
              <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                VisiPlayAI
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {t('sidebar.slogan')}
              </Typography>
            </Box>
          </Box>

          <Box
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignContent={'space-between'}
          >
            {/* Top icons */}
            <Box>
              <Item
                title={t("sidebar.home")}
                to={"/"}
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
        
              />
              <Item
                title={t("sidebar.performance")}
                to={"/performance"}
                icon={<SummarizeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <ItemClick
                title={t("sidebar.calibration")}
                icon={<TuneIcon />}
                onClick={handleOpenCali}
              />
            </Box>
            {/* Middle icons */}
            <Box>
              <Divider className="sidebar-tour" />
              {/* Help */}
              <MenuItem
                style={{color: colors.grey[100],}}
                icon={<InfoIcon />}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={i18n.language === "en" ? "https://jagged-aardwolf-5e8.notion.site/VisiPlayAI-Help-Center-1c3221c2c2ca43aab989a3b478aa80a9" : "https://jagged-aardwolf-5e8.notion.site/VisiPlayAI-Centro-de-Ayuda-Espa-ol-be31b8fb1279482c933d79f19ba23c72"}
                >
                  <Typography color={colors.grey[100]} fontSize={'1rem'}>{t("sidebar.help")}</Typography>
                </a>
              </MenuItem>
              {/* FAQ */}
              <MenuItem
                style={{color: colors.grey[100],}}
                icon={<HelpIcon />}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={i18n.language === "en" ? "https://jagged-aardwolf-5e8.notion.site/VisiplayAI-FAQs-7905ffe096764a89bd505d343653701e" : "https://jagged-aardwolf-5e8.notion.site/VisiplayAI-FAQs-Espa-ol-04cea6f56e1b49dab2f8d48702880ff7"} >
                  <Typography color={colors.grey[100]} fontSize={'1rem'}>FAQ</Typography>
                </a>
              </MenuItem>
              <Item
                title={t("sidebar.account")}
                to={"/account"}
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <ItemModal
                title={t("sidebar.arcade")}
                icon={<GamepadIcon />}
                selected={selected}
                setSelected={setSelected}
                onClick={handleOpen}
              />
            </Box>
            <Box>
              <Divider className="sidebar-tour" />
              {
                bottomData.map((item, index) => {
                  return (
                    <ItemClick
                      title={t(item.title)}
                      icon={item.icon}
                      onClick={handleLogout}
                      key={index}
                    />
                  )
                })
              }
              <Divider className="sidebar-tour" sx={{ marginBottom: '0.75rem' }} />

              <FormControl>
                <Select
                  value={i18n.language}
                  onChange={handleLanguageChange}
                  color="secondary"
                  sx={{
                    ml: '1rem',
                    borderRadius: '2rem',
                    width: '7.5rem',
                    textAlign: 'center',
                    backgroundColor: colors.blueAccent[900],
                    '& .MuiSelect-outlined': {
                      padding: '0.5rem',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }

                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      sx: {
                        backgroundColor: colors.blueAccent[900],
                      },
                    },
                    MenuListProps: {
                      sx: {
                        '& .MuiMenuItem-root': {
                          color: 'white',
                        },
                        '& .Mui-selected': {
                          backgroundColor: 'rgba(197, 15, 15, 0.1)',
                        },
                      },
                    },
                  }}
                  renderValue={(value) => (
                    <Box sx={{ pr: '1 rem' }}>{value === 'en' ? 'English' : 'Español'}</Box>
                  )}
                >
                  <SelectItem value="en">English</SelectItem>
                  <SelectItem value="es">Español</SelectItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
      <Modal
        open={openArcade}
        onClose={handleClose}
      >
        <Box style={style} p={5}>
          <Typography variant="h1" align="center" gutterBottom>{t('sidebar.arcade')}</Typography>
          <Typography variant="h4" align="center" gutterBottom>{t('sidebar.modal1')}</Typography>
          <Typography variant="h4" align="center" gutterBottom>{t('sidebar.modal2')}</Typography>
        <Box  sx={{display:'flex', width:'100%', justifyContent: 'center' }}> 
          <a target="_blank" rel="noreferrer" href='https://eyestorm.visionsciencelabs.com/'>
          <Button  sx=
      {[
        {background: colors.greenAccent[500],
          mt:2, 
          '&:hover': {
            color: colors.grey[900],
            backgroundColor: colors.grey[500],
          },
        }
      ]}>{t('sidebar.modal3')}</Button>
      </a>
          </Box>
        </Box>
      </Modal>
      <CalibrationModal open={openCaliModal} setOpen={setOpenCaliModal} handleClose={handleCloseCali} />
    </Box>
  );
};

export default Sidebar;
