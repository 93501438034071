import { Divider, Typography, Grid, Chip, useTheme, Box } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { tokens } from '../../theme';
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

function Clinician({email}) {
  const { t } = useTranslation();
  // Styles
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // User
  // Codes
  const [activeCodes, setActiveCodes] = useState([]);
  const [usedCodes, setUsedCodes] = useState([]);
  
  const fetchPromoCodes = useCallback(async () => {
    try {
      const url = `https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/active/${email}`;
  
      const res = await fetch(url, { mode: 'cors' });
      if (!res.ok) {
        throw new Error(`Failed to fetch promo codes. Status: ${res.status}`);
      }
  
      const data = await res.json();
      setActiveCodes(data);
  
      // Fetch used codes
      const usedUrl = `https://us-central1-visi-play.cloudfunctions.net/app/api/promoCodes/used/${email}`;
      const usedRes = await fetch(usedUrl, { mode: 'cors' });
      if (!usedRes.ok) {
        throw new Error(`Failed to fetch used promo codes. Status: ${usedRes.status}`);
      }
  
      const usedData = await usedRes.json();
      setUsedCodes(usedData);
    } catch (error) {
      console.log('Error retrieving promo codes:', error);
    }
  }, [email]);

  useEffect(() => {
    fetchPromoCodes();
  }, [fetchPromoCodes]);

  return (
    <Fragment>
      <Divider />
      <Typography variant='h2' gutterBottom pt={3}>{t('account.clinician.title')}</Typography>
      {/* Codes display */}
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <Typography variant='h3' gutterBottom>{t('account.admin.active')}</Typography>
          <Box
            height={400}
            overflow={'auto'}
            backgroundColor={colors.greenAccent[800]}
            borderRadius={2}
          >
            <Grid container p={0.5} >
              {activeCodes.map((promoCode, index) => {
                return(
                  <Grid item xs={12} key={index} display={'flex'} justifyContent={'center'} py={1}>
                    <Chip label={promoCode} sx={{ width: '95%', fontSize: '1rem' }} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
        <Typography variant='h3' gutterBottom>{t('account.admin.used')}</Typography>
          <Box
            height={400}
            overflow={'auto'}
            backgroundColor={colors.redAccent[800]}
            borderRadius={2}
          >
            <Grid container p={0.5} >
              {usedCodes.map((promoCode, index) => {
                return(
                  <Grid item xs={12} key={index} display={'flex'} justifyContent={'center'} py={1}>
                    <Chip label={promoCode} sx={{ width: '95%', fontSize: '1rem' }} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Clinician;
